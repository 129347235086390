import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonIcon, IonButton, IonLabel, IonInput, IonImg, useIonAlert, useIonToast } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { getMembersDownline, transferMoney, getUserInfo } from "../redux/auth/authCrud";
import { formatMoney } from "../util";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { defaultsDeep, filter, get } from "lodash";
import Select from "react-select";
import * as auth from "../redux/auth/authRedux";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { eye } from "ionicons/icons";

import "./Agency.css";
import { IMG_MB } from "../constants";

const INIT_TRANSFER_MONEY = {
	Amount: "",
	WithdrawPassword: "",
	toUserId: "",
};

const AgencyTransferMoney: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [presentAlert] = useIonAlert();
	const [present] = useIonToast();
	const [isShowPass, setIsShowPass] = useState(false);

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const { userInfo } = useSelector(
		(state: any) => ({
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	const [dataMembersDownline, setDataMembersDownline] = useState([]);
	const [dataTransferMoney, setDataTransferMoney] = useState(INIT_TRANSFER_MONEY);

	const GetMembersDownline = async () => {
		const res = await getMembersDownline();
		if (res && res.data) {
			setDataMembersDownline(res.data);
		}
	};

	const _transferMoney = async () => {
		if (!dataTransferMoney.toUserId) return presentToast("error", "Chọn thành viên.");
		if (!dataTransferMoney.Amount) return presentToast("error", "Nhập số tiền cần chuyển.");
		if (!dataTransferMoney.WithdrawPassword) return presentToast("error", "Nhập mật khẩu rút tiền.");
		const res = await transferMoney(dataTransferMoney.toUserId, dataTransferMoney.Amount, dataTransferMoney.WithdrawPassword);

		if (res?.data && res?.data?.error && res?.data?.error?.message) return presentToast("error", res?.data?.error?.message);

		presentAlert({
			header: "Thông Báo",
			message: "Chuyển tiền thành công.",
			buttons: ["OK"],
			cssClass: "alert-custom",
		});
		setDataTransferMoney(INIT_TRANSFER_MONEY);
		getUserInfo().then((resUser) => {
			dispatch(auth.actions.fulfillUser(get(resUser, "data", null)));
		});
	};

	useEffect(() => {
		GetMembersDownline();
	}, []);

	return (
		<IonPage className="app-agency-member-search">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/agency")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Chuyển Tiền Cấp Dưới
					</IonButton>
				</div>
			</div>
			<IonContent className="agency-member-list">
				<div className="af-wrapper">
					<div className="af-item">
						<div className="flex agency-member-list-item sic-border-bottom">
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/icon-tranfer-sodu.png`} />
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Số dư tài khoản của bạn</IonLabel>
							<IonInput
								value={formatMoney(userInfo?.Credits)}
								readonly
								type="text"
								className="sic-padding-x-10 color-trung agency-member-input ng-untouched ng-pristine ng-valid ion-untouched ion-pristine ion-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated"
							></IonInput>
						</div>
					</div>
					<div className="af-item">
						<div className="flex agency-member-list-item">
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/icon-tranfer-thanhvien.png`} />
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Chuyển tiền cho thành viên</IonLabel>
						</div>
						{/* <IonSelect
              value={dataTransferMoney?.Id}
              onIonChange={(e) => {
                setDataTransferMoney(
                  defaultsDeep(
                    {
                      Id: e.detail.value,
                    },
                    dataTransferMoney
                  )
                );
              }}
              cancelText="Huỷ"
              okText="Chọn"
              className="agency-member-input ng-pristine ng-valid ios hydrated ng-touched ion-pristine ion-valid ion-touched"
              role="combobox"
              aria-haspopup="dialog"
              aria-expanded="false"
              aria-labelledby="ion-sel-1-lbl"
            >
              <IonSelectOption>
                <div>
                  <input type="text" />
                </div>
              </IonSelectOption>
              {dataMembersDownline.map((item: any, index) => {
                return (
                  <IonSelectOption key={index} value={item?.Id} className="ios hydrated">
                    {item?.UserName} - {item?.NickName} - {item?.Mobile}
                  </IonSelectOption>
                );
              })}
            </IonSelect> */}
						<Select
							className="agency-member-input z-index-999 ng-pristine ng-valid ios hydrated ng-touched ion-pristine ion-valid ion-touched"
							value={filter(dataMembersDownline, ["Id", dataTransferMoney?.toUserId]).map((item: any) => ({ value: item?.Id, label: item?.UserName }))}
							onChange={(e: any) => {
								setDataTransferMoney(
									defaultsDeep(
										{
											toUserId: e.value,
										},
										dataTransferMoney
									)
								);
							}}
							options={dataMembersDownline.map((item: any) => ({ value: item?.Id, label: item?.UserName }))}
						/>
					</div>
					<div className="af-item">
						<div className="flex agency-member-list-item">
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/icon-tranfer-sotien.png`} />
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Số tiền cần chuyển</IonLabel>
						</div>
						<IonInput
							value={dataTransferMoney?.Amount}
							onIonChange={(e: any) => {
								e.detail.value = e.detail.value.replace(/[^0-9]/g, "");
								setDataTransferMoney(
									defaultsDeep(
										{
											Amount: e.detail.value,
										},
										dataTransferMoney
									)
								);
							}}
							type="text"
							className="sic-padding-x-10 agency-member-input ng-untouched ng-pristine ng-valid ion-untouched ion-pristine ion-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated"
						></IonInput>
					</div>
					<div className="af-item">
						<div className="flex agency-member-list-item">
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/icon-tranfer-maruttien.png`} />
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Mã rút tiền</IonLabel>
						</div>
						<div className="flex">
							<IonInput
								value={dataTransferMoney?.WithdrawPassword}
								onIonChange={(e) => {
									setDataTransferMoney(
										defaultsDeep(
											{
												WithdrawPassword: e.detail.value,
											},
											dataTransferMoney
										)
									);
								}}
								type={isShowPass ? "text" : "password"}
								className="sic-padding-x-10 agency-member-input ng-untouched ng-pristine ng-valid ion-untouched ion-pristine ion-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated"
							></IonInput>
							<IonIcon className="icon--eye" icon={eye} onClick={() => setIsShowPass(!isShowPass)} />
						</div>
					</div>
					<IonButton
						className="af-submit-btn ios button button-block button-solid ion-activatable ion-focusable hydrated"
						expand="block"
						onClick={() => _transferMoney()}
					>
						Chuyển Tiền
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default AgencyTransferMoney;
