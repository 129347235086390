import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, useIonAlert, useIonViewDidEnter, useIonViewDidLeave, IonImg } from "@ionic/react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import * as auth from "../redux/auth/authRedux";
import { setGameActive, getUserInfo, collectAllCredits } from "../redux/auth/authCrud";
import { get } from "lodash";

import HeaderHome from "../components/HeaderHome";

import "./Home.css";
import { IMG_MB } from "../constants";

const GameCodeWM: React.FC = (props: any) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { gameCode } = props.match.params;
	const [urlGame, setUrlGame] = useState("");
	const [isLoaded, setIsLoaded] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	useIonViewDidEnter(() => {
		if (authToken) {
			SetGameActive();
		}
	});

	useIonViewDidLeave(() => {
		setUrlGame("");
		_collectAllCredits();
		setIsLoaded(false);
		setIsMounted(false);
	});

	const _collectAllCredits = () => {
		collectAllCredits(gameCode);
	};

	const [presentAlert] = useIonAlert();

	const { authToken } = useSelector(
		(state: any) => ({
			authToken: state.auth.authToken,
		}),
		shallowEqual
	);

	const isBrowserEdge = () => {
		let userAgent = navigator.userAgent;
		let isEdge = false;

		if (/Edg/.test(userAgent)) {
			isEdge = true;
		}

		return isEdge;
	};

	const SetGameActive = async () => {
		const res: any = await setGameActive(gameCode);

		if (res.data && res.data.error && res.data.error.message) {
			return presentAlert({
				header: "Thông Báo!",
				message: res.data.error.message,
				backdropDismiss: false,
				buttons: [
					{
						text: "OK",
						role: "confirm",
						handler: () => {
							history.push("/home");
						},
					},
				],
				cssClass: "alert-custom",
			});
		}

		if (!res.data) {
			return history.push("/home");
		}

		getUserInfo().then((resUser) => {
			dispatch(auth.actions.fulfillUser(get(resUser, "data", null)));
		});
		setIsLoaded(true);
		setUrlGame(res.data);
	};

	useEffect(() => {
		if (!authToken) {
			window.location.href = "/home?login=true";
		}
		// eslint-disable-next-line
	}, [authToken]);

	const handleIframeLoad = () => {
		setIsMounted(true);
	};

	return (
		<IonPage className="app-home">
			<HeaderHome />
			<IonContent className={`homepage ${isBrowserEdge() ? `` : `max-height-calc-103 height-calc-103`}`}>
				<div className="home-main w-full h-full">
					{authToken && urlGame && (
						<iframe
							title={gameCode}
							className="w-full h-full"
							src={`${urlGame}`}
							data-mobile="1"
							height="100%"
							width="100%"
							frameBorder="0"
							sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
							scrolling="yes"
							allowFullScreen
							onLoad={handleIframeLoad}
							style={{ opacity: "1", background: "transparent" }}
						></iframe>
					)}
					{(!authToken || !urlGame || !isLoaded || !isMounted) && <IonImg className="page-loading" src={`${IMG_MB}assets/v2/loading.webp`} />}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default connect(null, auth.actions)(GameCodeWM);
