import React, { useState, useEffect } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonIcon,
	IonRippleEffect,
	IonImg,
	IonList,
	IonItem,
	IonModal,
	IonButton,
	IonPopover,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { getBetHistory } from "../redux/auth/authCrud";
import { orderBy, toNumber } from "lodash";
import { formatMoney } from "../util";
import moment from "moment";
import { caretDown } from "ionicons/icons";
import QuickOptions from "../components/QuickOptions";

import "./BetHistory.css";
import { IMG_MB } from "../constants";

const GameHistory: React.FC = () => {
	const [dataGameHistory, setDataGameHistory] = useState<any>([]);
	const [modalGameHistory, setModalGameHistory] = useState<any>(null);
	const [dataGameType, setDataGameType] = useState<any>(null);

	const GetBetHistory = async () => {
		const res = await getBetHistory();
		if (res && res.data) {
			setDataGameHistory(orderBy(res.data, [(obj) => new Date(moment(obj.BetDate, "DD-MM-YYYY").format("YYYY-MM-DD"))], ["desc"]));
		}
	};

	useEffect(() => {
		GetBetHistory();
	}, []);

	return (
		<IonPage className="app-bet-history">
			<div className="app-home homepage">
				<IonHeader>
					<IonToolbar className="ion-toolbar">
						<IonButtons slot="start">
							<IonBackButton text="" defaultHref="/history">
								<button type="button" className="button-native sc-ion-back-button-md">
									<span className="button-inner sc-ion-back-button-md">
										<IonIcon className="sc-ion-back-button-md md hydrated" icon={arrowBack} role="img" aria-label="arrow back"></IonIcon>
									</span>
									<IonRippleEffect className="sc-ion-back-button-md md unbounded hydrated" role="presentation"></IonRippleEffect>
								</button>
							</IonBackButton>
						</IonButtons>
						<IonTitle>Lịch Sử Game Chuyển Quỹ</IonTitle>
					</IonToolbar>
					<QuickOptions />
				</IonHeader>
				<div className="sic-title-page">
					<span> Lịch Sử Game Chuyển Quỹ </span>
					<hr />
					<div className="select-date ng-star-inserted">
						<IonButton id="biendongsodu" mode="md" className="md button button-solid btn-bdsd ion-activatable ion-focusable hydrated activated">
							<span>{dataGameType ? dataGameType : "Toàn bộ"}</span>
							<IonIcon icon={caretDown} slot="end" className="md hydrated" />
						</IonButton>
						<IonPopover trigger="biendongsodu" dismissOnSelect={true} alignment="end" size="auto">
							<IonContent>
								<IonList>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataGameType(null);
										}}
									>
										Toàn bộ
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataGameType("Saba");
										}}
									>
										Saba
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataGameType("Evo");
										}}
									>
										Evo
									</IonItem>
								</IonList>
							</IonContent>
						</IonPopover>
					</div>
				</div>
			</div>
			<IonContent className="ion-content ion-content-segment agency-form ios overscroll hydrated">
				<IonList className="sic-bet-history ios list-ios">
					{dataGameHistory.map((item: any, index: any) => {
						if (dataGameType && dataGameType !== item.Provider) return <React.Fragment key={index}></React.Fragment>;
						return (
							<IonItem key={index} className="bet-history__main--item item-lines-none" lines="none" onClick={() => setModalGameHistory(item)}>
								<div className="item-bet-history">
									<section className="bet-history-section flex-1">
										<div className="bet-history--channel">{item?.Provider}</div>
										<div className="bet-history--time">{item?.trans_id}</div>
									</section>
									<section className="bet-history-section">
										<div className="bet-history--amount">
											{formatMoney(toNumber(item?.BetAmount))} Điểm{" "}
											<div className="bet-history--status">{moment(item?.BetTime).format("DD-MM-YYYY HH:mm:ss")}</div>
										</div>
									</section>
								</div>
							</IonItem>
						);
					})}
				</IonList>
			</IonContent>
			<IonModal id="vibet88-modal" isOpen={modalGameHistory ? true : false} onIonModalDidDismiss={() => setModalGameHistory(null)}>
				<IonContent>
					<IonToolbar>
						<IonTitle>LỊCH SỬ GAME CHUYỂN QUỸ</IonTitle>
						<IonButtons slot="end">
							<IonButton color="light" onClick={() => setModalGameHistory(null)}>
								<IonImg className="icon-close" src={`${IMG_MB}assets/icon/close-modal.png`} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<div className="content-game-history">
						<p
							dangerouslySetInnerHTML={{
								__html: modalGameHistory?.BetDetails,
							}}
						></p>
					</div>
				</IonContent>
			</IonModal>
		</IonPage>
	);
};

export default GameHistory;
