import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonBackButton,
	IonIcon,
	IonRippleEffect,
	IonButton,
	IonLabel,
	IonList,
	IonRow,
	IonCol,
	useIonToast,
	useIonViewDidEnter,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { getUserBanks } from "../redux/auth/authCrud";

import "./UserBanks.css";

const UserBanks: React.FC = () => {
	const history = useHistory();
	const [present] = useIonToast();
	const [userBanks, setUserBanks] = useState([]);

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const GetUserBanks = async () => {
		const { data } = await getUserBanks();

		if (data) {
			setUserBanks(data);
		}
	};

	useIonViewDidEnter(() => {
		GetUserBanks();
	});

	return (
		<IonPage className="app-user-banks">
			<IonHeader className="ion-header">
				<IonToolbar className="ion-toolbar">
					<IonButtons slot="start">
						<IonBackButton text="" defaultHref="/account">
							<button type="button" className="button-native sc-ion-back-button-md">
								<span className="button-inner sc-ion-back-button-md">
									<IonIcon className="sc-ion-back-button-md md hydrated" icon={arrowBack} role="img" aria-label="arrow back"></IonIcon>
								</span>
								<IonRippleEffect className="sc-ion-back-button-md md unbounded hydrated" role="presentation"></IonRippleEffect>
							</button>
						</IonBackButton>
					</IonButtons>
					<IonTitle>Tài khoản ngân hàng</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent className="ion-content account-form ios overscroll hydrated" fullscreen>
				<ul className="ac-note">
					<li>
						Bạn có thể thêm tối đa <span>[5]</span> tài khoản ngân hàng, Hiện tại bạn có <span>[{userBanks.length}]</span> tài khoản
					</li>
					<li>
						Từ tài khoản ngân hàng thứ <span>[2]</span> trở đi phải trùng tên chủ tài khoản với tài khoản đầu tiên
					</li>
					<li> Nếu bạn khóa danh sách tài khoản, bạn sẽ không thể thêm mới hoặc xóa tài khoản ngân hàng </li>
					<li> VIBET88 không hỗ trợ rút tiền về số thẻ ATM và Viettel Pay </li>
				</ul>
				{userBanks.length > 0 && (
					<>
						<IonLabel className="ion-label ng-star-inserted sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Thẻ ngân hàng của tôi</IonLabel>
						<IonList className="ion-list ac-list ng-star-inserted ios list-ios hydrated">
							{userBanks.map((bank: any, index) => {
								return (
									<div className="ac-list-item ng-star-inserted" key={index}>
										<IonRow className="ios hydrated">
											<IonCol className="ion-col info ios hydrated">
												<IonRow className="ios hydrated">
													<IonCol className="bankname ios hydrated">{bank.BankName}</IonCol>
													<IonCol className="stk ios hydrated">STK: {bank.AccountNumber}</IonCol>
												</IonRow>
												<IonRow className="add-date ios hydrated">Chủ tài khoản: {bank.AccountName}</IonRow>
											</IonCol>
										</IonRow>
									</div>
								);
							})}
						</IonList>
					</>
				)}

				<div className="btn-wrapper">
					<IonButton
						expand="block"
						className="ng-star-inserted ios button button-block button-solid ion-activatable ion-focusable hydrated"
						onClick={() => {
							if (userBanks.length === 5) return presentToast("error", "Bạn đã thêm tối đa 5 số tài khoản.");
							history.push("/account-add-bank");
						}}
					>
						Đăng ký tài khoản ngân hàng
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default UserBanks;
