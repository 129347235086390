import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonToolbar, IonImg, IonIcon, IonButtons, IonTitle, IonModal, IonButton, IonGrid } from "@ionic/react";
import { chevronForward, arrowBack } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { getMessages } from "../redux/auth/authCrud";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./UserInfo.css";
import moment from "moment";
import { size } from "lodash";
import { IMG_MB } from "../constants";

const MessageSystem: React.FC = () => {
	const history = useHistory();
	const [dataModalMessages, setDataModalMessages] = useState<any>(null);
	const [userMessages, setUserMessages] = useState([]);

	const GetMessages = async () => {
		const { data } = await getMessages();

		if (data) {
			setUserMessages(data);
		}
	};

	useEffect(() => {
		GetMessages();
	}, []);

	return (
		<IonPage className="app-user-edit app-home app-user-banks ng-star-inserted ion-page can-go-back">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/information")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Tin Nhắn Hệ Thống
					</IonButton>
				</div>
			</div>
			<IonContent className="ion-content">
				<div className="p-10">
					{userMessages.length > 0 && (
						<table className="sic-table">
							<tbody className="bg-F1F1F1">
								{userMessages.map((item: any, index) => {
									return (
										<tr key={index}>
											<td className={`${index === 0 ? `bt-0 rounded-tl-10` : ``} w-30`}>{index + 1}</td>
											<td className={`${index === 0 ? `bt-0` : ``} text-left`}>
												{size(item?.Note) > 20 ? (
													<span className="text-title">{`${item?.Note}`.substring(0, 20)}...</span>
												) : (
													<span className="text-title">{item?.Note}</span>
												)}
											</td>
											<td className={`${index === 0 ? `bt-0` : ``} w-80`}>{moment(item?.CreatedTime).format("MM-DD-YYYY")}</td>
											<td className={`${index === 0 ? `bt-0 rounded-tr-10` : ``} w-30`} onClick={() => setDataModalMessages(item)}>
												<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</div>
			</IonContent>
			<IonModal id="vibet88-modal" isOpen={dataModalMessages ? true : false} onIonModalDidDismiss={() => setDataModalMessages(null)}>
				<IonToolbar>
					<IonTitle>CHI TIẾT THÔNG BÁO</IonTitle>
					<IonButtons slot="end">
						<IonButton color="light">
							<IonImg className="icon-close" src={`${IMG_MB}assets/icon/close-modal.png`} onClick={() => setDataModalMessages(null)} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonContent className="ion-content-message">
					<IonGrid className="ios ion-grid ion-grid-message hydrated">
						<p className="px-10 pb-10">{dataModalMessages?.Note}</p>
					</IonGrid>
				</IonContent>
			</IonModal>
		</IonPage>
	);
};

export default MessageSystem;
