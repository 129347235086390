import React, { useLayoutEffect, useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
	IonContent,
	IonPage,
	IonGrid,
	IonRow,
	IonCol,
	IonList,
	IonItem,
	IonIcon,
	IonModal,
	IonLabel,
	IonInput,
	IonButton,
	useIonToast,
	useIonAlert,
	IonImg
} from "@ionic/react";
import { useSelector, shallowEqual, connect } from "react-redux";
import { chevronForward, arrowBack } from "ionicons/icons";
import "./UserInfo.css";
import { defaultsDeep, get } from "lodash";
import { changePassword, changeWithdrawPassword, getUserBanks } from "../redux/auth/authCrud";
import * as auth from "../redux/auth/authRedux";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { eye } from "ionicons/icons";
import { IMG_MB } from "../constants";

const INIT_CHANGE_PASS = {
	oldPass: "",
	newPass: "",
	confirmPass: "",
};

const INIT_CHANGE_WITHDRAW_PASS = {
	oldPass: "",
	newPass: "",
	confirmPass: "",
};

const UserInfo: React.FC = (props: any) => {
	const [isShowModalChangePass, setIsShowModalChangePass] = useState(false);
	const [isShowModalChangeWithdrawPassword, setIsShowModalChangeWithdrawPassword] = useState(false);
	const modalChangePass = useRef<HTMLIonModalElement>(null);
	const modalChangeWithdrawPassword = useRef<HTMLIonModalElement>(null);
	const [dataChangePass, setDataChangePass] = useState(INIT_CHANGE_PASS);
	const [dataChangeWithdrawPass, setDataChangeWithdrawPass] = useState(INIT_CHANGE_PASS);
	const [userBanks, setUserBanks] = useState([]);
	const [isShowPass, setIsShowPass] = useState(false);

	const [present] = useIonToast();
	const [presentAlert] = useIonAlert();
	const history = useHistory();
	const { authToken, userInfo } = useSelector(
		(state: any) => ({
			authToken: state.auth.authToken,
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	const GetUserBanks = async () => {
		const { data } = await getUserBanks();

		if (data) {
			setUserBanks(data);
		}
	};

	useEffect(() => {
		GetUserBanks();
	}, []);

	useLayoutEffect(() => {
		if (!authToken) {
			history.push("/home");
		}
	}, [authToken, history]);

	if (!authToken) return <IonImg className="page-loading" src={`${IMG_MB}assets/v2/loading.webp`} />;

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const _changePassword = () => {
		const { oldPass, newPass, confirmPass } = dataChangePass;
		if (!oldPass) return presentToast("error", "Mật khẩu cũ không được để trống.");
		if (!newPass) return presentToast("error", "Mật khẩu mới không được để trống.");
		if (!confirmPass) return presentToast("error", "Nhập lại mật khẩu mới không được để trống.");
		if (newPass !== confirmPass) return presentToast("error", "Nhập lại mật khẩu mới không đúng.");

		changePassword(oldPass, newPass).then((res: any) => {
			if (res && res.data && res.data.Status === false) return presentToast("error", res?.data?.Message);
			presentAlert({
				header: "Thông Báo",
				message: res?.data?.Message,
				buttons: [
					{
						text: "OK",
						role: "confirm",
						handler: () => {
							props.logout();
							modalChangePass.current?.dismiss();
							setDataChangePass(INIT_CHANGE_PASS);
						},
					},
				],
				cssClass: "alert-custom",
				backdropDismiss: false,
			});
		});
	};

	const _changeWithdrawPassword = () => {
		const { oldPass, newPass, confirmPass } = dataChangeWithdrawPass;
		if (!oldPass) return presentToast("error", "Mật khẩu cũ không được để trống.");
		if (!newPass) return presentToast("error", "Mật khẩu mới không được để trống.");
		if (!confirmPass) return presentToast("error", "Nhập lại mật khẩu mới không được để trống.");
		if (newPass !== confirmPass) return presentToast("error", "Nhập lại mật khẩu mới không đúng.");

		changeWithdrawPassword(oldPass, newPass).then((res: any) => {
			if (res && res.data && res.data.Status === false) return presentToast("error", res?.data?.Message);
			presentAlert({
				header: "Thông Báo",
				message: res?.data?.Message,
				buttons: [
					{
						text: "OK",
						role: "confirm",
						handler: () => {
							modalChangeWithdrawPassword.current?.dismiss();
							setDataChangeWithdrawPass(INIT_CHANGE_WITHDRAW_PASS);
						},
					},
				],
				cssClass: "alert-custom",
				backdropDismiss: false,
			});
		});
	};

	return (
		<IonPage className="app-user-edit app-home app-user-banks ng-star-inserted ion-page can-go-back">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/information")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Thông Tin Cá Nhân
					</IonButton>
				</div>
			</div>
			<IonContent className="ion-content">
				<IonList style={{ marginTop: "-10px" }} className="mt-0 ion-list account-list md list-md hydrated">
					<IonItem className="ion-item item md in-list ion-focusable hydrated">
						<IonGrid className="ion-grid md hydrated">
							<IonRow className="ion-row md hydrated">
								<IonCol className="col-7-left md hydrated" size="7">
									<strong>Tên đăng nhập</strong>
								</IonCol>
								<IonCol className="col-5-right md hydrated" size="5">
									{userInfo?.UserName}
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
					<IonItem className="ion-item item md in-list ion-focusable hydrated">
						<IonGrid className="ion-grid md hydrated">
							<IonRow className="ion-row md hydrated">
								<IonCol className="col-7-left md hydrated" size="7">
									<strong>Họ và tên</strong>
								</IonCol>
								<IonCol className="col-5-right md hydrated" size="5">
									{get(userBanks, "[0].AccountName", "")}
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
					<IonItem className="ion-item item md in-list ion-focusable hydrated">
						<IonGrid className="ion-grid md hydrated">
							<IonRow className="ion-row md hydrated">
								<IonCol className="col-7-left md hydrated" size="7">
									<strong>Số điện thoại</strong>
								</IonCol>
								<IonCol className="col-5-right md hydrated" size="5">
									{userInfo?.Mobile}
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonItem>
					<IonItem className="ion-item item md in-list ion-focusable hydrated" onClick={() => setIsShowModalChangePass(true)}>
						<IonGrid className="ion-grid md hydrated">
							<IonRow className="ion-row md hydrated">
								<IonCol className="col-7-left md hydrated" size="7">
									<strong>Đổi mật khẩu đăng nhập</strong>
								</IonCol>
								<IonCol className="col-5-right md hydrated" size="5"></IonCol>
							</IonRow>
						</IonGrid>
						<IonIcon className="end-arrow ios hydrated" mode="ios" slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
					<IonItem className="ion-item item md in-list ion-focusable hydrated" onClick={() => setIsShowModalChangeWithdrawPassword(true)}>
						<IonGrid className="ion-grid md hydrated">
							<IonRow className="ion-row md hydrated">
								<IonCol className="col-7-left md hydrated" size="8">
									<strong>Đổi mã rút tiền</strong>
								</IonCol>
								<IonCol className="col-5-right md hydrated" size="4"></IonCol>
							</IonRow>
						</IonGrid>
						<IonIcon className="end-arrow ios hydrated" mode="ios" slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
				</IonList>
			</IonContent>
			{isShowModalChangePass && (
				<IonModal
					ref={modalChangePass}
					isOpen={isShowModalChangePass}
					onIonModalDidDismiss={() => setIsShowModalChangePass(false)}
					className="sc-ion-modal-md-h app-change-password sc-ion-modal-md-s md hydrated show-modal"
				>
					<div className="app-home homepage">
						<HeaderHome />
						<QuickOptions />
						<div className="btn-group">
							<IonButton className="btn-sic" size="small" onClick={() => modalChangePass.current?.dismiss()}>
								<IonIcon color="#000000" icon={arrowBack}></IonIcon>
							</IonButton>
							<IonButton className="btn-sic" size="small">
								Đổi Mật Khẩu Đăng Nhập
							</IonButton>
						</div>
					</div>
					<IonContent _ngcontent-kew-c20 className="ion-content update-account-info ios overscroll hydrated">
						<div _ngcontent-kew-c20="" className="frm-account">
							<div _ngcontent-kew-c20="" className="frm-item frm-item-border">
								<IonLabel _ngcontent-kew-c20="" className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
									Mật khẩu cũ
								</IonLabel>
								<div className="flex">
									<IonInput
										onIonChange={(e: any) => {
											setDataChangePass(defaultsDeep({ oldPass: e.target.value }, dataChangePass));
										}}
										_ngcontent-kew-c20=""
										mode="md"
										type={isShowPass ? "text" : "password"}
										className="ng-untouched ng-pristine ng-valid sc-ion-input-md-h sc-ion-input-md-s md hydrated ion-untouched ion-pristine ion-valid"
									></IonInput>
									<IonIcon className="icon--eye" icon={eye} onClick={() => setIsShowPass(!isShowPass)} />
								</div>
							</div>
							<div _ngcontent-kew-c20="" className="frm-item frm-item-border">
								<IonLabel _ngcontent-kew-c20="" className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
									Mật khẩu mới
								</IonLabel>
								<div className="flex">
									<IonInput
										onIonChange={(e: any) => {
											setDataChangePass(defaultsDeep({ newPass: e.target.value }, dataChangePass));
										}}
										_ngcontent-kew-c20=""
										mode="md"
										type={isShowPass ? "text" : "password"}
										className="ng-untouched ng-pristine ng-valid sc-ion-input-md-h sc-ion-input-md-s md hydrated ion-untouched ion-pristine ion-valid"
									></IonInput>
									<IonIcon className="icon--eye" icon={eye} onClick={() => setIsShowPass(!isShowPass)} />
								</div>
							</div>
							<div _ngcontent-kew-c20="" className="frm-item frm-item-border">
								<IonLabel _ngcontent-kew-c20="" className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
									Xác nhận mật khẩu mới
								</IonLabel>
								<div className="flex">
									<IonInput
										onIonChange={(e: any) => {
											setDataChangePass(defaultsDeep({ confirmPass: e.target.value }, dataChangePass));
										}}
										_ngcontent-kew-c20=""
										mode="md"
										type={isShowPass ? "text" : "password"}
										className="ng-untouched ng-pristine ng-valid sc-ion-input-md-h sc-ion-input-md-s md hydrated ion-untouched ion-pristine ion-valid"
									></IonInput>
									<IonIcon className="icon--eye" icon={eye} onClick={() => setIsShowPass(!isShowPass)} />
								</div>
							</div>
							<IonButton
								_ngcontent-kew-c20=""
								className="af-submit-btn ios button button-block button-solid ion-activatable ion-focusable hydrated"
								expand="block"
								onClick={() => _changePassword()}
							>
								Lưu Lại
							</IonButton>
						</div>
					</IonContent>
				</IonModal>
			)}
			{isShowModalChangeWithdrawPassword && (
				<IonModal
					ref={modalChangeWithdrawPassword}
					isOpen={isShowModalChangeWithdrawPassword}
					onIonModalDidDismiss={() => setIsShowModalChangeWithdrawPassword(false)}
					className="sc-ion-modal-md-h app-change-password sc-ion-modal-md-s md hydrated show-modal"
				>
					<div className="app-home homepage">
						<HeaderHome />
						<QuickOptions />
						<div className="btn-group">
							<IonButton className="btn-sic" size="small" onClick={() => modalChangeWithdrawPassword.current?.dismiss()}>
								<IonIcon color="#000000" icon={arrowBack}></IonIcon>
							</IonButton>
							<IonButton className="btn-sic" size="small">
								Đổi Mã Rút Tiền
							</IonButton>
						</div>
					</div>
					<IonContent _ngcontent-kew-c20 className="ion-content update-account-info ios overscroll hydrated">
						<div _ngcontent-kew-c20="" className="frm-account">
							<div _ngcontent-kew-c20="" className="frm-item frm-item-border">
								<IonLabel _ngcontent-kew-c20="" className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
									Mã rút tiền cũ
								</IonLabel>
								<div className="flex">
									<IonInput
										onIonChange={(e: any) => {
											setDataChangeWithdrawPass(defaultsDeep({ oldPass: e.target.value }, dataChangeWithdrawPass));
										}}
										_ngcontent-kew-c20=""
										mode="md"
										type={isShowPass ? "text" : "password"}
										className="ng-untouched ng-pristine ng-valid sc-ion-input-md-h sc-ion-input-md-s md hydrated ion-untouched ion-pristine ion-valid"
									></IonInput>
									<IonIcon className="icon--eye" icon={eye} onClick={() => setIsShowPass(!isShowPass)} />
								</div>
							</div>
							<div _ngcontent-kew-c20="" className="frm-item frm-item-border">
								<IonLabel _ngcontent-kew-c20="" className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
									Mã rút tiền mới
								</IonLabel>
								<div className="flex">
									<IonInput
										onIonChange={(e: any) => {
											setDataChangeWithdrawPass(defaultsDeep({ newPass: e.target.value }, dataChangeWithdrawPass));
										}}
										_ngcontent-kew-c20=""
										mode="md"
										type={isShowPass ? "text" : "password"}
										className="ng-untouched ng-pristine ng-valid sc-ion-input-md-h sc-ion-input-md-s md hydrated ion-untouched ion-pristine ion-valid"
									></IonInput>
									<IonIcon className="icon--eye" icon={eye} onClick={() => setIsShowPass(!isShowPass)} />
								</div>
							</div>
							<div _ngcontent-kew-c20="" className="frm-item frm-item-border">
								<IonLabel _ngcontent-kew-c20="" className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
									Xác nhận mã rút tiền mới
								</IonLabel>
								<div className="flex">
									<IonInput
										onIonChange={(e: any) => {
											setDataChangeWithdrawPass(defaultsDeep({ confirmPass: e.target.value }, dataChangeWithdrawPass));
										}}
										_ngcontent-kew-c20=""
										mode="md"
										type={isShowPass ? "text" : "password"}
										className="ng-untouched ng-pristine ng-valid sc-ion-input-md-h sc-ion-input-md-s md hydrated ion-untouched ion-pristine ion-valid"
									></IonInput>
									<IonIcon className="icon--eye" icon={eye} onClick={() => setIsShowPass(!isShowPass)} />
								</div>
							</div>
							<IonButton
								_ngcontent-kew-c20=""
								className="af-submit-btn ios button button-block button-solid ion-activatable ion-focusable hydrated"
								expand="block"
								onClick={() => _changeWithdrawPassword()}
							>
								Lưu Lại
							</IonButton>
						</div>
					</IonContent>
				</IonModal>
			)}
		</IonPage>
	);
};

export default connect(null, auth.actions)(UserInfo);
