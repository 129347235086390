import axios from "axios";
import { API_URL } from "../../constants";

export function register(params) {
  return axios.post(`${API_URL}auth/register`, params);
}

export function login(params) {
  return axios.post(`${API_URL}auth/login`, params);
}

export function getUserByToken() {
  return axios.get(`${API_URL}auth/me`);
}

export function getResult(codeName) {
  return axios.get(`${API_URL}api/GetResult?codeName=${codeName}`);
}

export function getChannels() {
  return axios.get(`${API_URL}api/GetChannels`);
}

export function getBetTypes() {
  return axios.get(`${API_URL}api/GetBetTypes`);
}

export function getTickets() {
  return axios.get(`${API_URL}api/GetTickets`);
}

export function playBet(params) {
  return axios.post(`${API_URL}api/Bet`, params);
}

export function getBetLogNohu() {
  return axios.get(`${API_URL}api/GetBetLogNohu`);
}


export function getUserInfo() {
  return axios.get(`${API_URL}api/GetUserInfo`);
}

export function getSettings() {
  return axios.get(`${API_URL}api/GetSettings`);
}

export function getBetHistory() {
  return axios.get(`${API_URL}api/GetBetHistory`);
}

export function changePassword(oldPass, newPass) {
  return axios.get(`${API_URL}api/ChangePassword?oldPass=${oldPass}&newPass=${newPass}`);
}

export function applyReward(params) {
  return axios.post(`${API_URL}api/applyReward`, params);
}

export function betCheckingProcessList(params) {
  return axios.post(`${API_URL}api/BetCheckingProcessList`, params);
}

export function getMemberDownlineDetail(Username) {
  return axios.get(`${API_URL}api/GetMemberDownlineDetail?Username=${Username}`);
}

export function getRewards() {
  return axios.get(`${API_URL}api/rewards`);
}

export function depositUsdt(params) {
  return axios.post(`${API_URL}api/DepositUsdt`, params);
}

export function withdrawUsdt(params) {
  return axios.post(`${API_URL}api/WithdrawUsdt`, params);
}

export function getRewardPointLog(params) {
  return axios.post(`${API_URL}api/RewardPointLog`, params);
}

export function getRewardsRequest() {
  return axios.get(`${API_URL}api/RewardsRequest`);
}

export function changeWithdrawPassword(oldPass, newPass) {
  return axios.get(`${API_URL}api/ChangeWithdrawPassword?oldPass=${oldPass}&newPass=${newPass}`);
}

export function getChannelByCode(codeName) {
  return axios.get(`${API_URL}api/GetChannelByCode?codeName=${codeName}`);
}

export function setPackage(packageName) {
  return axios.get(`${API_URL}api/SetPackage?packageName=${packageName}`);
}

export function getPackages() {
  return axios.get(`${API_URL}api/GetPackages`);
}

export function getBanks() {
  return axios.get(`${API_URL}api/GetBanks`);
}

export function getNotifications() {
  return axios.get(`${API_URL}api/GetNotifications`);
}

export function setUserBank(params) {
  return axios.post(`${API_URL}api/SetUserBank`, params);
}

export function getUserBanks() {
  return axios.get(`${API_URL}api/GetUserBanks`);
}

export function depositBank(params) {
  return axios.post(`${API_URL}api/DepositBank`, params);
}

export function withdrawBank(params) {
  return axios.post(`${API_URL}api/WithdrawBank`, params);
}

export function depositHistory() {
  return axios.get(`${API_URL}api/DepositHistory`);
}

export function withdrawHistory() {
  return axios.get(`${API_URL}api/WithdrawHistory`);
}

export function getAutoBankList() {
  return axios.get(`${API_URL}api/GetAutoBankList`);
}

export function autoBankDeposit(params) {
  return axios.post(`${API_URL}api/AutoBankDeposit`, params);
}

export function getPosts() {
  return axios.get(`${API_URL}api/GetPosts`);
}

export function getBalanceLog() {
  return axios.get(`${API_URL}api/GetCreditLog`);
}

export function getBalanceLogDetail(balanceLogId) {
  return axios.get(`${API_URL}api/GetBalanceLogDetail?balanceLogId=${balanceLogId}`);
}

export function addUserDownline(params) {
  return axios.post(`${API_URL}api/AddUserDownline`, params);
}

export function getMembersDownline() {
  return axios.get(`${API_URL}api/GetMembersDownline`);
}

export function getPromotions() {
  return axios.get(`${API_URL}api/GetPromotions`);
}

export function collectAllCredits(game) {
  return axios.get(`${API_URL}api/CollectAllCredits?game=${game}`);
}

export function getWalletTransfer() {
  return axios.get(`${API_URL}api/GetWalletTransfer`);
}

export function getUserVipInfo() {
  return axios.get(`${API_URL}api/GetUserVipInfo`);
}

export function setGameActive(gameName) {
  return axios.get(`${API_URL}api/SetGameActive?gameName=${gameName}&isMobile=true`);
}

export function transferMoney(toUserId, amount, withdrawPassword) {
  return axios.get(`${API_URL}api/TransferMoney?toUserId=${toUserId}&amount=${amount}&withdrawPassword=${withdrawPassword}`);
}

export function userRequestAgent(periodTime) {
  return axios.get(`${API_URL}api/UserRequestAgent?periodTime=${periodTime}`);
}

export function applyPromotion(promotionId) {
  return axios.get(`${API_URL}api/ApplyPromotion?promotionId=${promotionId}`);
}

export function getMemberStatistic(playerId, startTime, endTime) {
  return axios.get(`${API_URL}api/GetMemberStatistic?playerId=${playerId}&startTime=${startTime}&endTime=${endTime}`);
}

export function cancelTicket(ticketId) {
  return axios.get(`${API_URL}api/CancelTicket?ticketId=${ticketId}`);
}

export function getMessages() {
  return axios.get(`${API_URL}api/GetMessages`);
}

export function getNews() {
  return axios.get(`${API_URL}api/GetNews`);
}

export function getQuestionList() {
  return axios.get(`${API_URL}api/GetQuestionList`);
}

export function getIncomeAgentReport(reportMonth) {
  return axios.get(`${API_URL}api/GetAgentIncomeReport?reportMonth=${reportMonth}`);
}

export function selectAutoBankRequest(ref_id) {
  return axios.get(`${API_URL}api/SelectAutoBankRequest?ref_id=${ref_id}`);
}

export function getCompanyBanks() {
  return axios.get(`${API_URL}api/GetCompanyBanks`);
}

export function getAgentIncomeReportCurrent() {
  return axios.get(`${API_URL}api/GetAgentIncomeReportCurrent`);
}

export function getAgentIncomeReportLast() {
  return axios.get(`${API_URL}api/GetAgentIncomeReportLast`);
}

export function applyGiftCode(codeName) {
  return axios.get(`${API_URL}api/ApplyGiftCode?codeName=${codeName}`);
}

export function applyGiftCodeDetail(codeName) {
  return axios.get(`${API_URL}api/ApplyGiftCodeDetail?codeName=${codeName}`);
}

export function getGiftCodeByCodeName(codeName) {
  return axios.get(`${API_URL}api/GetGiftCodeByCodeName?codeName=${codeName}`);
}

export function cardDeposit(params) {
  return axios.post(`${API_URL}api/Card/Deposit`, params);
}

export function getContactInfo() {
  return axios.get(`${API_URL}api/GetContactInfo`);
}

export function getHotNumbers() {
  return axios.get(`${API_URL}api/GetHotNumbers`);
}