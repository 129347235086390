import React, { useEffect, useState, useRef } from "react";
import { IonImg, IonCheckbox, IonButton, IonIcon, IonItem, IonInput, useIonToast } from "@ionic/react";
import { eye } from "ionicons/icons";
import { connect } from "react-redux";
import { login } from "../../redux/auth/authCrud";
import * as auth from "../../redux/auth/authRedux";
import { defaultsDeep, toLower } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { collectAllCredits } from "../../redux/auth/authCrud";

import "./LoginModal.css";
import { IMG_MB } from "../../constants";

interface LoginProps {
	setIsShowModalLogin: any;
	login: any;
}

const LoginModal: React.FC<LoginProps> = (props: LoginProps) => {
	const [present] = useIonToast();
	const [isShowPass, setIsShowPass] = useState(false);
	const [isSaveInfo, setIsSaveInfo] = useState(false);
	const { setIsShowModalLogin } = props;

	const inputUsername = useRef<HTMLIonInputElement>(null);
	const inputPassword = useRef<HTMLIonInputElement>(null);

	useEffect(() => {
		if (localStorage.getItem("SAVE_LOGIN")) {
			const infoLogin = JSON.parse(`${localStorage.getItem("SAVE_LOGIN")}`);
			inputUsername.current!.value = infoLogin.username;
			inputPassword.current!.value = infoLogin.password;
			setIsSaveInfo(true);
		}
	}, []);

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const _login = () => {
		const params = { username: toLower(`${inputUsername.current?.value}`), password: inputPassword.current?.value, deviceId: uuidv4() };

		if (isSaveInfo) {
			localStorage.setItem("SAVE_LOGIN", JSON.stringify(params));
		} else {
			localStorage.removeItem("SAVE_LOGIN");
		}

		if (!params.username) return presentToast("error", "Tên đăng nhập không được để trống");
		if (!/^[a-zA-Z0-9_]*$/.test(params.username)) return presentToast("error", "Tên đăng nhập không được chứa ký tự đặc biệt");
		if (!params.password) return presentToast("error", "Mật khẩu không được để trống");

		login(params).then((res: any) => {
			const { data } = res;
			if (data.error) {
				return presentToast("error", data.error.message);
			}

			if (data && data.Token && data.user) {
				props.login(defaultsDeep({ deviceId: params.deviceId, logoutTime: Date.now() + 240 * 60 * 1000 }, data));
				_collectAllCredits();
			}

			setIsShowModalLogin(false);
		});
	};

	const _collectAllCredits = () => {
		collectAllCredits("");
	};

	return (
		<div className="app-login">
			<div className="login login__container">
				<p className="login-p">đăng nhập</p>
				<div className="close-up">
					<IonImg src={`${IMG_MB}assets/noti-detail/noti-close.svg`} onClick={() => setIsShowModalLogin(false)} />
				</div>
				<main>
					<IonItem className="login__username  item-interactive item-input item-has-placeholder item md item-lines-none ion-focusable hydrated ion-star-inserted ion-pristine ion-valid ion-touched">
						<div className="flex" style={{ width: "100%" }}>
							<div className="flex flex-align" style={{ width: "auto" }}>
								<img alt="logo-user" className="login__icon--user" src="assets/login/user-icon.png" />
							</div>
							<IonInput
								autocomplete="username"
								placeholder="Tên đăng nhập"
								className="sc-ion-input-md-h sc-ion-input-md-s md hydrated ion-star-inserted ion-pristine ion-valid ion-touched"
								ref={inputUsername}
								// onIonChange={(e: any) => {
								// 	setDataLogin(defaultsDeep({ username: e.target.value }, dataLogin));
								// }}
							/>
						</div>
					</IonItem>
					<IonItem className="login__username  item-interactive item-input item-has-placeholder item md item-lines-none ion-focusable hydrated ion-star-inserted ion-pristine ion-valid ion-touched">
						<div className="flex" style={{ width: "100%" }}>
							<div className="flex flex-align" style={{ width: "auto" }}>
								<img alt="logo-user" className="login__icon--user" src="assets/login/pwd-icon.png" />
							</div>
							<IonInput
								autocomplete="current-password"
								placeholder="Mật khẩu"
								type={isShowPass ? "text" : "password"}
								className="sc-ion-input-md-h sc-ion-input-md-s ion-star-inserted ion-untouched ion-pristine ion-valid"
								ref={inputPassword}
								// onIonChange={(e: any) => {
								// 	setDataLogin(defaultsDeep({ password: e.target.value }, dataLogin));
								// }}
							/>
							<IonIcon className="login__icon--eye" icon={eye} onClick={() => setIsShowPass(!isShowPass)} />
						</div>
					</IonItem>
					<div className="login__options flex flex-jus">
						<div className="login__remember flex flex-align">
							<IonCheckbox
								checked={isSaveInfo}
								color="success"
								mode="md"
								name="isSaveInfo"
								className="ion-color ion-color-success md interactive hydrated ion-untouched ion-pristine ion-valid"
								role="checkbox"
								aria-checked="false"
								aria-labelledby="ion-cb-9-lbl"
								onIonChange={(e) => setIsSaveInfo(e.detail.checked)}
							/>
							<span className="login__text--opts login__text--red login__text--bold">Nhớ mật khẩu</span>
						</div>
						<div
							className="login__text--opts login__text--red login__text--bold"
							onClick={() => presentToast("error", "Vui lòng liên hệ với bộ phận chăm sóc khách hàng để được cấp lại mật khẩu mới.")}
						>
							Quên mật khẩu
						</div>
					</div>
					<div className="login__button">
						<IonButton className="login__button--signin bold ion-activatable" expand="block" onClick={() => _login()}>
							Đăng nhập
						</IonButton>
					</div>
				</main>
			</div>
		</div>
	);
};

export default connect(null, auth.actions)(LoginModal);
