import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonIcon, IonButton } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { getQuestionList } from "../redux/auth/authCrud";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./UserInfo.css";

const QuickView: React.FC = () => {
	const history = useHistory();

	const [showQuickView, setShowQuickView] = useState(null);
	const [questionList, setQuestionList] = useState([]);

	const GetQuestionList = async () => {
		const { data } = await getQuestionList();

		if (data) {
			setQuestionList(data);
		}
	};

	useEffect(() => {
		GetQuestionList();
	}, []);

	return (
		<IonPage className="app-user-edit app-home app-user-banks ng-star-inserted ion-page can-go-back">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/information")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Câu Hỏi Thường Gặp
					</IonButton>
				</div>
			</div>
			<IonContent className="ion-content">
				<div className="p-10 flex gap-2 items-start flex-col">
					{questionList.map((item: any, index: any) => {
						return (
							<React.Fragment key={index}>
								<div className="flex gap-4 items-center pb-2">
									<div className="bg-F5BC27 text-black w-30 h-30 rounded-full items-center justify-center flex font-bold">{index + 1}</div>
									<span className="underline text-154AB1 font-medium cursor-pointer" onClick={() => setShowQuickView(item?.Id)}>
										{item?.Title}
									</span>
								</div>
								{showQuickView === item?.Id && <div className="bg-EDEDED p-4 rounded-md border border-solid border-F5BC27">{item?.Description}</div>}
							</React.Fragment>
						);
					})}
				</div>
			</IonContent>
		</IonPage>
	);
};

export default QuickView;
