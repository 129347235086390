import React from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonIcon, IonGrid, IonRow, IonCol, IonText, IonImg, IonButton, IonItem, IonLabel, IonList } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useSelector, shallowEqual } from "react-redux";
import { chevronForward } from "ionicons/icons";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./RewardPoints.css";
import { IMG_MB } from "../constants";
import { formatMoney } from "../util";

const RewardPoints: React.FC = () => {
	const history = useHistory();

	const { userInfo } = useSelector(
		(state: any) => ({
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/account")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Điểm Thưởng
					</IonButton>
				</div>
			</div>
			<IonContent className="app-agency app-rewards-point">
				<div className="sic-title-vip-info">
					<div className="text-vip">Bạn đang có</div>
					<span className="title">{formatMoney(userInfo?.RewardPoint)} Điểm</span>
				</div>
				<IonGrid className="ios agency-ion-grid">
					<IonRow className="ion-row agency-section">
						<IonCol size="12">
							<div className="agency-ion-col">
								<IonImg className="m-w-20px" src={`${IMG_MB}assets/icon/icon-star.png`} />
								<IonText className="">Khi bạn Đặt Cược các trò chơi trên hệ thống VIBET88, bạn sẽ nhận được Điểm Thưởng.</IonText>
							</div>
						</IonCol>
						<IonCol size="12">
							<div className="agency-ion-col">
								<IonImg className="m-w-20px" src={`${IMG_MB}assets/icon/icon-star.png`} />
								<IonText className="">Điểm thưởng có thể đổi thành các Phần Quà hoặc quy đổi thành Tiền cộng vào tài khoản của bạn.</IonText>
							</div>
						</IonCol>
					</IonRow>
				</IonGrid>
				<IonList className="agent-ion-list" no-line>
					<IonItem className="ion-item" onClick={() => history.push("/rewards-exchange")}>
						<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/doithuong.png`} />
						<IonLabel className="">Đổi thưởng</IonLabel>
						<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
					<IonItem className="ion-item" onClick={() => history.push("/history-add-points")}>
						<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/lichsucongdiem.png`} />
						<IonLabel className="">Lịch sử cộng điểm</IonLabel>
						<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
					<IonItem className="ion-item" onClick={() => history.push("/reward-history")}>
						<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/lichsudoithuong.png`} />
						<IonLabel className="">Lịch sử đổi thưởng</IonLabel>
						<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default RewardPoints;
