import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonIcon, IonImg, IonButton, IonList, useIonViewDidEnter } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useSelector, shallowEqual } from "react-redux";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { getRewardsRequest } from "../redux/auth/authCrud";

import "./RewardPoints.css";
import { IMG_MB } from "../constants";
import { formatMoney } from "../util";
import moment from "moment";

const RewardHistory: React.FC = () => {
	const history = useHistory();
	const [choseReward, setChoseReward] = useState<any>(null);
	const [dataRewardsRequest, setDataRewardsRequest] = useState<any>(null);

	const GetRewardsRequest = async () => {
		const res = await getRewardsRequest();
		if (res && res.data) {
			setDataRewardsRequest(res.data);
		}
	};

	useIonViewDidEnter(() => {
		GetRewardsRequest();
	});

	const { userInfo } = useSelector(
		(state: any) => ({
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	return (
		<IonPage className="app-bet-history">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/reward-points")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Lịch Sử Đổi Thưởng
					</IonButton>
				</div>
			</div>
			<IonContent className="app-agency app-rewards-point">
				<div className="sic-title-vip-info">
					<div className="text-vip">Bạn đang có</div>
					<span className="title">{formatMoney(userInfo?.RewardPoint)} Điểm</span>
				</div>
				<IonList className="vibet88-bet-history p-4px">
					{dataRewardsRequest &&
						dataRewardsRequest.map((item: any, index: number) => {
							const _reReward = JSON.parse(item?.RewardJson);
							return (
								<React.Fragment key={index}>
									<div className={`history-ion-item ${choseReward?.Id === item?.Id ? "active" : ""}`}>
										<div className="history-item" onClick={() => setChoseReward(item)}>
											<div className="history-item-row">
												<div className="title">
													<span className="date">{moment(item?.CreatedTime).format("DD/MM/YYYY")}</span>
													<span className="num">{item?.RewardTypeName}</span>
												</div>
												<div>
													<IonImg src={`${IMG_MB}assets/icon/icon-down-history.png`} />
												</div>
											</div>
											<div className="history-item-row-bottom">
												<div className="left">
													<span className="title">Điểm đổi:</span>
													<span className="num">{formatMoney(item?.ConversionPoints)}</span>
												</div>
												<div className="right flex-1 flex gap-2 items-end justify-content-end">
													<span className={`num ${item?.Status === 0 ? "text-FF0000" : item?.Status === 1 ? "text-2A5B84" : ""}`}>{item?.StatusName}</span>
												</div>
											</div>
										</div>
									</div>
									{choseReward?.Id === item?.Id && (
										<div className="history-type-game">
											<div className="game-row">
												<div className={`row-type active`}>
													<div className="top">
														<div className="flex gap-5">
															<span className="title text-5A5A5A font-semibold">Phần quà:</span>
															<span className="num text-black font-semibold">
																{item?.RewardType === 2 ? _reReward?.RewardName : formatMoney(_reReward?.ConversionMoney)}
															</span>
														</div>
													</div>
													{item?.Note && (
														<div className={`bottom flex-1 flex ${item?.SupportNote ? `border-row-bottom` : ``}`}>
															<div className="flex-1 flex gap-5 items-end">
																<span className="title text-5A5A5A font-semibold">Địa chỉ:</span>
																<span className="num text-black font-semibold">{item?.Note}</span>
															</div>
														</div>
													)}
													{item?.SupportNote && (
														<div className="bottom flex-1 flex">
															<div className="flex-1 flex gap-5 items-end">
																<span className="title text-5A5A5A font-semibold">Ghi chú:</span>
																<span className="num text-black font-semibold">{item?.SupportNote}</span>
															</div>
														</div>
													)}
												</div>
											</div>
										</div>
									)}
								</React.Fragment>
							);
						})}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default RewardHistory;
