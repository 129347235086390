import React from "react";
import { IonToolbar, IonImg } from "@ionic/react";
import { useHistory } from "react-router-dom";

import "../pages/Home.css";
import { IMG_MB } from "../constants";

const QuickOptions: React.FC = () => {
	const history = useHistory();
	return (
		<div className="app-home homepage toolbar-header">
			<IonToolbar className="quick-options">
				<ul>
					<li onClick={() => history.push("/deposit-withdraw/0")}>
						<div className="menu">
							<div className="img">
								<IonImg className="nap-tien" src={`${IMG_MB}assets/home/icon-naptien.png`} />
							</div>
						</div>
						<p>Nạp Tiền</p>
					</li>
					<li onClick={() => history.push("/deposit-withdraw/2")}>
						<div className="menu">
							<div className="img">
								<IonImg className="rut-tien" src={`${IMG_MB}assets/home/icon-ruttien.png`} />
							</div>
						</div>
						<p>Rút Tiền</p>
					</li>
					<li onClick={() => history.push("/promotion")}>
						<div className="menu">
							<div className="img">
								<IonImg className="thong-bao" src={`${IMG_MB}assets/home/icon-khuyenmai.png`} />
							</div>
						</div>
						<p>Khuyến Mãi</p>
					</li>
					<li onClick={() => history.push("/reward-points")}>
						<div className="menu">
							<div className="img">
								<IonImg className="ho-tro" src={`${IMG_MB}assets/home/Icon-diem-thuong.png`} />
							</div>
						</div>
						<p>Điểm Thưởng</p>
					</li>
				</ul>
			</IonToolbar>
		</div>
	);
};

export default QuickOptions;
