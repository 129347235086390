// import moment from "moment";
import * as auth from "./auth/authRedux";
// import { get, includes } from "lodash";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    (config) => {
      const {
        auth: { authToken },
      } = store.getState();

      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }

      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { status } = error.response || {};
      if ([401].includes(status)) {
        store.dispatch(auth.actions.logout());
        window.location.href = "/home?login=true";
      }
      return Promise.reject(error);
    }
  );
}
