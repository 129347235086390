import React, { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonImg, IonGrid, IonCol, IonRow } from "@ionic/react";
import { useSelector, shallowEqual } from "react-redux";

import SlideHome from "../components/SlideHome";
import HeaderHome from "../components/HeaderHome";
import MenuGames from "../components/MenuGames";

import "./Home.css";
import { IMG_MB } from "../constants";

const SlotGame: React.FC = () => {
	const history = useHistory();
	const { authToken } = useSelector(
		(state: any) => ({
			authToken: state.auth.authToken,
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	useLayoutEffect(() => {
		if (!authToken) {
			window.location.href = "/home?login=true";
		}
	}, [authToken, history]);

	if (!authToken) return <IonImg className="page-loading" src={`${IMG_MB}assets/v2/loading.webp`} />;

	return (
		<IonPage className="app-home">
			<HeaderHome />
			<IonContent fullscreen className="homepage">
				<div className="home-main">
					<SlideHome />
					<MenuGames />
					<div className="lottery-category">
						<IonGrid className="app-lottery pt-0 list-lt bg-white">
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Nổ Hũ - Bắn Cá </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonCol className="item-lottery-col" size="4">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/28.png`} onClick={() => history.push("/nohu-games")} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/36.png`} onClick={() => history.push("/nohu-games")} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/2.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/35.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/34.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/33.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/3.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/9.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/19.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/32.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/31.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/30.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/11.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/12.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/13.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/14.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/16.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/17.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/20.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/21.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/22.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/23.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/24.png`} />
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default SlotGame;
