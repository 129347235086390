import React, { useEffect, useState } from "react";
import { IonContent, IonPage, IonIcon, IonButton, useIonAlert } from "@ionic/react";
import { getPackages, setPackage, getUserInfo } from "../redux/auth/authCrud";
import * as auth from "../redux/auth/authRedux";
import "./BetPackage.css";
import { get, reduce, includes } from "lodash";
import { formatMoney } from "../util";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { arrowBack } from "ionicons/icons";

const BetPackage: React.FC = () => {
	const history = useHistory();
	const [presentAlert] = useIonAlert();
	const dispatch = useDispatch();
	const [dataPackages, setDataPackages] = useState<any>([]);
	const { userInfo } = useSelector((state: any) => ({ userInfo: state.auth.user }), shallowEqual);

	const getListPackages = async () => {
		const { data } = await getPackages();
		if (data) {
			const newData = reduce(
				data,
				(res: any, item: any) => {
					(res[item.Name] || (res[item.Name] = [])).push(item);
					return res;
				},
				{}
			);
			setDataPackages(newData);
		}
	};

	const setDataPackage = async (packageName: any) => {
		if (userInfo) {
			await setPackage(packageName);
			getUserInfo().then((resUser) => {
				dispatch(auth.actions.fulfillUser(get(resUser, "data", null)));
			});
		}
	};

	useEffect(() => {
		getListPackages();
	}, []);

	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.goBack()}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Chọn Mức Trúng
					</IonButton>
				</div>
			</div>
			<IonContent>
				<div className="ion-padding">
					Gói cược lô đề tại VIBET88 được thiết kế nhằm phù hợp với nhu cầu của từng người chơi. Mỗi gói cược sẽ có mức tỷ lệ trả thưởng và hoa hồng hoàn trả khác
					nhau.
				</div>
				{dataPackages[get(userInfo, "Package")] && (
					<div className="ion-padding pt-0 bet-package">
						<div className="flex px-1 bg-title">
							<span className="flex-1">{`Mức Trúng ${get(userInfo, "Package")}`}</span>
							<IonButton
								className="rounded-none"
								color="danger"
								onClick={() => {
									presentAlert({
										header: "Thông Báo",
										message: `Bạn đang chọn Mức Trúng ${get(userInfo, "Package")}`,
										buttons: ["OK"],
										cssClass: "alert-custom",
										id: "bet-success",
									});
								}}
							>
								Đã Chọn
							</IonButton>
						</div>
						<table className="tblDauDuoiOnly">
							<tbody>
								<tr className="title">
									<td style={{ whiteSpace: "nowrap" }}>Hình Thức Cược</td>
									<td>MN - MT</td>
									<td className="line-height-20">
										Hoa Hồng <br /> MN - MT
									</td>
									<td>MB</td>
									<td className="line-height-20">
										Hoa Hồng <br /> MB
									</td>
								</tr>
								{dataPackages[get(userInfo, "Package")] &&
									dataPackages[get(userInfo, "Package")].map((pack: any, indexPack: any) => {
										return (
											<tr key={indexPack}>
												<td>{get(pack, "BetTypeName")}</td>
												<td>{includes([2, 43, 12, 13], pack?.BetTypeId) ? "-" : formatMoney(get(pack, "WinRate"))}</td>
												<td>{includes([2, 43, 12, 13], pack?.BetTypeId) ? "-" : `${get(pack, "Percent")}%`}</td>
												<td>{includes([44, 41, 42], pack?.BetTypeId) ? "-" : formatMoney(get(pack, "WinRateMB"))}</td>
												<td>{includes([44, 41, 42], pack?.BetTypeId) ? "-" : `${get(pack, "PercentMB")}%`}</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div>
				)}

				{Object.keys(dataPackages).map((item: any, index) => {
					if (get(userInfo, "Package") === item) return <React.Fragment key={index}></React.Fragment>;
					return (
						<div className="ion-padding pt-0 bet-package" key={index}>
							<div className="flex px-1 bg-title">
								<span className="flex-1">{`Mức Trúng ${item}`}</span>
								{get(userInfo, "Package", "G") === item ? (
									<IonButton
										className="rounded-none"
										color="danger"
										onClick={() => {
											presentAlert({
												header: "Thông Báo",
												message: `Bạn đang chọn Mức Trúng ${item}`,
												buttons: ["OK"],
												cssClass: "alert-custom",
												id: "bet-success",
											});
										}}
									>
										Đã Chọn
									</IonButton>
								) : (
									<IonButton
										className="rounded-none"
										color="light"
										onClick={() => {
											presentAlert({
												header: "Thông Báo",
												message: `Bạn muốn đổi sang Mức Trúng ${item}`,
												buttons: [
													{
														text: "Hủy",
														role: "cancel",
													},
													{
														text: "OK",
														role: "confirm",
														handler: () => setDataPackage(item),
													},
												],
												cssClass: "alert-custom",
												id: "bet-success",
											});
										}}
									>
										Chọn
									</IonButton>
								)}
							</div>
							<table className="tblDauDuoiOnly">
								<tbody>
									<tr className="title">
										<td style={{ whiteSpace: "nowrap" }}>Hình Thức Cược</td>
										<td>MN - MT</td>
										<td className="line-height-20">
											Hoa Hồng <br /> MN - MT
										</td>
										<td>MB</td>
										<td className="line-height-20">
											Hoa Hồng <br /> MB
										</td>
									</tr>
									{dataPackages[item] &&
										dataPackages[item].map((pack: any, indexPack: any) => {
											return (
												<tr key={indexPack}>
													<td>{get(pack, "BetTypeName")}</td>
													<td>{includes([2, 43, 12, 13], pack?.BetTypeId) ? "-" : formatMoney(get(pack, "WinRate"))}</td>
													<td>{includes([2, 43, 12, 13], pack?.BetTypeId) ? "-" : `${get(pack, "Percent")}%`}</td>
													<td>{includes([44, 41, 42], pack?.BetTypeId) ? "-" : formatMoney(get(pack, "WinRateMB"))}</td>
													<td>{includes([44, 41, 42], pack?.BetTypeId) ? "-" : `${get(pack, "PercentMB")}%`}</td>
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					);
				})}
			</IonContent>
		</IonPage>
	);
};

export default BetPackage;
