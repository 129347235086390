export const getDayOfWeek = (date) => {
  const dayOfWeek = new Date(date).getDay();
  switch (dayOfWeek) {
    case 0:
      return "Chủ Nhật";
    case 1:
      return "Thứ Hai";
    case 2:
      return "Thứ Ba";
    case 3:
      return "Thứ Tư";
    case 4:
      return "Thứ Năm";
    case 5:
      return "Thứ Sáu";
    case 6:
      return "Thứ Bảy";
    default:
      break;
  }
};

export const setCookie = (key, value, timeSet) => {
  let timeDefault = (1000 * 60 * 24 * 30 * 365)
  let timeEp = timeSet ? timeSet : timeDefault
  let expires = new Date()
  expires.setTime(expires.getTime() + timeEp)
  document.cookie = key + '=' + encodeURIComponent(value) + ';expires=' + expires.toUTCString() + ';path=/'
}

export const removeCookie = (name) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;'
}

export const getCookie = (cname) => {
  var name = cname + "="
  var ca = document.cookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      if (c.substring(name.length, c.length) && c.substring(name.length, c.length) !== 'null') {
        return decodeURIComponent(c.substring(name.length, c.length))
      }
    }
  }
  return null
}

export function formatMoney(num = 0) {
  if (!num) return 0;
  const newNum = `${num}`.split(".");
  const num2 = newNum[1] ? `${newNum[1]}`.slice(0, 2) : ``;
  return `${newNum[0]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (num2 ? `.${num2}` : ``);
}

export function formatMoneyColor(num = 0) {
  if (!num) return <span className="text-[#276196]">0</span>
  const newNum = num.toString().split(".");
  const num2 = newNum[1] ? `${newNum[1]}000`.slice(0, 2) : ``;
  return <span className={newNum[0] < 0 ? "text-[#FF0000]" : "text-[#276196]"}>{`${newNum[0]}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (num2 ? `.${num2}` : ``)}</span>;
}

export const formatUUID = (uuid = ``) => {
  return uuid.split("-")[0]
};

export function generateCode() {
  return (Math.random() + 1).toString(36).substring(2);
}

export const downPercentNumber = (num, percent) => {
  return parseFloat((num * (1 - percent / 100)).toFixed(2));
}