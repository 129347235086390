import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, useIonAlert, IonImg, useIonViewDidEnter, useIonViewDidLeave } from "@ionic/react";
import { useSelector, shallowEqual, connect, useDispatch } from "react-redux";
import * as auth from "../redux/auth/authRedux";
import { setGameActive, getUserInfo, collectAllCredits } from "../redux/auth/authCrud";
import { get } from "lodash";

import "./Home.css";
import { IMG_MB } from "../constants";

const NohuGames: React.FC = (props: any) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [urlGame, setUrlGame] = useState("");
	const [isLoaded, setIsLoaded] = useState(false);
	const [isMounted, setIsMounted] = useState(false);

	const iframeRef = useRef(null);

	useEffect(() => {
		const requestFullscreen = () => {
			if (!iframeRef) return;

			const iframe: any = iframeRef.current;
			if (iframe?.requestFullscreen) {
				iframe.requestFullscreen();
			} else if (iframe?.mozRequestFullScreen) {
				// Firefox
				iframe.mozRequestFullScreen();
			} else if (iframe?.webkitRequestFullscreen) {
				// Chrome, Safari and Opera
				iframe.webkitRequestFullscreen();
			} else if (iframe?.msRequestFullscreen) {
				// IE/Edge
				iframe.msRequestFullscreen();
			}
		};

		const handleUserInteraction = () => {
			requestFullscreen();
			document.removeEventListener("click", handleUserInteraction);
		};

		document.addEventListener("click", handleUserInteraction);

		return () => {
			document.removeEventListener("click", handleUserInteraction);
		};
	}, [iframeRef]);

	const SetGameActive = async (gameCode: any) => {
		const res: any = await setGameActive(gameCode);

		if (res.data && res.data.error && res.data.error.message) {
			return presentAlert({
				header: "Thông Báo!",
				message: res.data.error.message,
				backdropDismiss: false,
				buttons: [
					{
						text: "OK",
						role: "confirm",
						handler: () => {
							history.push("/home");
						},
					},
				],
				cssClass: "alert-custom",
			});
		}

		if (!res.data) {
			return history.push("/home");
		}

		getUserInfo().then((resUser) => {
			dispatch(auth.actions.fulfillUser(get(resUser, "data", null)));
		});
		setIsLoaded(true);
		setUrlGame(res.data);
	};

	useIonViewDidEnter(() => {
		if (authToken) {
			SetGameActive("nohu");
		}
	});

	useIonViewDidLeave(() => {
		setUrlGame("");
		_collectAllCredits();
		setIsLoaded(false);
		setIsMounted(false);
	});

	const _collectAllCredits = () => {
		collectAllCredits("nohu");
	};

	const [presentAlert] = useIonAlert();

	const { authToken } = useSelector(
		(state: any) => ({
			authToken: state.auth.authToken,
		}),
		shallowEqual
	);

	useEffect(() => {
		if (!authToken) {
			window.location.href = "/home?login=true";
		}

		if (authToken) {
			SetGameActive("nohu");
		}

		// eslint-disable-next-line
	}, [authToken]);

	const handleIframeLoad = () => {
		setIsMounted(true);
	};

	return (
		<IonPage className="app-home">
			<IonContent className="homepage h-full">
				{authToken && urlGame && (
					<div className="home-main w-full h-full">
						<IonImg className="btn-x-slot-game" src={`${IMG_MB}assets/icon/icon-x.png`} onClick={(e) => history.push("/home")}></IonImg>
						<iframe
							ref={iframeRef}
							title="Game Nổ Hũ"
							className="w-full h-full"
							src={`${urlGame}`}
							data-mobile="1"
							width="100%"
							height="100%"
							frameBorder="0"
							sandbox="allow-same-origin allow-scripts allow-popups allow-forms allow-top-navigation"
							scrolling="yes"
							onLoad={handleIframeLoad}
							allowFullScreen
						></iframe>
					</div>
				)}
				{(!authToken || !urlGame || !isLoaded || !isMounted) && <IonImg className="page-loading" src={`${IMG_MB}assets/v2/loading.webp`} />}
			</IonContent>
		</IonPage>
	);
};

export default connect(null, auth.actions)(NohuGames);
