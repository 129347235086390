import React, { useState, useEffect, useRef } from "react";
import {
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonIcon,
	IonRippleEffect,
	IonImg,
	IonList,
	IonModal,
	IonButton,
	IonGrid,
	IonRow,
	IonCol,
	useIonAlert,
	useIonToast,
} from "@ionic/react";
import { getTickets, getChannels, getBetTypes, getBetHistory, getBetLogNohu } from "../redux/auth/authCrud";
import { defaultsDeep, orderBy, forEach, get, reduce, set, split, join, cloneDeep, sumBy, groupBy, size, toNumber, includes } from "lodash";
import { useHistory } from "react-router-dom";
import { formatMoney } from "../util";
import { BET_STATUS, IMG_MB } from "../constants";
import moment from "moment";
import { useSelector, shallowEqual } from "react-redux";
import { chevronBackOutline, arrowBack } from "ionicons/icons";
import { Clipboard } from "@capacitor/clipboard";

import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./BetHistory.css";

const INIT_CHOOSE_BET_HISTORY: any = {
	date: "",
	game: null,
	data: null,
};

const INIT_DATA_BET_HISTORY: any = {};
INIT_DATA_BET_HISTORY[moment().format("DD/MM/YYYY")] = [];

const BetHistory: React.FC = () => {
	const history = useHistory();
	const [presentAlert] = useIonAlert();
	const refDetailTicket = useRef<HTMLIonModalElement>(null);
	const modalResult = useRef<HTMLIonModalElement>(null);
	const [dataChannels, setDataChannels] = useState({});
	const [dataBetTypes, setDataBetTypes] = useState({});
	const [dataModalTicket, setDataModalTicket] = useState(null);
	const [dataModalResult, setDataModalResult] = useState<any>(null);
	const [dataDateHistory, setDataDateHistory] = useState<any>([]);
	const [dataBetHistory, setDataBetHistory] = useState<any>(INIT_DATA_BET_HISTORY);
	const [dataDateWaiting, setDataDateWaiting] = useState<any>([]);
	const [dataBetWaiting, setDataBetWaiting] = useState<any>(INIT_DATA_BET_HISTORY);
	const [chooseBetHistory, setChooseBetHistory] = useState(INIT_CHOOSE_BET_HISTORY);
	const [modalGameHistory, setModalGameHistory] = useState<any>(null);
	const [isBetWaiting, setIsBetWaiting] = useState(false);

	const [present] = useIonToast();
	const { userInfo } = useSelector((state: any) => ({ userInfo: state.auth.user }), shallowEqual);

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const writeToClipboard = async (message: any) => {
		await Clipboard.write({
			string: message,
		});
		presentToast("success", "Sao chép thành công.");
	};

	const GetDataChannels = async () => {
		const { data } = await getChannels();

		if (data) {
			const newData = reduce(
				data,
				(res: any, item) => {
					res[item.Id] = item;
					return res;
				},
				[]
			);
			setDataChannels(newData);
		}
	};

	const getDataBetTypes = async () => {
		const { data } = await getBetTypes();

		if (data) {
			const newData = reduce(
				data,
				(res: any, item) => {
					res[item.Id] = item;
					return res;
				},
				[]
			);
			setDataBetTypes(newData);
		}
	};

	const GetBetHistory = async () => {
		const resHistory = await getBetHistory();
		const resTicket = await getTickets();
		const resNohu = await getBetLogNohu();

		let newData: any[] = cloneDeep([]);
		let newDataWaiting: any[] = cloneDeep([]);

		if (resHistory && resHistory.data) {
			forEach(resHistory.data, (item) => {
				const betDate: any = moment(item.BetTime, "YYYY-MM-DD").format("DD/MM/YYYY");

				if (item.BetStatus === "running") {
					(newDataWaiting[betDate] || (newDataWaiting[betDate] = [])).push(item);
				} else {
					(newData[betDate] || (newData[betDate] = [])).push(item);
				}
			});
		}

		if (resTicket && resTicket.data) {
			forEach(resTicket.data, (item) => {
				const betDate: any =
					item.stopWon && item.BetStatus === "Cancel"
						? moment(item.UpdatedTime, "YYYY-MM-DD").format("DD/MM/YYYY")
						: moment(item.BetDate, "DD-MM-YYYY").format("DD/MM/YYYY");

				if (item.BetStatus === "Waiting") {
					(newDataWaiting[betDate] || (newDataWaiting[betDate] = [])).push(defaultsDeep({ Provider: "Xổ Số" }, item));
				} else {
					(newData[betDate] || (newData[betDate] = [])).push(defaultsDeep({ Provider: "Xổ Số" }, item));
				}
			});
		}

		if (resNohu && resNohu.data && resNohu.data.data) {
			forEach(resNohu.data.data, (item) => {
				const betDate: any = moment(item.BetTime, "MM/DD/YYYY HH:mm:ss").format("DD/MM/YYYY");
				if (item.BetStatus === "running") {
					newDataWaiting.push(defaultsDeep({ betDate }, item));
				} else {
					(newData[betDate] || (newData[betDate] = [])).push(
						defaultsDeep({ Provider: item?.GameProvider, BetTime: moment(item.BetTime, "MM/DD/YYYY HH:mm:ss").format("YYYY-MM-DD HH:mm:ss") }, item)
					);
				}
			});
		}

		const dateHistory = orderBy(
			Object.keys(newData).map((item) => ({ date: moment(item, "DD/MM/YYYY") })),
			["date"],
			["desc"]
		);

		const dateWaiting = orderBy(
			Object.keys(newDataWaiting).map((item) => ({ date: moment(item, "DD/MM/YYYY") })),
			["date"],
			["asc"]
		);

		setDataDateWaiting(dateWaiting);
		setDataBetWaiting(newDataWaiting);

		setDataDateHistory(dateHistory);
		setDataBetHistory(newData);
	};

	useEffect(() => {
		GetBetHistory();
		GetDataChannels();
		getDataBetTypes();
		// eslint-disable-next-line
	}, []);

	return (
		<IonPage className="app-bet-history">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/account")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton
						className={isBetWaiting ? "btn-sic" : "btn-sic active"}
						size="small"
						onClick={() => {
							setIsBetWaiting(false);
							setChooseBetHistory(INIT_CHOOSE_BET_HISTORY);
						}}
					>
						LỊCH SỬ CƯỢC
					</IonButton>
					<IonButton
						className={isBetWaiting ? "btn-sic active" : "btn-sic"}
						size="small"
						onClick={() => {
							setIsBetWaiting(true);
							setChooseBetHistory(INIT_CHOOSE_BET_HISTORY);
						}}
					>
						LỊCH SỬ CƯỢC TREO
					</IonButton>
				</div>
			</div>
			<IonContent className="ion-content ion-content-segment agency-form ios overscroll hydrated">
				<IonList className="vibet88-bet-history">
					{!isBetWaiting &&
						dataBetHistory &&
						dataDateHistory.map((item: any) => {
							const srtDate = moment(item.date, "DD/MM/YYYY").format("DD/MM/YYYY");
							const dataGroups = groupBy(dataBetHistory[srtDate], "Provider");
							return (
								<React.Fragment key={srtDate}>
									<div className={`history-ion-item ${chooseBetHistory.date === srtDate ? "active" : ""}`}>
										<div
											className="history-item"
											onClick={() => {
												setChooseBetHistory(defaultsDeep({ date: srtDate }, INIT_CHOOSE_BET_HISTORY));
											}}
										>
											<div className="history-item-row">
												<div className="title">
													<span className="date">{srtDate}</span>
													<span className="num">({size(dataBetHistory[srtDate]) > 0 ? size(dataBetHistory[srtDate]) : "Hôm nay bạn chưa đặt cược"})</span>
												</div>
												<div>
													<IonImg src={`${IMG_MB}assets/icon/icon-down-history.png`} />
												</div>
											</div>
											<div className="history-item-row-bottom">
												<div className="left">
													<span className="title">Tiền cược:</span>
													<span className="num">
														{formatMoney(
															toNumber(
																sumBy(dataBetHistory[srtDate], (bH: any) =>
																	!includes(["Cancel", "reject", "refund", "RejectGoal", "void", "RejectPenalty", "RejectRedCard"], bH.BetStatus)
																		? toNumber(bH.BetAmount)
																		: 0
																).toFixed(2)
															)
														)}{" "}
														K
													</span>
												</div>
												<div className="right flex-1 flex gap-2 items-end justify-between">
													<span className="title text-[#5A5A5A] text-[14px] font-semibold">Thắng / Thua:</span>
													<span className={`num ${toNumber(sumBy(dataBetHistory[srtDate], (bH: any) => toNumber(bH.WinLose)).toFixed(2)) > 0 ? "" : "red"}`}>
														{formatMoney(
															toNumber(
																sumBy(dataBetHistory[srtDate], (bH: any) =>
																	bH.Provider === "Xổ Số" ? toNumber(bH.WinloseForSum) : toNumber(bH.WinLose)
																).toFixed(2)
															)
														)}{" "}
														K
													</span>
												</div>
											</div>
										</div>
									</div>
									{chooseBetHistory.date === srtDate && (
										<div className="history-type-game">
											{Object.keys(dataGroups).map((game) => {
												return (
													<React.Fragment key={`${srtDate}_${game}`}>
														<div className="game-row">
															<div
																className={`row-type ${chooseBetHistory.game === game ? "active" : ""}`}
																onClick={() => {
																	const tempData = cloneDeep(chooseBetHistory);
																	const newData = orderBy(dataGroups[game], ["BetDate"], ["desc"]);
																	set(tempData, "game", game);
																	set(tempData, "data", newData);
																	setChooseBetHistory(tempData);
																}}
															>
																<div className="top">
																	<div className="flex gap-5">
																		<span className="title">{game}</span>
																		<span className="num">({size(dataGroups[game])})</span>
																	</div>
																	<div>
																		<span className="detail">Chi tiết =&gt;</span>
																	</div>
																</div>
																<div className="bottom flex-1 flex">
																	<div className="flex-1 flex gap-5 items-end">
																		<span className="title">Tiền cược:</span>
																		<span className="num">
																			{formatMoney(
																				toNumber(
																					sumBy(dataGroups[game], (gB) =>
																						!includes(["Cancel", "reject", "refund", "RejectGoal", "void", "RejectPenalty", "RejectRedCard"], gB.BetStatus)
																							? toNumber(gB.BetAmount)
																							: 0
																					).toFixed(2)
																				)
																			)}{" "}
																			K
																		</span>
																	</div>
																	<div className="flex-1 flex gap-5 items-end justify-between">
																		<span className="title">Thắng / Thua:</span>
																		<span className={`num ${toNumber(sumBy(dataGroups[game], (gB) => toNumber(gB.WinLose)).toFixed(2)) > 0 ? "" : "red"}`}>
																			{formatMoney(
																				toNumber(
																					sumBy(dataGroups[game], (gB) =>
																						gB.Provider === "Xổ Số" ? toNumber(gB.WinloseForSum) : toNumber(gB.WinLose)
																					).toFixed(2)
																				)
																			)}{" "}
																			K
																		</span>
																	</div>
																</div>
															</div>
														</div>
														{chooseBetHistory.game === game && chooseBetHistory.data && (
															<div className="history-game-detail">
																{chooseBetHistory.data.map((item: any, index: any) => {
																	if (item.Provider === "Xổ Số") {
																		const ChannelName = get(dataChannels, `[${item?.ChannelId}].Name`, "");
																		const Channel2Name = get(dataChannels, `[${item?.ChannelId2}].Name`, null);
																		const BetTypeName = get(dataBetTypes, `[${item?.BetTypeId}].Name`, "");
																		return (
																			<div
																				className="game-row"
																				key={`${srtDate}_${game}_${index}`}
																				onClick={() => {
																					let tempData1 = item?.Result1;
																					let tiketResult1 = {};
																					let tempData2 = item?.Result2;
																					let tiketResult2 = {};
																					if (tempData1) {
																						const arrLotteryNumber = split(
																							`${tempData1["Special"]}-${tempData1["T1"]}-${tempData1["T2"]}-${tempData1["T3"]}-${tempData1["T4"]}-${tempData1["T5"]}-${tempData1["T6"]}-${tempData1["T7"]}-${tempData1["T8"]}`,
																							"-"
																						);
																						const dauDuoi = {};
																						forEach(arrLotteryNumber, (item) => {
																							if (item) {
																								const num = item.slice(-2);
																								const num1 = num[0];
																								const num2 = num[1];
																								const newDataDau = get(dauDuoi, `${num1}`, []);
																								set(dauDuoi, `${num1}`, [...newDataDau, num2]);
																							}
																						});

																						tiketResult1 = {
																							data: tempData1,
																							dauDuoi,
																						};
																					}

																					if (tempData2) {
																						const arrLotteryNumber = split(
																							`${tempData2["Special"]}-${tempData2["T1"]}-${tempData2["T2"]}-${tempData2["T3"]}-${tempData2["T4"]}-${tempData2["T5"]}-${tempData2["T6"]}-${tempData2["T7"]}-${tempData2["T8"]}`,
																							"-"
																						);

																						const dauDuoi = {};
																						forEach(arrLotteryNumber, (item) => {
																							if (item) {
																								const num = item.slice(-2);
																								const num1 = num[0];
																								const num2 = num[1];
																								const newDataDau = get(dauDuoi, `${num1}`, []);
																								set(dauDuoi, `${num1}`, [...newDataDau, num2]);
																							}
																						});

																						tiketResult2 = {
																							data: tempData2,
																							dauDuoi,
																						};
																					}

																					setDataModalTicket(
																						defaultsDeep(
																							{
																								ChannelName,
																								BetTypeName,
																								Channel2Name,
																								tiketResult1,
																								tiketResult2,
																							},
																							item
																						)
																					);
																				}}
																			>
																				<div className="flex-1 flex items-center">
																					<div className="flex-1">
																						<span className="title text-black font-medium text-[12px]">
																							{ChannelName} - {BetTypeName}
																						</span>
																					</div>
																					<div className="flex-1 flex justify-between">
																						<span className="title">Thắng / Thua:</span>
																						<span className={`num ${toNumber(item?.WinLose) > 0 ? "" : "red"}`}>
																							{formatMoney(toNumber(toNumber(item?.WinLose).toFixed(2)))} K
																						</span>
																					</div>
																				</div>
																				<div className="flex-1 flex items-center">
																					<div className="flex-1">
																						<span className="date">Lượt sổ: {item?.SessionId ? item?.SessionId : item?.BetDate}</span>
																					</div>
																					<div className="flex-1 flex justify-between">
																						<span className="date">Thời gian đặt cược:</span>
																						<span className="date">{moment(item?.CreatedTime, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm")}</span>
																					</div>
																				</div>
																			</div>
																		);
																	}

																	return (
																		<div
																			className="game-row"
																			key={`${srtDate}_${game}_${index}`}
																			onClick={() => {
																				setModalGameHistory(item);
																			}}
																		>
																			<div className="flex-1 flex items-center">
																				<div className="flex-1">
																					<span className="title">{item?.GameName}</span>
																				</div>
																				<div className="flex-1 flex justify-between">
																					<span className="title">Thắng / Thua:</span>
																					<span className={`num ${toNumber(item?.WinLose) > 0 ? "" : "red"}`}>
																						{formatMoney(toNumber(toNumber(item?.WinLose).toFixed(2)))} K
																					</span>
																				</div>
																			</div>
																			<div className="flex-1 flex items-center">
																				<div className="flex-1">
																					<span className="date">Lượt sổ: {moment(item?.ResultTime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}</span>
																				</div>
																				<div className="flex-1 flex justify-between">
																					<span className="date">Thời gian đặt cược:</span>
																					<span className="date">{moment(item?.BetTime, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY HH:mm:ss")}</span>
																				</div>
																			</div>
																		</div>
																	);
																})}
															</div>
														)}
													</React.Fragment>
												);
											})}
										</div>
									)}
								</React.Fragment>
							);
						})}

					{isBetWaiting &&
						dataBetWaiting &&
						dataDateWaiting.map((item: any) => {
							const srtDate = moment(item.date, "DD/MM/YYYY").format("DD/MM/YYYY");
							const dataGroups = groupBy(dataBetWaiting[srtDate], "Provider");
							return (
								<React.Fragment key={srtDate}>
									<div className={`history-ion-item ${chooseBetHistory.date === srtDate ? "active" : ""}`}>
										<div
											className="history-item"
											onClick={() => {
												setChooseBetHistory(defaultsDeep({ date: srtDate }, INIT_CHOOSE_BET_HISTORY));
											}}
										>
											<div className="history-item-row">
												<div className="title">
													<span className="date">{srtDate}</span>
													<span className="num">({size(dataBetWaiting[srtDate]) > 0 ? size(dataBetWaiting[srtDate]) : "Hôm nay bạn chưa đặt cược"})</span>
												</div>
												<div>
													<IonImg src={`${IMG_MB}assets/icon/icon-down-history.png`} />
												</div>
											</div>
											<div className="history-item-row-bottom">
												<div className="left">
													<span className="title">Tiền cược:</span>
													<span className="num">{formatMoney(toNumber(sumBy(dataBetWaiting[srtDate], (bH: any) => toNumber(bH.BetAmount)).toFixed(2)))} K</span>
												</div>
												<div className="right flex-1 flex gap-2 items-end justify-between">
													<span className="title text-[#5A5A5A] text-[14px] font-semibold">Thắng / Thua:</span>
													<span className={`num ${toNumber(sumBy(dataBetWaiting[srtDate], (bH: any) => toNumber(bH.WinLose)).toFixed(2)) > 0 ? "" : "red"}`}>
														{formatMoney(
															toNumber(
																sumBy(dataBetWaiting[srtDate], (bH: any) =>
																	bH.Provider === "Xổ Số" ? toNumber(bH.WinloseForSum) : toNumber(bH.WinLose)
																).toFixed(2)
															)
														)}{" "}
														K
													</span>
												</div>
											</div>
										</div>
									</div>
									{chooseBetHistory.date === srtDate && (
										<div className="history-type-game">
											{Object.keys(dataGroups).map((game) => {
												return (
													<React.Fragment key={`${srtDate}_${game}`}>
														<div className="game-row">
															<div
																className={`row-type ${chooseBetHistory.game === game ? "active" : ""}`}
																onClick={() => {
																	const tempData = cloneDeep(chooseBetHistory);
																	const newData = orderBy(dataGroups[game], ["BetDate"], ["desc"]);
																	set(tempData, "game", game);
																	set(tempData, "data", newData);
																	setChooseBetHistory(tempData);
																}}
															>
																<div className="top">
																	<div className="flex gap-5">
																		<span className="title">{game}</span>
																		<span className="num">({size(dataGroups[game])})</span>
																	</div>
																	<div>
																		<span className="detail">Chi tiết =&gt;</span>
																	</div>
																</div>
																<div className="bottom flex-1 flex">
																	<div className="flex-1 flex gap-5 items-end">
																		<span className="title">Tiền cược:</span>
																		<span className="num">{formatMoney(toNumber(sumBy(dataGroups[game], (gB) => toNumber(gB.BetAmount)).toFixed(2)))} K</span>
																	</div>
																	<div className="flex-1 flex gap-5 items-end justify-between">
																		<span className="title">Thắng / Thua:</span>
																		<span className={`num ${toNumber(sumBy(dataGroups[game], (gB) => toNumber(gB.WinLose)).toFixed(2)) > 0 ? "" : "red"}`}>
																			{formatMoney(
																				toNumber(
																					sumBy(dataGroups[game], (gB) =>
																						gB.Provider === "Xổ Số" ? toNumber(gB.WinloseForSum) : toNumber(gB.WinLose)
																					).toFixed(2)
																				)
																			)}{" "}
																			K
																		</span>
																	</div>
																</div>
															</div>
														</div>
														{chooseBetHistory.game === game && chooseBetHistory.data && (
															<div className="history-game-detail">
																{chooseBetHistory.data.map((item: any, index: any) => {
																	if (item.Provider === "Xổ Số") {
																		const ChannelName = get(dataChannels, `[${item?.ChannelId}].Name`, "");
																		const Channel2Name = get(dataChannels, `[${item?.ChannelId2}].Name`, null);
																		const BetTypeName = get(dataBetTypes, `[${item?.BetTypeId}].Name`, "");
																		return (
																			<div
																				className="game-row"
																				key={`${srtDate}_${game}_${index}`}
																				onClick={() => {
																					let tempData1 = item?.Result1;
																					let tiketResult1 = {};
																					let tempData2 = item?.Result2;
																					let tiketResult2 = {};
																					if (tempData1) {
																						const arrLotteryNumber = split(
																							`${tempData1["Special"]}-${tempData1["T1"]}-${tempData1["T2"]}-${tempData1["T3"]}-${tempData1["T4"]}-${tempData1["T5"]}-${tempData1["T6"]}-${tempData1["T7"]}-${tempData1["T8"]}`,
																							"-"
																						);
																						const dauDuoi = {};
																						forEach(arrLotteryNumber, (item) => {
																							if (item) {
																								const num = item.slice(-2);
																								const num1 = num[0];
																								const num2 = num[1];
																								const newDataDau = get(dauDuoi, `${num1}`, []);
																								set(dauDuoi, `${num1}`, [...newDataDau, num2]);
																							}
																						});

																						tiketResult1 = {
																							data: tempData1,
																							dauDuoi,
																						};
																					}

																					if (tempData2) {
																						const arrLotteryNumber = split(
																							`${tempData2["Special"]}-${tempData2["T1"]}-${tempData2["T2"]}-${tempData2["T3"]}-${tempData2["T4"]}-${tempData2["T5"]}-${tempData2["T6"]}-${tempData2["T7"]}-${tempData2["T8"]}`,
																							"-"
																						);

																						const dauDuoi = {};
																						forEach(arrLotteryNumber, (item) => {
																							if (item) {
																								const num = item.slice(-2);
																								const num1 = num[0];
																								const num2 = num[1];
																								const newDataDau = get(dauDuoi, `${num1}`, []);
																								set(dauDuoi, `${num1}`, [...newDataDau, num2]);
																							}
																						});

																						tiketResult2 = {
																							data: tempData2,
																							dauDuoi,
																						};
																					}

																					setDataModalTicket(
																						defaultsDeep(
																							{
																								ChannelName,
																								BetTypeName,
																								Channel2Name,
																								tiketResult1,
																								tiketResult2,
																							},
																							item
																						)
																					);
																				}}
																			>
																				<div className="flex-1 flex items-center">
																					<div className="flex-1">
																						<span className="title text-black font-medium text-[12px]">
																							{ChannelName} - {BetTypeName}
																						</span>
																					</div>
																					<div className="flex-1 flex justify-between">
																						<span className="title">Thắng / Thua:</span>
																						<span className={`num ${toNumber(item?.WinLose) > 0 ? "" : "red"}`}>
																							{formatMoney(toNumber(toNumber(item?.WinLose).toFixed(2)))} K
																						</span>
																					</div>
																				</div>
																				<div className="flex-1 flex">
																					<div className="flex-1">
																						<span className="date">Lượt sổ: {item?.SessionId ? item?.SessionId : item?.BetDate}</span>
																					</div>
																					<div className="flex-1 flex justify-between">
																						<span className="date">Thời gian đặt cược:</span>
																						<span className="date">{moment(item?.CreatedTime, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
																					</div>
																				</div>
																			</div>
																		);
																	}

																	return (
																		<div
																			className="game-row"
																			key={`${srtDate}_${game}_${index}`}
																			onClick={() => {
																				setModalGameHistory(item);
																			}}
																		>
																			<div className="flex-1 flex items-center">
																				<div className="flex-1">
																					<span className="title">{item?.trans_id}</span>
																				</div>
																				<div className="flex-1 flex justify-between">
																					<span className="title">Thắng / Thua:</span>
																					<span className={`num ${toNumber(item?.WinLose) > 0 ? "" : "red"}`}>
																						{formatMoney(toNumber(toNumber(item?.WinLose).toFixed(2)))} K
																					</span>
																				</div>
																			</div>
																			<div className="flex-1 flex">
																				<div className="flex-1">
																					<span className="date">Lượt sổ: {item?.ResultTime}</span>
																				</div>
																				<div className="flex-1 flex justify-between">
																					<span className="date">Thời gian đặt cược:</span>
																					<span className="date">{moment(item?.BetTime, "YYYY-MM-DD").format("DD/MM/YYYY")}</span>
																				</div>
																			</div>
																		</div>
																	);
																})}
															</div>
														)}
													</React.Fragment>
												);
											})}
										</div>
									)}
								</React.Fragment>
							);
						})}
				</IonList>
			</IonContent>
			{dataModalTicket && (
				<IonModal
					ref={refDetailTicket}
					isOpen={true}
					onIonModalDidDismiss={() => setDataModalTicket(null)}
					className="app-bet-his-detail sc-ion-modal-md-h app-bet-his-list sc-ion-modal-md-s md modalHisBet hydrated show-modal"
				>
					<IonHeader mode="ios" role="banner" className="ion-header ios header-ios header-collapse-none hydrated">
						<IonToolbar className="fromBetHisList ion-toolbar in-toolbar ios toolbar-title-default hydrated">
							<IonButtons slot="start">
								<IonButton type="button" className="button-native btn-header-back sc-ion-back-button-md" onClick={() => refDetailTicket.current?.dismiss()}>
									<span className="button-inner sc-ion-back-button-lg">
										<IonIcon className="sc-ion-back-button-lg lg hydrated ion-coin-back" icon={chevronBackOutline} role="img"></IonIcon>
									</span>
									<IonRippleEffect className="sc-ion-back-button-lg lg unbounded hydrated" role="presentation"></IonRippleEffect>
								</IonButton>
							</IonButtons>
							<IonTitle className="ios title-ios title-default hydrated">Chi tiết vé cược</IonTitle>
						</IonToolbar>
					</IonHeader>
					<IonContent className="ion-content account-form ios overscroll hydrated">
						<IonGrid className="ion-grid ios hydrated">
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Mã số cược
								</IonCol>
								<IonCol className="ion-col ion-text-right code-bet ios hydrated" size="8">
									{get(dataModalTicket, "Id", "")}
									<IonButton
										mode="md"
										className="ion-button md button button-solid ion-activatable ion-focusable hydrated"
										onClick={() => writeToClipboard(get(dataModalTicket, "Id", ""))}
									>
										Copy
									</IonButton>
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Thành viên
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									{userInfo.UserName}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Xổ Số
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									{get(dataModalTicket, `Channel2Name`)
										? `${get(dataModalTicket, "ChannelName")} - ${get(dataModalTicket, "Channel2Name")}`
										: get(dataModalTicket, `ChannelName`)}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Loại cược
								</IonCol>
								<IonCol className="ion-col ion-text-right name-type-bet ios hydrated" size="8">
									{get(dataModalTicket, "BetTypeName", "")}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Tóm tắt cược
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									<span>
										[{get(dataModalTicket, "BetTypeName", "")}] - {get(dataModalTicket, "BetNumbers", "")}
									</span>
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Trạng thái
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									<span className={`state ${get(dataModalTicket, "BetStatus") === "Win" ? "state4" : "state3"}`}>
										{get(BET_STATUS, `${get(dataModalTicket, "BetStatus")}`, "")}
									</span>
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Lượt xổ
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									{get(dataModalTicket, "BetDate", "")}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Thời gian cược
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									{moment(get(dataModalTicket, "CreatedTime")).format("YYYY/MM/DD HH:mm:ss")}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Tổng số mua
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									{get(dataModalTicket, "NumberCount", "")}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Chi tiết chọn
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									<span className="ng-star-inserted">{get(dataModalTicket, "BetNumbers", "")}</span>
									<IonButton
										mode="md"
										className="ion-button md button button-solid ion-activatable ion-focusable hydrated"
										onClick={() =>
											presentAlert({
												header: "Chi tiết cược",
												message: get(dataModalTicket, "BetNumbers", ""),
												buttons: ["OK"],
												cssClass: "alert-custom",
											})
										}
									>
										chi tiết
									</IonButton>
								</IonCol>
							</IonRow>
							{get(dataModalTicket, "HotBetNumbers", "") && (
								<IonRow className="ion-row ios hydrated">
									<IonCol size="4" className="ion-col ios hydrated">
										Số nóng
									</IonCol>
									<IonCol className="ion-col ion-text-right ios hydrated" size="8">
										<span className="ng-star-inserted state state3">{get(dataModalTicket, "HotBetNumbers", "")}</span>
										<IonButton
											mode="md"
											className="ion-button md button button-solid ion-activatable ion-focusable hydrated"
											onClick={() =>
												presentAlert({
													header: "Chi tiết số nóng",
													message: `${get(dataModalTicket, "HotBetNumbers", "")} - Giảm[${get(dataModalTicket, "HotRates", "").split("|").join("%|")}%]`,
													buttons: ["OK"],
													cssClass: "alert-custom",
												})
											}
										>
											chi tiết
										</IonButton>
									</IonCol>
								</IonRow>
							)}
							{get(dataModalTicket, "PercentDown", 0) > 0 && (
								<IonRow className="ion-row ios hydrated">
									<IonCol size="4" className="ion-col ios hydrated">
										Giảm thưởng
									</IonCol>
									<IonCol className="ion-col ion-text-right ios hydrated" size="8">
										<span className="ng-star-inserted state state3">{get(dataModalTicket, "PercentDown", "")}</span>
									</IonCol>
								</IonRow>
							)}
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Số trúng
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									<span className="ng-star-inserted">{get(dataModalTicket, "WonNumbers", "")}</span>
									{get(dataModalTicket, "WonNumbers", "") && (
										<IonButton
											mode="md"
											className="ion-button md button button-solid ion-activatable ion-focusable hydrated"
											onClick={() =>
												presentAlert({
													header: "Chi tiết số trúng",
													message: get(dataModalTicket, "WonNumbers", ""),
													buttons: ["OK"],
													cssClass: "alert-custom",
												})
											}
										>
											chi tiết
										</IonButton>
									)}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Số điểm
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									{get(dataModalTicket, "BetTimes")} lần
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Tổng tiền cược
								</IonCol>
								<IonCol className="ion-col ion-text-right color-money ios hydrated" size="8">
									{formatMoney(get(dataModalTicket, "BetAmount"))}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Kết quả
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									{get(dataModalTicket, "BetStatus") === "Waiting" ? (
										"- - -"
									) : get(dataModalTicket, "ChannelId2") ? (
										<>
											<IonButton
												mode="md"
												className="ion-button max-width-170 md button button-solid ion-activatable ion-focusable hydrated"
												onClick={() => {
													const result: any = get(dataModalTicket, "Result1");

													if (result) {
														const arrLotteryNumber = split(
															`${result["Special"]}-${result["T1"]}-${result["T2"]}-${result["T3"]}-${result["T4"]}-${result["T5"]}-${result["T6"]}-${result["T7"]}-${result["T8"]}`,
															"-"
														);
														const dauDuoi = {};
														forEach(arrLotteryNumber, (item) => {
															if (item) {
																const num = item.slice(-2);
																const num1 = num[0];
																const num2 = num[1];
																const newDataDau = get(dauDuoi, `${num1}`, []);
																set(dauDuoi, `${num1}`, [...newDataDau, num2]);
															}
														});
														setDataModalResult({
															item: result,
															dauDuoi,
														});
													}
												}}
											>
												{get(dataModalTicket, "ChannelName")}
											</IonButton>
											<IonButton
												mode="md"
												className="ion-button max-width-170 md button button-solid ion-activatable ion-focusable hydrated"
												onClick={() => {
													const result: any = get(dataModalTicket, "Result2");

													if (result) {
														const arrLotteryNumber = split(
															`${result["Special"]}-${result["T1"]}-${result["T2"]}-${result["T3"]}-${result["T4"]}-${result["T5"]}-${result["T6"]}-${result["T7"]}-${result["T8"]}`,
															"-"
														);
														const dauDuoi = {};
														forEach(arrLotteryNumber, (item) => {
															if (item) {
																const num = item.slice(-2);
																const num1 = num[0];
																const num2 = num[1];
																const newDataDau = get(dauDuoi, `${num1}`, []);
																set(dauDuoi, `${num1}`, [...newDataDau, num2]);
															}
														});
														setDataModalResult({
															item: result,
															dauDuoi,
														});
													}
												}}
											>
												{get(dataModalTicket, "Channel2Name")}
											</IonButton>
										</>
									) : (
										<IonButton
											mode="md"
											className="ion-button md button button-solid ion-activatable ion-focusable hydrated"
											onClick={() => {
												const result: any = get(dataModalTicket, "Result1");
												if (result) {
													const arrLotteryNumber = split(
														`${result["Special"]}-${result["T1"]}-${result["T2"]}-${result["T3"]}-${result["T4"]}-${result["T5"]}-${result["T6"]}-${result["T7"]}-${result["T8"]}`,
														"-"
													);
													const dauDuoi = {};
													forEach(arrLotteryNumber, (item) => {
														if (item) {
															const num = item.slice(-2);
															const num1 = num[0];
															const num2 = num[1];
															const newDataDau = get(dauDuoi, `${num1}`, []);
															set(dauDuoi, `${num1}`, [...newDataDau, num2]);
														}
													});
													setDataModalResult({
														item: result,
														dauDuoi,
													});
												}
											}}
										>
											Chi tiết
										</IonButton>
									)}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Lần thắng
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									{get(dataModalTicket, "WinLose") > 0
										? get(dataModalTicket, "WinCount") > 0
											? get(dataModalTicket, "WinCount")
											: `${get(dataModalTicket, "WonNumbers")}`.split(" ").length
										: ``}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Tiền thưởng
								</IonCol>
								<IonCol className="ion-col ion-text-right color-money ios hydrated" size="8">
									{get(dataModalTicket, "WinLose", 0) > 0 ? formatMoney(get(dataModalTicket, "WinLose", 0)) : 0}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Tỉ lệ chiết khấu
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									{includes([37, 38, 39, 40, 41, 42], get(dataModalTicket, "ChannelId"))
										? `0`
										: includes([1, 44], get(dataModalTicket, "ChannelId"))
										? toNumber(get(dataModalTicket, "PercentMB"))
										: toNumber(get(dataModalTicket, "Percent"))}
									%
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Nuôi
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									{get(dataModalTicket, "stopWon") ? "Có" : "Không"}
								</IonCol>
							</IonRow>
							<IonRow className="ion-row ios hydrated">
								<IonCol size="4" className="ion-col ios hydrated">
									Mã nuôi
								</IonCol>
								<IonCol className="ion-col ion-text-right ios hydrated" size="8">
									{get(dataModalTicket, "stopWon")}
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonContent>
				</IonModal>
			)}
			{dataModalResult && (
				<IonModal
					ref={modalResult}
					isOpen={true}
					onIonModalDidDismiss={() => setDataModalResult(null)}
					className="app-show-lottery-result sc-ion-modal-md-h sc-ion-modal-md-s md modalHisResult hydrated"
				>
					<IonContent className="ion-content ion-padding account-form ios overscroll hydrated">
						<div className="title__result">
							Kết quả lượt xổ
							<span className="issue__name">{get(dataModalResult, "item.ResultDate")}</span>
						</div>
						<div className="wrap-tbl-result-lottery asean-link">
							<div className="wrap-tbl-result">
								<table className="tblResultLottery">
									<tbody>
										{get(dataModalResult, "item.Special", "") && (
											<tr>
												<td>ĐB</td>
												<td>
													<div className="num-prizedb">{get(dataModalResult, "item.Special", "")}</div>
												</td>
											</tr>
										)}
										{get(dataModalResult, "item.T1", "") && (
											<tr>
												<td>Nhất</td>
												<td>
													<div>{get(dataModalResult, "item.T1", "")}</div>
												</td>
											</tr>
										)}
										{get(dataModalResult, "item.T2", "") && (
											<tr>
												<td>Nhì</td>
												<td>
													<div>{get(dataModalResult, "item.T2", "")}</div>
												</td>
											</tr>
										)}
										{get(dataModalResult, "item.T3", "") && (
											<tr>
												<td>Ba</td>
												<td>
													<div>{get(dataModalResult, "item.T3", "")}</div>
												</td>
											</tr>
										)}
										{get(dataModalResult, "item.T4", "") && (
											<tr>
												<td>Tư</td>
												<td>
													<div>{get(dataModalResult, "item.T4", "")}</div>
												</td>
											</tr>
										)}

										{get(dataModalResult, "item.T5", "") && (
											<tr>
												<td>Năm</td>
												<td>
													<div>{get(dataModalResult, "item.T5", "")}</div>
												</td>
											</tr>
										)}
										{get(dataModalResult, "item.T6", "") && (
											<tr>
												<td>Sáu</td>
												<td>
													<div>{get(dataModalResult, "item.T6", "")}</div>
												</td>
											</tr>
										)}
										{get(dataModalResult, "item.T7", "") && (
											<tr>
												<td>Bảy</td>
												<td>
													<div>{get(dataModalResult, "item.T7", "")}</div>
												</td>
											</tr>
										)}
										{get(dataModalResult, "item.T8", "") && (
											<tr>
												<td>Tám</td>
												<td>
													<div>{get(dataModalResult, "item.T8", "")}</div>
												</td>
											</tr>
										)}
									</tbody>
								</table>
							</div>
							<div className="wrap-tbl-dauduoi">
								<table className="tblDauDuoi">
									<tbody>
										<tr className="title">
											<td>Đầu</td>
											<td>Đuôi</td>
										</tr>
										<tr>
											<td>0</td>
											<td>{join(get(dataModalResult, "dauDuoi.0", []), "| ")}</td>
										</tr>
										<tr>
											<td>1</td>
											<td>{join(get(dataModalResult, "dauDuoi.1", []), "| ")}</td>
										</tr>
										<tr>
											<td>2</td>
											<td>{join(get(dataModalResult, "dauDuoi.2", []), "| ")}</td>
										</tr>
										<tr>
											<td>3</td>
											<td>{join(get(dataModalResult, "dauDuoi.3", []), "| ")}</td>
										</tr>
										<tr>
											<td>4</td>
											<td>{join(get(dataModalResult, "dauDuoi.4", []), "| ")}</td>
										</tr>
										<tr>
											<td>5</td>
											<td>{join(get(dataModalResult, "dauDuoi.5", []), "| ")}</td>
										</tr>
										<tr>
											<td>6</td>
											<td>{join(get(dataModalResult, "dauDuoi.6", []), "| ")}</td>
										</tr>
										<tr>
											<td>7</td>
											<td>{join(get(dataModalResult, "dauDuoi.7", []), "| ")}</td>
										</tr>
										<tr>
											<td>8</td>
											<td>{join(get(dataModalResult, "dauDuoi.8", []), "| ")}</td>
										</tr>
										<tr>
											<td>9</td>
											<td>{join(get(dataModalResult, "dauDuoi.9", []), "| ")}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<IonButton
							className="close-result ios button button-block button-solid ion-activatable ion-focusable hydrated"
							expand="block"
							onClick={() => modalResult.current?.dismiss()}
						>
							OK
						</IonButton>
					</IonContent>
				</IonModal>
			)}
			{modalGameHistory && (
				<IonModal id="vibet88-modal" isOpen={true} onIonModalDidDismiss={() => setModalGameHistory(null)}>
					<IonContent>
						<IonToolbar>
							<IonTitle>LỊCH SỬ GAME CHUYỂN QUỸ</IonTitle>
							<IonButtons slot="end">
								<IonButton color="light" onClick={() => setModalGameHistory(null)}>
									<IonImg className="icon-close" src={`${IMG_MB}assets/icon/close-modal.png`} />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<div className="content-game-history">
							<p
								dangerouslySetInnerHTML={{
									__html: modalGameHistory?.BetDetails,
								}}
							></p>
						</div>
					</IonContent>
				</IonModal>
			)}
		</IonPage>
	);
};

export default BetHistory;
