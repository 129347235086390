import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonRippleEffect,
  IonButton,
  IonLabel,
  IonInput,
  IonDatetime,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonDatetimeButton,
  IonModal,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import "./ReportWinLoseSearch.css";

const ReportWinLoseSearch: React.FC = () => {
  return (
    <IonPage className="app-reg">
      <IonHeader>
        <IonToolbar className="ion-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/agency">
              <button type="button" className="button-native sc-ion-back-button-md">
                <span className="button-inner sc-ion-back-button-md">
                  <IonIcon className="sc-ion-back-button-md md hydrated" icon={arrowBack} role="img" aria-label="arrow back"></IonIcon>
                </span>
                <IonRippleEffect className="sc-ion-back-button-md md unbounded hydrated" role="presentation"></IonRippleEffect>
              </button>
            </IonBackButton>
          </IonButtons>
          <IonTitle>Báo cáo đại lý</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="agency-form ion-padding ios overscroll hydrated content-ltr" fullscreen>
        <div _ngcontent-oei-c20="" className="frm-item">
          <IonLabel _ngcontent-oei-c20="" className="ion-label sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
            Thời gian từ
          </IonLabel>
          <IonDatetimeButton datetime="datetimeForm"></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              id="datetimeForm"
              cancelText="Hủy"
              doneText="Chọn"
              min="2017"
              mode="ios"
              className="ng-untouched ng-pristine ng-valid ios hydrated ion-untouched ion-pristine ion-valid"
              role="combobox"
              aria-expanded="false"
              aria-haspopup="true"
              aria-labelledby="ion-dt-39-lbl"
            ></IonDatetime>
          </IonModal>
        </div>
        <div _ngcontent-nto-c18="" className="frm-item">
          <IonLabel _ngcontent-nto-c18="" className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
            Đến
          </IonLabel>
          <IonDatetimeButton datetime="datetimeTo"></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              id="datetimeTo"
              cancelText="Hủy"
              doneText="Chọn"
              min="2017"
              mode="ios"
              className="ng-untouched ng-pristine ng-valid ios hydrated ion-untouched ion-pristine ion-valid"
              role="combobox"
              aria-expanded="false"
              aria-haspopup="true"
              aria-labelledby="ion-dt-39-lbl"
            ></IonDatetime>
          </IonModal>
        </div>
        <div _ngcontent-nto-c18="" className="frm-item">
          <IonLabel _ngcontent-nto-c18="" className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
            Loại
          </IonLabel>
          <IonItem
            _ngcontent-nto-c18=""
            className="item-label item ios ion-activatable ion-focusable ion-untouched ion-pristine ion-valid hydrated item-interactive item-select item-has-value"
          >
            <IonLabel _ngcontent-nto-c18="" className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated" id="ion-sel-0-lbl">
              Loại
            </IonLabel>
            <IonSelect
              _ngcontent-nto-c18=""
              cancelText="Huỷ"
              mode="ios"
              okText="Chọn"
              className="ng-untouched ng-pristine ng-valid ion-untouched ion-pristine ion-valid ios in-item hydrated"
              role="combobox"
              aria-haspopup="dialog"
              aria-expanded="false"
              aria-labelledby="ion-sel-0-lbl"
            >
              <IonSelectOption _ngcontent-nto-c18="" role="option" id="ion-selopt-0" className="ios hydrated">
                Đặt cược từ lớn tới nhỏ
              </IonSelectOption>
              <IonSelectOption _ngcontent-nto-c18="" role="option" id="ion-selopt-1" className="ios hydrated">
                Hoàn tiền từ lớn tới nhỏ
              </IonSelectOption>
              <IonSelectOption _ngcontent-nto-c18="" role="option" id="ion-selopt-2" className="ios hydrated">
                Trúng cược từ lớn tới nhỏ
              </IonSelectOption>
              <IonSelectOption _ngcontent-nto-c18="" role="option" id="ion-selopt-3" className="ios hydrated">
                Khuyến mãi từ lớn tới nhỏ
              </IonSelectOption>
              <IonSelectOption _ngcontent-nto-c18="" role="option" id="ion-selopt-4" className="ios hydrated">
                Nạp tiền từ lớn tới nhỏ
              </IonSelectOption>
              <IonSelectOption _ngcontent-nto-c18="" role="option" id="ion-selopt-5" className="ios hydrated">
                Rút tiền từ lớn tới nhỏ
              </IonSelectOption>
              <input type="hidden" className="aux-input" name="ion-sel-0" value="0" />
            </IonSelect>
          </IonItem>
        </div>
        <div _ngcontent-nto-c18="" className="frm-item">
          <IonLabel _ngcontent-nto-c18="" className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
            Tên tài khoản
          </IonLabel>
          <IonInput
            _ngcontent-nto-c18=""
            clearInput={true}
            type="text"
            className="ng-untouched ng-pristine ng-valid ion-untouched ion-pristine ion-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated"
          ></IonInput>
        </div>
        <IonButton _ngcontent-nto-c18="" className="af-submit-btn ios button button-block button-solid ion-activatable ion-focusable hydrated" expand="block">
          Tìm kiếm
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default ReportWinLoseSearch;
