import React from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonIcon, IonImg, IonItem, IonLabel, IonList, IonButton } from "@ionic/react";
import { chevronForward, arrowBack } from "ionicons/icons";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./Agency.css";
import { IMG_MB } from "../constants";

const Information: React.FC = () => {
	const history = useHistory();
	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/account")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Tài Khoản Cá Nhân
					</IonButton>
				</div>
			</div>
			<IonContent className="app-agency">
				<IonList className="agent-ion-list" no-line>
					<IonItem className="ion-item" onClick={() => history.push("/user-info")}>
						<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/account/lichsucuocxoso.png`} />
						<IonLabel className="">Thông tin cá nhân</IonLabel>
						<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
					<IonItem className="ion-item" onClick={() => history.push("/bank-account")}>
						<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/account/lichsucuocxoso.png`} />
						<IonLabel className="">Tài khoản ngân hàng</IonLabel>
						<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
					<IonItem className="ion-item" onClick={() => history.push("/message-system")}>
						<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/account/lichsucuocxoso.png`} />
						<IonLabel className="">Tin nhắn hệ thống</IonLabel>
						<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
					<IonItem className="ion-item" onClick={() => history.push("/notification")}>
						<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/account/lichsucuocxoso.png`} />
						<IonLabel className="">Thông báo</IonLabel>
						<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
					<IonItem className="ion-item" onClick={() => history.push("/quick-view")}>
						<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/account/lichsucuocxoso.png`} />
						<IonLabel className="">Câu hỏi thường gặp</IonLabel>
						<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default Information;
