import React, { useState } from "react";
import {
	IonContent,
	IonPage,
	IonIcon,
	IonButton,
	IonLabel,
	IonItem,
	IonSelect,
	IonSelectOption,
	IonInput,
	useIonToast,
	useIonAlert,
	useIonViewDidEnter,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { setUserBank, getUserBanks } from "../redux/auth/authCrud";
import { useHistory } from "react-router-dom";
import { defaultsDeep, get } from "lodash";
import { useSelector, shallowEqual } from "react-redux";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { eye } from "ionicons/icons";

import "./AccountAddBank.css";

const INIT_BANK = {
	BankName: "",
	AccountName: "",
	AccountNumber: "",
	ConfirmAccountNumber: "",
	isActive: true,
	UserName: "",
	Note: "",
	NickName: "",
	WithdrawPassword: "",
	ConfirmWithdrawPassword: "",
};

const AccountAddBank: React.FC = () => {
	const [dataBank, setDataBank] = useState<any>(INIT_BANK);
	const [present] = useIonToast();
	const [presentAlert] = useIonAlert();
	const history = useHistory();
	const [userBanks, setUserBanks] = useState([]);
	const [isShowPass, setIsShowPass] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const { userInfo } = useSelector(
		(state: any) => ({
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const GetUserBanks = async () => {
		const { data } = await getUserBanks();

		if (!isLoading) {
			setIsLoading(true);
		}

		if (data) {
			setUserBanks(data);
		}
	};

	const SetUserBank = async () => {
		if (userBanks.length === 0 && !dataBank.NickName) return presentToast("error", "Họ và tên không được để trống");
		if (userBanks.length === 0 && !dataBank.WithdrawPassword) return presentToast("error", "Mã rút tiền không được để trống");
		if (userBanks.length === 0 && !dataBank.ConfirmWithdrawPassword) return presentToast("error", "Nhập lại mã rút tiền không được để trống");
		if (userBanks.length === 0 && dataBank.WithdrawPassword !== dataBank.ConfirmWithdrawPassword)
			return presentToast("error", "Nhập lại mã rút tiền không chính xác");

		if (!dataBank.BankName) return presentToast("error", "Tên ngân hàng không được để trống");
		if (!dataBank.AccountNumber) return presentToast("error", "Số tài khoản không được để trống");
		if (!dataBank.ConfirmAccountNumber) return presentToast("error", "Xác nhận số tài khoản không được để trống");
		if (dataBank.AccountNumber !== dataBank.ConfirmAccountNumber) return presentToast("error", "Nhập lại số tài khoản không chính xác");

		const { data } = await setUserBank(
			defaultsDeep({ UserId: userInfo?.Id, AccountName: userBanks.length === 0 ? dataBank.NickName : get(userBanks, "[0].AccountName", "") }, dataBank)
		);
		if (data?.error && data?.error?.message)
			return presentAlert({
				header: "Thông Báo",
				message: data?.error?.message,
				buttons: ["OK"],
				cssClass: "alert-custom",
			});

		if (data) {
			presentAlert({
				header: "Thông Báo",
				message: "Thêm tài khoản ngân hàng thành công!",
				buttons: ["OK"],
				cssClass: "alert-custom",
			});
			history.push("/user-banks");
		}
	};

	useIonViewDidEnter(() => {
		GetUserBanks();
	});

	return (
		<IonPage className="app-account-add-bank">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/bank-account")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Thêm Tài Khoản Ngân Hàng
					</IonButton>
				</div>
			</div>
			<IonContent className="ion-content add-bank-form ios overscroll hydrated" aria-keyshortcuts="">
				<form className="ab-wrapper ng-untouched ng-pristine ng-invalid mb-300">
					{isLoading && userBanks.length === 0 && (
						<>
							<div className="ab-item">
								<IonLabel className="ion-label sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Họ và tên</IonLabel>
								<IonInput
									className="ion-input ion-text-uppercase ng-untouched ng-pristine ng-invalid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-invalid"
									type="text"
									value={dataBank?.NickName}
									onIonChange={(e) => setDataBank(defaultsDeep({ NickName: e.detail.value }, dataBank))}
								></IonInput>
								<div className="aci-note">(Yêu cầu Họ và Tên thật trùng với thông tin ngân hàng ...)</div>
							</div>
							<div className="ab-item">
								<IonLabel className="ion-label sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Mã rút tiền</IonLabel>
								<div className="flex">
									<IonInput
										className="ion-input ion-text-uppercase ng-untouched ng-pristine ng-invalid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-invalid"
										type={isShowPass ? "text" : "password"}
										value={dataBank?.WithdrawPassword}
										onIonChange={(e) => setDataBank(defaultsDeep({ WithdrawPassword: e.detail.value }, dataBank))}
									></IonInput>
									<IonIcon className="icon--eye" icon={eye} onClick={() => setIsShowPass(!isShowPass)} />
								</div>
								<div className="aci-note">(Mã rút tiền bao gồm 6 chữ số sử dụng để rút tiền ...)</div>
							</div>
							<div className="ab-item">
								<IonLabel className="ion-label sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Nhập lại mã rút tiền</IonLabel>
								<div className="flex">
									<IonInput
										className="ion-input flex-1 ion-text-uppercase ng-untouched ng-pristine ng-invalid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-invalid"
										type={isShowPass ? "text" : "password"}
										value={dataBank?.ConfirmWithdrawPassword}
										onIonChange={(e) => setDataBank(defaultsDeep({ ConfirmWithdrawPassword: e.detail.value }, dataBank))}
									></IonInput>
									<IonIcon className="icon--eye" icon={eye} onClick={() => setIsShowPass(!isShowPass)} />
								</div>
							</div>
						</>
					)}
					<div className="ab-item">
						<IonLabel className="ion-label sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Tên ngân hàng</IonLabel>
						<IonItem
							lines="none"
							className="ion-item item-interactive item-select item-has-value item ios item-lines-none ion-activatable ion-focusable hydrated ion-untouched ion-pristine ion-valid"
						>
							<IonSelect
								cancelText="Huỷ"
								value={dataBank?.BankName}
								mode="ios"
								okText="Chọn"
								className="ion-select ng-untouched ng-pristine ng-valid ios in-item hydrated ion-untouched ion-pristine ion-valid"
								role="combobox"
								aria-haspopup="dialog"
								aria-expanded="false"
								aria-labelledby="ion-sel-19-lbl"
								onIonChange={(e) => setDataBank(defaultsDeep({ BankName: e.detail.value }, dataBank))}
							>
								<IonSelectOption value="Ngân Hàng Á Châu (ACB)" role="option" id="ion-selopt-441" className="ios hydrated">
									Ngân Hàng Á Châu (ACB)
								</IonSelectOption>
								<IonSelectOption value="Agribank" role="option" id="ion-selopt-442" className="ios hydrated">
									Agribank
								</IonSelectOption>
								<IonSelectOption value="BIDV" role="option" id="ion-selopt-443" className="ios hydrated">
									BIDV
								</IonSelectOption>
								<IonSelectOption value="Đông Á" role="option" id="ion-selopt-444" className="ios hydrated">
									Đông Á
								</IonSelectOption>
								<IonSelectOption value="EximBank" role="option" id="ion-selopt-445" className="ios hydrated">
									EximBank
								</IonSelectOption>
								<IonSelectOption value="NCB – Ngân Hàng Quốc Dân" role="option" id="ion-selopt-445" className="ios hydrated">
									NCB – Ngân Hàng Quốc Dân
								</IonSelectOption>
								<IonSelectOption value="Sacombank" role="option" id="ion-selopt-446" className="ios hydrated">
									Sacombank
								</IonSelectOption>
								<IonSelectOption value="Techcombank" role="option" id="ion-selopt-447" className="ios hydrated">
									Techcombank
								</IonSelectOption>
								<IonSelectOption value="Vietinbank" role="option" id="ion-selopt-448" className="ios hydrated">
									Vietinbank
								</IonSelectOption>
								<IonSelectOption value="Vietcombank" role="option" id="ion-selopt-449" className="ios hydrated">
									Vietcombank
								</IonSelectOption>
								<IonSelectOption value="VIB" role="option" id="ion-selopt-450" className="ios hydrated">
									VIB
								</IonSelectOption>
								<IonSelectOption value="VIETABANK" role="option" id="ion-selopt-450" className="ios hydrated">
									VIETABANK
								</IonSelectOption>
								<IonSelectOption value="KIENLONG BANK" role="option" id="ion-selopt-450" className="ios hydrated">
									KIENLONG BANK
								</IonSelectOption>
								<IonSelectOption value="ABBANK" role="option" id="ion-selopt-450" className="ios hydrated">
									ABBANK
								</IonSelectOption>
								<IonSelectOption value="HDBANK" role="option" id="ion-selopt-450" className="ios hydrated">
									HDBANK
								</IonSelectOption>
								<IonSelectOption value="BVBANK" role="option" id="ion-selopt-450" className="ios hydrated">
									BVBANK
								</IonSelectOption>
								<IonSelectOption value="Cake By VPBank" role="option" id="ion-selopt-450" className="ios hydrated">
									Cake By VPBank
								</IonSelectOption>
								<IonSelectOption value="SCB" role="option" id="ion-selopt-450" className="ios hydrated">
									SCB
								</IonSelectOption>
								<IonSelectOption value="NamABank" role="option" id="ion-selopt-450" className="ios hydrated">
									NamABank
								</IonSelectOption>
								<IonSelectOption value="VIETBANK" role="option" id="ion-selopt-450" className="ios hydrated">
									VIETBANK
								</IonSelectOption>
								<IonSelectOption value="OCB" role="option" id="ion-selopt-450" className="ios hydrated">
									OCB - Ngân Hàng Phương Đông
								</IonSelectOption>
								<IonSelectOption value="VPBank" role="option" id="ion-selopt-451" className="ios hydrated">
									VPBank
								</IonSelectOption>
								<IonSelectOption value="MBBank" role="option" id="ion-selopt-451" className="ios hydrated">
									MBBank
								</IonSelectOption>
								<IonSelectOption value="MaritimeBank" role="option" id="ion-selopt-452" className="ios hydrated">
									MaritimeBank
								</IonSelectOption>
								<IonSelectOption value="SHB" role="option" id="ion-selopt-453" className="ios hydrated">
									SHB
								</IonSelectOption>
								<IonSelectOption value="MBBank" role="option" id="ion-selopt-454" className="ios hydrated">
									MBBank
								</IonSelectOption>
								<IonSelectOption value="ShinHan Bank" role="option" id="ion-selopt-455" className="ios hydrated">
									ShinHan Bank
								</IonSelectOption>
								<IonSelectOption value="LienViet Post Bank" role="option" id="ion-selopt-456" className="ios hydrated">
									LienViet Post Bank
								</IonSelectOption>
								<IonSelectOption value="Ocean Bank" role="option" id="ion-selopt-457" className="ios hydrated">
									Ocean Bank
								</IonSelectOption>
								<IonSelectOption value="TP Bank" role="option" id="ion-selopt-458" className="ios hydrated">
									TPBank
								</IonSelectOption>
								<IonSelectOption value="SeaBank" role="option" id="ion-selopt-459" className="ios hydrated">
									SeaBank
								</IonSelectOption>
								<IonSelectOption value="BacA Bank" role="option" id="ion-selopt-460" className="ios hydrated">
									BacA Bank
								</IonSelectOption>
							</IonSelect>
						</IonItem>
					</div>
					<div className="ab-item">
						<IonLabel className="ion-label sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Số tài khoản</IonLabel>
						<IonInput
							placeholder="Từ 1 đến 15 kí tự"
							type="text"
							className="ion-input ng-untouched ng-pristine ng-invalid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-invalid"
							value={dataBank?.AccountNumber}
							onIonChange={(e: any) => {
								e.detail.value = e.detail.value.replace(/[^0-9]/g, "");
								setDataBank(defaultsDeep({ AccountNumber: e.detail.value }, dataBank));
							}}
						></IonInput>
						<div className="aci-note">(Nhập đúng số tài khoản, không phải là số thẻ ATM)</div>
					</div>
					<div className="ab-item">
						<IonLabel className="ion-label sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Nhập lại số tài khoản</IonLabel>
						<IonInput
							placeholder="Xác nhận tài khoản ngân hàng"
							type="text"
							className="ion-input ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
							value={dataBank?.ConfirmAccountNumber}
							onIonChange={(e: any) => {
								e.detail.value = e.detail.value.replace(/[^0-9]/g, "");
								setDataBank(
									defaultsDeep(
										{
											ConfirmAccountNumber: e.detail.value,
										},
										dataBank
									)
								);
							}}
						></IonInput>
						<div className="aci-note"></div>
					</div>
					<IonButton expand="block" className="ios button button-block button-solid ion-activatable ion-focusable hydrated" onClick={() => SetUserBank()}>
						Thêm
					</IonButton>
				</form>
			</IonContent>
		</IonPage>
	);
};

export default AccountAddBank;
