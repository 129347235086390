import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "./auth/authRedux";
import * as lottery from "./lottery/lotteryRedux";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  lottery: lottery.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
