import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonRippleEffect,
  IonButton,
  IonRow,
  IonCol,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import "./AgencyRegisterLink.css";

const AgencyRegisterLink: React.FC = () => {
  return (
    <IonPage className="app-agency-reg-link">
      <IonHeader>
        <IonToolbar className="ion-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/agency">
              <button type="button" className="button-native sc-ion-back-button-md">
                <span className="button-inner sc-ion-back-button-md">
                  <IonIcon className="sc-ion-back-button-md md hydrated" icon={arrowBack} role="img" aria-label="arrow back"></IonIcon>
                </span>
                <IonRippleEffect className="sc-ion-back-button-md md unbounded hydrated" role="presentation"></IonRippleEffect>
              </button>
            </IonBackButton>
          </IonButtons>
          <IonTitle>Liên kết đăng ký</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="agency-form ios overscroll hydrated" fullscreen>
        <ul className="af-note">
          <li>
            Mỗi hội viên có <span>[1]</span> liên kết đăng ký.
          </li>
          <li>
            Sau khi tạo liên kết đăng ký, nhấp vào <span>[Sao chép]</span> để sao chép liên kết đăng ký
          </li>
        </ul>
        <div className="af-link ng-star-inserted">
          <IonRow className="ion-row ion-align-items-center ng-star-inserted ios hydrated">
            <IonCol size="9" className="ion-col ios hydrated">
              https://loto988.com/reg.shtml?id=8832130
            </IonCol>
            <IonCol size="3" className="ion-col ios hydrated">
              <IonButton
                expand="block"
                size="small"
                className="ion-button ios button button-block button-small button-solid ion-activatable ion-focusable hydrated"
              >
                Sao chép
              </IonButton>
            </IonCol>
          </IonRow>
          <em className="ng-star-inserted">
            Nếu thiết bị của bạn không [Sao chép] được, xin hãy <span>nhấn vào đây</span> để [Sao chép] thủ công.
          </em>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AgencyRegisterLink;
