import React, { useState, useEffect } from "react";
import {
	IonContent,
	IonPage,
	IonButtons,
	IonIcon,
	IonList,
	IonItem,
	IonModal,
	IonButton,
	IonPopover,
	IonDatetime,
	IonInput,
	useIonViewDidEnter,
} from "@ionic/react";
import { getBalanceLog } from "../redux/auth/authCrud";
import { includes, orderBy, filter, defaultsDeep, sum, toNumber } from "lodash";
import { useHistory } from "react-router-dom";
import { formatMoney, formatUUID } from "../util";
import moment from "moment";
import { caretDown, arrowBack } from "ionicons/icons";

import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { TITLE_BALANCE_LOG } from "../constants";

import "./AgencyBalance.css";

const INIT_SEARCH_LOG = {
	TypeLog: "",
	Id: "",
	DateStart: moment().startOf("months").format("DD-MM-YYYY"),
	DateEnd: moment().endOf("months").format("DD-MM-YYYY"),
};

const AgencyBalance: React.FC = () => {
	const history = useHistory();
	const [dataBalanceLog, setDataBalanceLog] = useState<any>([]);
	const [isDateStart, setIsDateStart] = useState<any>(false);
	const [isDateEnd, setIsDateEnd] = useState<any>(false);
	const [dataSearchLog, setDataSearchLog] = useState(INIT_SEARCH_LOG);
	const [dataTicketsSearchLog, setDataTicketsSearchLog] = useState<any>([]);
	const [rowSelect, setRowSelect] = useState(null);

	const GetBalanceLog = async () => {
		const res = await getBalanceLog();
		if (res && res.data) {
			setDataBalanceLog(orderBy(res.data, ["CreatedTime"], ["desc"]));
		}
	};

	const _searchLog = () => {
		let tempSearch = filter(dataBalanceLog, (item) => {
			if (
				!moment(moment(item?.CreatedTime, "YYYY-MM-DD").format("YYYY-MM-DD")).isBetween(
					moment(dataSearchLog?.DateStart, "DD-MM-YYYY").format("YYYY-MM-DD"),
					moment(dataSearchLog?.DateEnd, "DD-MM-YYYY").format("YYYY-MM-DD"),
					undefined,
					"[]"
				)
			)
				return false;
			return dataSearchLog.Id ? includes(formatUUID(item.Id), dataSearchLog.Id) : dataSearchLog.TypeLog ? item.TypeLog === dataSearchLog.TypeLog : true;
		});
		setDataTicketsSearchLog(tempSearch);
	};

	useEffect(() => {
		if (dataBalanceLog.length > 0) {
			_searchLog();
		}
		// eslint-disable-next-line
	}, [dataBalanceLog]);

	useIonViewDidEnter(() => {
		GetBalanceLog();
	});

	useEffect(() => {
		GetBalanceLog();
	}, []);

	return (
		<IonPage className="app-agency-balance">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/account")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Biến Động Số Dư
					</IonButton>
				</div>
				<div className="box-search">
					<IonButtons slot="primary" className="">
						<IonButton
							className="flex-1 btn-date"
							fill="solid"
							onClick={() => {
								setDataSearchLog(
									defaultsDeep(
										{
											DateStart: moment().format("DD-MM-YYYY"),
											DateEnd: moment().format("DD-MM-YYYY"),
										},
										dataSearchLog
									)
								);
							}}
						>
							Hôm Nay
						</IonButton>
						<IonButton
							fill="solid"
							className="flex-1 btn-date"
							onClick={() => {
								setDataSearchLog(
									defaultsDeep(
										{
											DateStart: moment().subtract(1, "day").format("DD-MM-YYYY"),
											DateEnd: moment().subtract(1, "day").format("DD-MM-YYYY"),
										},
										dataSearchLog
									)
								);
							}}
						>
							Hôm Qua
						</IonButton>
						<IonButton
							fill="solid"
							className="flex-1 btn-date"
							onClick={() => {
								setDataSearchLog(
									defaultsDeep(
										{
											DateStart: moment().startOf("month").format("DD-MM-YYYY"),
											DateEnd: moment().endOf("month").format("DD-MM-YYYY"),
										},
										dataSearchLog
									)
								);
							}}
						>
							Tháng Này
						</IonButton>
						<IonButton
							fill="solid"
							className="flex-1 btn-date"
							onClick={() => {
								setDataSearchLog(
									defaultsDeep(
										{
											DateStart: moment().subtract(1, "month").startOf("month").format("DD-MM-YYYY"),
											DateEnd: moment().subtract(1, "month").endOf("month").format("DD-MM-YYYY"),
										},
										dataSearchLog
									)
								);
							}}
						>
							Tháng Trước
						</IonButton>
					</IonButtons>
					<div className="select-date gap flex ng-star-inserted">
						<IonInput
							className="flex-1 px-5 input-search"
							placeholder="Tìm theo mã lệnh giao dịch ..."
							onIonChange={(e) => {
								setDataSearchLog(
									defaultsDeep(
										{
											Id: e.target.value,
										},
										dataSearchLog
									)
								);
							}}
						/>
						<IonButton
							id="biendongsodu"
							mode="md"
							className="md flex-1 flex input-search button button-solid btn-bdsd ion-activatable ion-focusable hydrated activated"
						>
							<span className="flex-1 text-black">{dataSearchLog.TypeLog ? dataSearchLog.TypeLog : "Chọn giao dịch"}</span>
							<IonIcon icon={caretDown} slot="end" className="md hydrated" />
						</IonButton>
						<IonPopover trigger="biendongsodu" dismissOnSelect={true} alignment="end" size="auto">
							<IonContent>
								<IonList>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "",
													},
													dataSearchLog
												)
											);
										}}
									>
										Chọn giao dịch
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Thu hồi điểm Evo",
													},
													dataSearchLog
												)
											);
										}}
									>
										Thu hồi điểm Evo
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Thu hồi điểm",
													},
													dataSearchLog
												)
											);
										}}
									>
										Thu hồi điểm
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Duyệt rút tiền",
													},
													dataSearchLog
												)
											);
										}}
									>
										Duyệt rút tiền
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Hủy rút tiền",
													},
													dataSearchLog
												)
											);
										}}
									>
										Hủy rút tiền
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Hủy rút tiền",
													},
													dataSearchLog
												)
											);
										}}
									>
										Hủy rút tiền
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Khuyến mãi",
													},
													dataSearchLog
												)
											);
										}}
									>
										Khuyến mãi
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Chuyển điểm đại lý",
													},
													dataSearchLog
												)
											);
										}}
									>
										Chuyển điểm đại lý
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Thu hồi điểm Saba",
													},
													dataSearchLog
												)
											);
										}}
									>
										Thu hồi điểm Saba
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Chuyển điểm",
													},
													dataSearchLog
												)
											);
										}}
									>
										Chuyển điểm
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Cộng tiền cược",
													},
													dataSearchLog
												)
											);
										}}
									>
										Cộng tiền cược
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Hoàn trả cược",
													},
													dataSearchLog
												)
											);
										}}
									>
										Hoàn trả cược
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Rút tiền",
													},
													dataSearchLog
												)
											);
										}}
									>
										Rút tiền
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Hủy cược",
													},
													dataSearchLog
												)
											);
										}}
									>
										Hủy cược
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Đặt cược",
													},
													dataSearchLog
												)
											);
										}}
									>
										Đặt cược
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Thêm điểm",
													},
													dataSearchLog
												)
											);
										}}
									>
										Thêm điểm
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Nạp tiền",
													},
													dataSearchLog
												)
											);
										}}
									>
										Nạp tiền
									</IonItem>
									<IonItem
										button={true}
										detail={false}
										onClick={(e) => {
											e.preventDefault();
											setDataSearchLog(
												defaultsDeep(
													{
														TypeLog: "Trả thưởng",
													},
													dataSearchLog
												)
											);
										}}
									>
										Trả thưởng
									</IonItem>
								</IonList>
							</IonContent>
						</IonPopover>
					</div>
					<div className="flex gap">
						<IonInput
							value={moment(dataSearchLog.DateStart, "DD-MM-YYYY").format("DD-MM-YYYY")}
							className="flex-1 px-5 input-search"
							readonly
							onClick={() => setIsDateStart(true)}
						/>
						<span className="text-black">Đến</span>
						<IonInput
							value={moment(dataSearchLog.DateEnd, "DD-MM-YYYY").format("DD-MM-YYYY")}
							className="flex-1 px-5 input-search"
							readonly
							onClick={() => setIsDateEnd(true)}
						/>
						<IonButton fill="solid" className="flex-1 btn-search input-search" onClick={() => _searchLog()}>
							TÌM KIẾM
						</IonButton>
					</div>
				</div>
			</div>
			<IonContent className="ion-content ion-content-segment agency-form ios overscroll hydrated">
				<IonList className="sic-bet-history ios list-ios">
					{dataTicketsSearchLog.map((item: any, index: any) => {
						return (
							<React.Fragment key={index}>
								<IonItem
									className={`${rowSelect === index ? "mb-0 border-item-active" : ""} bet-history__main--item bg-item border-item item-lines-none`}
									lines="none"
									onClick={() => {
										setRowSelect(index);
									}}
								>
									<div className="item-bet-history">
										<section className="bet-history-section flex-1">
											<div className="bet-history--channel">{item?.TypeLog}</div>
											<div className="bet-history--time">{TITLE_BALANCE_LOG[item?.TypeLog] ? TITLE_BALANCE_LOG[item?.TypeLog] : formatUUID(item?.Id)}</div>
										</section>
										<section className="bet-history-section">
											<div className="bet-history--amount">
												<span className={`${item?.Amount < 0 ? "color-trung" : ""} font-weight-bold`}>{formatMoney(item?.Amount)}</span>
												<div className="bet-history--status">{moment(item?.CreatedTime).format("DD-MM-YYYY HH:mm:ss")}</div>
											</div>
										</section>
									</div>
								</IonItem>
								{rowSelect === index && (
									<IonItem className="bet-history__main--item item-select item-lines-none border-radius-top border-item-active border-top-0" lines="none">
										<div className="item-bet-history">
											<section className="bet-history-section flex-1">
												<div className="bet-history--channel font-weight-normal">
													Số dư ban đầu:{" "}
													<span className={`${sum([item?.AmountFinal, item?.Amount * -1]) < 0 ? "color-trung" : ""} font-weight-bold`}>
														{formatMoney(toNumber(sum([item?.AmountFinal, item?.Amount * -1]).toFixed(2)))}
													</span>
												</div>
												<div className="bet-history--time">{formatUUID(item?.Note)}</div>
											</section>
											<section className="bet-history-section">
												<div className="bet-history--amount max-width-100">
													Số dư còn lại:{" "}
													<span className={`${item?.AmountFinal < 0 ? "color-trung" : ""} font-weight-bold`}>{formatMoney(item?.AmountFinal)}</span>
												</div>
											</section>
										</div>
									</IonItem>
								)}
							</React.Fragment>
						);
					})}
				</IonList>
			</IonContent>
			{isDateEnd && (
				<IonModal id="vibet88-modal" className="modal-date" isOpen={true} onIonModalDidDismiss={() => setIsDateEnd(false)}>
					<IonContent>
						<IonDatetime
							value={moment(dataSearchLog.DateEnd, "DD-MM-YYYY").format("YYYY-MM-DD")}
							presentation="date"
							onIonChange={(e) => {
								setDataSearchLog(
									defaultsDeep(
										{
											DateEnd: moment(e.detail.value, "YYYY-MM-DD").format("DD-MM-YYYY"),
										},
										dataSearchLog
									)
								);
								setIsDateEnd(false);
							}}
						></IonDatetime>
					</IonContent>
				</IonModal>
			)}
			{isDateStart && (
				<IonModal id="vibet88-modal" className="modal-date" isOpen={true} onIonModalDidDismiss={() => setIsDateStart(false)}>
					<IonContent>
						<IonDatetime
							value={moment(dataSearchLog.DateStart, "DD-MM-YYYY").format("YYYY-MM-DD")}
							presentation="date"
							onIonChange={(e) => {
								setDataSearchLog(
									defaultsDeep(
										{
											DateStart: moment(e.detail.value, "YYYY-MM-DD").format("DD-MM-YYYY"),
										},
										dataSearchLog
									)
								);
								setIsDateStart(false);
							}}
						></IonDatetime>
					</IonContent>
				</IonModal>
			)}
		</IonPage>
	);
};

export default AgencyBalance;
