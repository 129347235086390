import React, { useState, useEffect } from "react";
import { IonSlides, IonSlide, IonContent, IonTitle, IonToolbar, IonButtons, IonImg, IonModal, IonButton, IonGrid, useIonToast } from "@ionic/react";
import { getPromotions, applyPromotion } from "../redux/auth/authCrud";
import moment from "moment";

import "../pages/Home.css";

import { IMG_URL, IMG_MB } from "../constants";

const SlideHome: React.FC = () => {
	const [present] = useIonToast();

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const [dataPromotions, setDataPromotions] = useState<any>([]);
	const [dataModalPromotion, setDataModalPromotion] = useState<any>(null);

	const _applyPromotion = async (promotionId: any) => {
		const res = await applyPromotion(promotionId);

		if (res?.data && res?.data?.error && res?.data?.error?.message) return presentToast("error", res?.data?.error?.message);

		setDataModalPromotion(null);
		presentToast("success", "Nhận khuyến mãi thành công!");
	};

	const GetPromotions = async () => {
		const res = await getPromotions();
		if (res && res.data) {
			setDataPromotions(res.data);
		}
	};

	useEffect(() => {
		GetPromotions();
	}, []);

	return (
		<>
			{dataPromotions.length > 0 && (
				<div className="slide-header">
					<div className="slideHome  ng-star-inserted">
						<IonSlides
							className="home-slides ios slides-ios swiper-container hydrated swiper-container-initialized swiper-container-horizontal swiper-container-ios"
							options={{
								autoplay: {
									delay: 10000,
								},
							}}
							pager={true}
						>
							{dataPromotions.map((item: any, index: any) => {
								if (!item.isActive || !item.IsBannerHome) return <React.Fragment key={index}></React.Fragment>;
								return (
									<IonSlide className="swiper-slide swiper-zoom-container hydrated" key={index}>
										<IonImg src={`${IMG_URL}${item?.BannerMobile}`} onClick={() => setDataModalPromotion(item)} />
									</IonSlide>
								);
							})}
						</IonSlides>
					</div>
				</div>
			)}
			<IonModal id="vibet88-modal" className="h-70" isOpen={dataModalPromotion ? true : false} onIonModalDidDismiss={() => setDataModalPromotion(null)}>
				<IonContent className="modal-promotion-content">
					<div className="header">
						<IonToolbar>
							<IonTitle>{dataModalPromotion?.Name}</IonTitle>
							<IonButtons slot="end">
								<IonButton color="light" onClick={() => setDataModalPromotion(null)}>
									<IonImg className="icon-close" src={`${IMG_MB}assets/icon/close-modal.png`} />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<div className="flex flex-col gab-10 promotion-header">
							<span>
								Áp dụng từ: <span className="date">{moment(dataModalPromotion?.DateStart).format("DD-MM-YYYY")}</span> đến{" "}
								<span className="date">{moment(dataModalPromotion?.DateEnd).format("DD-MM-YYYY")}</span>
							</span>
							{dataModalPromotion?.ShowRegisterBtn && (
								<IonButton
									expand="block"
									className="ios button button-block button-solid ion-activatable ion-focusable hydrated btn-naptien"
									onClick={() => _applyPromotion(dataModalPromotion?.Id)}
								>
									ĐĂNG KÍ NGAY
								</IonButton>
							)}
						</div>
					</div>
					<IonGrid className="ios ion-grid hydrated">
						<div className="flex flex-col gab-10 py-10">
							<p
								className="ck-content"
								dangerouslySetInnerHTML={{
									__html: dataModalPromotion?.NoteMobile,
								}}
							></p>
						</div>
					</IonGrid>
				</IonContent>
			</IonModal>
		</>
	);
};

export default SlideHome;
