import { persistReducer } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const actionTypes = {
  UpdateTicketResult: "[Update Ticket Result] Action",
};
const initialState = {
  ticketResult: null
};

export const reducer = persistReducer({ storage: AsyncStorage, key: "VB_MB_L" }, (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UpdateTicketResult: {
      return { ...state, ticketResult: action.payload };
    }

    default:
      return state;
  }
});

export const actions = {
  updateTicketResult: (data) => ({ type: actionTypes.UpdateTicketResult, payload: data }),
};