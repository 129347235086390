import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonIcon,
  IonRippleEffect,
  IonButton,
  IonLabel,
  IonDatetime,
  IonDatetimeButton,
  IonModal,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import "./ReportDateSearch.css";

const ReportDateSearch: React.FC = () => {
  return (
    <IonPage className="app-agency-reg">
      <IonHeader>
        <IonToolbar className="ion-toolbar">
          <IonButtons slot="start">
            <IonBackButton text="" defaultHref="/agency">
              <button type="button" className="button-native sc-ion-back-button-md">
                <span className="button-inner sc-ion-back-button-md">
                  <IonIcon className="sc-ion-back-button-md md hydrated" icon={arrowBack} role="img" aria-label="arrow back"></IonIcon>
                </span>
                <IonRippleEffect className="sc-ion-back-button-md md unbounded hydrated" role="presentation"></IonRippleEffect>
              </button>
            </IonBackButton>
          </IonButtons>
          <IonTitle>Báo cáo nhóm</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="agency-form ion-padding ios overscroll hydrated content-ltr" fullscreen>
        <div _ngcontent-oei-c20="" className="frm-item">
          <IonLabel _ngcontent-oei-c20="" className="ion-label sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
            Thời gian từ
          </IonLabel>
          <IonDatetimeButton datetime="datetimeForm"></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              id="datetimeForm"
              cancelText="Hủy"
              doneText="Chọn"
              min="2017"
              mode="ios"
              className="ng-untouched ng-pristine ng-valid ios hydrated ion-untouched ion-pristine ion-valid"
              role="combobox"
              aria-expanded="false"
              aria-haspopup="true"
              aria-labelledby="ion-dt-39-lbl"
            ></IonDatetime>
          </IonModal>
        </div>
        <div _ngcontent-nto-c18="" className="frm-item">
          <IonLabel _ngcontent-nto-c18="" className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
            Đến
          </IonLabel>
          <IonDatetimeButton datetime="datetimeTo"></IonDatetimeButton>
          <IonModal keepContentsMounted={true}>
            <IonDatetime
              id="datetimeTo"
              cancelText="Hủy"
              doneText="Chọn"
              min="2017"
              mode="ios"
              className="ng-untouched ng-pristine ng-valid ios hydrated ion-untouched ion-pristine ion-valid"
              role="combobox"
              aria-expanded="false"
              aria-haspopup="true"
              aria-labelledby="ion-dt-39-lbl"
            ></IonDatetime>
          </IonModal>
        </div>
        <IonButton _ngcontent-nto-c18="" className="af-submit-btn ios button button-block button-solid ion-activatable ion-focusable hydrated" expand="block">
          Tìm kiếm
        </IonButton>
      </IonContent>
    </IonPage>
  );
};

export default ReportDateSearch;
