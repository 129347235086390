import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
	IonContent,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonIcon,
	IonGrid,
	IonRow,
	IonCol,
	IonText,
	IonImg,
	IonButton,
	IonModal,
	IonItem,
	useIonAlert,
	useIonToast,
	IonLabel,
	IonList,
} from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useSelector, shallowEqual } from "react-redux";
import { userRequestAgent } from "../redux/auth/authCrud";
import { chevronForward } from "ionicons/icons";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./Agency.css";
import { IMG_MB } from "../constants";

const Agency: React.FC = () => {
	const history = useHistory();

	const [presentAlert] = useIonAlert();
	const [present] = useIonToast();

	const [modalRequestAgent, setModalRequestAgent] = useState<any>(null);

	const { userInfo } = useSelector(
		(state: any) => ({
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const _userRequestAgent = async () => {
		if (!modalRequestAgent.periodTime) return presentToast("error", "Lựa chọn chu kỳ.");

		const res = await userRequestAgent(modalRequestAgent.periodTime);
		if (res && res.data && res.data.Status === false) return presentToast("error", "Đăng ký đại lý không thành công.");

		presentAlert({
			header: "Thông Báo",
			message: `Bạn đã đăng ký làm đại lý VIBET88 thành công.
      Nhân viên hỗ trợ Đại Lý Vibet88 sẽ sớm liên hệ lại với bạn 
      để xác minh và hỗ trợ.`,
			buttons: ["OK"],
			cssClass: "alert-custom",
		});
		setModalRequestAgent(null);
	};

	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/account")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						{userInfo?.RoleName === "agent" ? "Trung Tâm Đại Lý" : "Đăng Ký Làm Đại Lý"}
					</IonButton>
				</div>
			</div>
			<IonContent className="app-agency">
				{userInfo?.RoleName === "agent" ? (
					<IonList className="agent-ion-list" no-line>
						<IonItem className="ion-item" onClick={() => history.push("/agency-link")}>
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/lienketdangky.png`} />
							<IonLabel className="">Liên kết đăng ký</IonLabel>
							<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
						</IonItem>
						<IonItem className="ion-item" onClick={() => history.push("/agency-register")}>
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/dangkycapduoi.png`} />
							<IonLabel className="">Đăng ký cấp dưới</IonLabel>
							<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
						</IonItem>
						<IonItem className="ion-item" onClick={() => history.push("/agency-members")}>
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/quanlythanhvien.png`} />
							<IonLabel className="">Quản lý thành viên</IonLabel>
							<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
						</IonItem>
						<IonItem className="ion-item" onClick={() => history.push("/agency-transfer")}>
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/chuyentiencapduoi.png`} />
							<IonLabel className="">Chuyển tiền cấp dưới</IonLabel>
							<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
						</IonItem>
						<IonItem className="ion-item" onClick={() => history.push("/agency-report")}>
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/baocaothunhap.png`} />
							<IonLabel className="">Báo cáo thu nhập</IonLabel>
							<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
						</IonItem>
					</IonList>
				) : (
					<IonGrid className="ios agency-ion-grid">
						<IonRow className="ion-row agency-section">
							<IonCol size="12">
								<div className="agency-ion-col">
									<IonImg src={`${IMG_MB}assets/icon/icon-star.png`} />
									<IonText className="">Đăng ký làm đại lý VIBET88 nhận hoa hồng tiền tỷ.</IonText>
								</div>
							</IonCol>
							<IonCol size="12">
								<div className="agency-ion-col">
									<IonImg className="" src={`${IMG_MB}assets/icon/icon-star.png`} />
									<IonText className="">VIBET88 có những sảnh trò chơi độc quyền và uy tín.</IonText>
								</div>
							</IonCol>
							<IonCol size="12">
								<div className="agency-ion-col">
									<IonImg className="" src={`${IMG_MB}assets/icon/icon-star.png`} />
									<IonText className="">Xem chi tiết chương trình tại trang Đại Lý VIBET88.</IonText>
								</div>
							</IonCol>
							<IonCol size="12">
								<div className="agency-ion-col ion-justify-content-center">
									<IonButton
										expand="block"
										className="ios button button-block button-solid ion-activatable ion-focusable hydrated btn-naptien"
										onClick={() => setModalRequestAgent({ periodTime: 30 })}
									>
										Đăng Ký Làm Đại Lý
									</IonButton>
								</div>
							</IonCol>
						</IonRow>
					</IonGrid>
				)}
			</IonContent>
			<IonModal id="vibet88-modal" isOpen={modalRequestAgent ? true : false} onIonModalDidDismiss={() => setModalRequestAgent(null)}>
				<IonToolbar>
					<IonTitle>ĐĂNG KÝ LÀM ĐẠI LÝ</IonTitle>
					<IonButtons slot="end">
						<IonButton color="light" onClick={() => setModalRequestAgent(null)}>
							<IonImg className="icon-close" src={`${IMG_MB}assets/icon/close-modal.png`} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonContent>
					<IonGrid className="ios agency-ion-grid">
						<IonRow className="ion-row">
							<IonCol size="12">
								<div className="agency-ion-col flex gap-10">
									<IonImg className="" src={`${IMG_MB}assets/icon/icon-next.png`} />
									<IonText className="">Đăng ký làm đại lý VIBET88 nhận hoa hồng tiền tỷ.</IonText>
								</div>
							</IonCol>
							<IonCol size="12">
								<div className="agency-ion-col flex gap-10">
									<IonImg className="" src={`${IMG_MB}assets/icon/icon-next.png`} />
									<IonText className="">VIBET88 có những sảnh trò chơi độc quyền và uy tín.</IonText>
								</div>
							</IonCol>
							<IonCol size="12">
								<div className="agency-ion-col flex gap-10">
									<IonImg className="" src={`${IMG_MB}assets/icon/icon-next.png`} />
									<IonText className="">Xem chi tiết chương trình tại trang Đại Lý VIBET88.</IonText>
								</div>
							</IonCol>
							<IonCol size="12">
								<div className="agency-ion-col flex gap-10">
									<IonImg className="" src={`${IMG_MB}assets/icon/icon-star.png`} />
									<IonText className="">Lựa chọn chu kỳ</IonText>
								</div>
							</IonCol>
							<IonCol size="12">
								<div className="agency-ion-col flex gap-10 ion-justify-content-center">
									<IonButton
										expand="block"
										className="ios button button-block button-solid ion-activatable ion-focusable hydrated btn-naptien"
										onClick={() => _userRequestAgent()}
									>
										Xác Nhận
									</IonButton>
									<IonButton
										expand="block"
										className="ios button button-block button-solid ion-activatable ion-focusable hydrated btn-naptien"
										onClick={() => setModalRequestAgent(null)}
									>
										Huỷ
									</IonButton>
								</div>
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonModal>
		</IonPage>
	);
};

export default Agency;
