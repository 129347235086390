import React, { useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonImg, IonGrid, IonCol, IonRow } from "@ionic/react";
import { useSelector, shallowEqual } from "react-redux";

import SlideHome from "../components/SlideHome";
import HeaderHome from "../components/HeaderHome";
import MenuGames from "../components/MenuGames";

import "./Home.css";
import { IMG_MB } from "../constants";

const Sport: React.FC = () => {
	const history = useHistory();
	const { authToken } = useSelector(
		(state: any) => ({
			authToken: state.auth.authToken,
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	useLayoutEffect(() => {
		if (!authToken) {
			window.location.href = "/home?login=true";
		}
	}, [authToken, history]);

	if (!authToken) return <IonImg className="page-loading" src={`${IMG_MB}assets/v2/loading.webp`} />;

	return (
		<IonPage className="app-home">
			<HeaderHome />
			<IonContent fullscreen className="homepage">
				<div className="home-main">
					<SlideHome />
					<MenuGames />
					<div className="lottery-category">
						<IonGrid className="app-lottery pt-0 list-lt bg-white">
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Thể Thao </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonCol className="item-lottery-col" size="4">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v3/saba.png`} onClick={() => history.push("/sport/saba")} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v3/afb.png`} onClick={() => history.push("/sport-2/afbsport")} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v3/cmd.png`} onClick={() => history.push("/sport-3/cmd")} />
								</IonCol>
								{/* <IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/sbobet-off.png" />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/bbibet-off.png" />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/ibcbet-off.png" />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/cmd368-off.png" />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/maxbet-off.png" />
								</IonCol> */}
							</IonRow>
						</IonGrid>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Sport;
