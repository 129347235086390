import React from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonIcon, IonImg, IonItem, IonLabel, IonList } from "@ionic/react";
import { chevronForward } from "ionicons/icons";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./Agency.css";
import { IMG_MB } from "../constants";

const History: React.FC = () => {
	const history = useHistory();
	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="sic-title-page">
					<span>Lịch Sử Đặt Cược</span>
					<hr />
				</div>
			</div>
			<IonContent className="app-agency">
				<IonList className="agent-ion-list" no-line>
					<IonItem className="ion-item" onClick={() => history.push("/bet-history")}>
						<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/account/lichsucuocxoso.png`} />
						<IonLabel className="">Lịch sử cược</IonLabel>
						<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
					<IonItem className="ion-item" onClick={() => history.push("/keep-bet-history")}>
						<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/account/lichsucuocxoso.png`} />
						<IonLabel className="">Lịch sử nuôi xổ số</IonLabel>
						<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
					</IonItem>
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default History;
