import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonImg, IonItem, IonInput, IonButton, IonIcon, useIonToast, useIonAlert, IonCheckbox } from "@ionic/react";
import { useHistory } from "react-router-dom";
import { eye } from "ionicons/icons";
import { IMG_MB, INIT_REGISTER } from "../constants";
import { defaultsDeep, size, toLower } from "lodash";
import { register, getPosts } from "../redux/auth/authCrud";
import HeaderHome from "../components/HeaderHome";

import "./Register.css";
import { setCookie, getCookie } from "../util";

const Register: React.FC = () => {
	const history = useHistory();

	const [present] = useIonToast();
	const [presentAlert] = useIonAlert();

	const [dataRegister, setDataRegister] = useState(INIT_REGISTER);
	const [isShowPass, setIsShowPass] = useState(false);
	const [isConfirmPass, setIsConfirmPass] = useState(false);

	const [isShowModalPost, setIsShowModalPost] = useState<any>(null);
	const [dataPosts, setDataPosts] = useState([]);
	const [isCheckRegistered, setIsCheckRegistered] = useState(true);

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const regexPhoneNumber = (phone: any) => {
		const regexPhoneNumber = /(0[3|5|7|8|9])+([0-9]{8})\b/g;
		return phone.match(regexPhoneNumber) ? true : false;
	};

	const _register = () => {
		if (!isCheckRegistered) return presentToast("error", "Vui lòng xác nhận trên 18 tuổi và đã đọc điều khoản và điều kiện");
		if (!dataRegister.username) return presentToast("error", "Tên đăng nhập không được để trống");
		if (dataRegister.username && size(dataRegister.username) < 6) return presentToast("error", "Tên đăng nhập không được ít hơn 5 ký tự");
		if (dataRegister.username && size(dataRegister.username) > 15) return presentToast("error", "Tên đăng nhập không được lớn hơn 15 ký tự");
		if (!/^[a-zA-Z0-9_]*$/.test(dataRegister.username)) return presentToast("error", "Tên đăng nhập không được chứa ký tự đặc biệt");
		if (!dataRegister.password) return presentToast("error", "Mật khẩu không được để trống");
		if (dataRegister.password && size(dataRegister.password) < 7) return presentToast("error", "Mật khẩu đăng nhập không được ít hơn 6 ký tự");
		if (!dataRegister.confirmPassword) return presentToast("error", "Xác nhận mật khẩu đăng nhập không được để trống");
		if (dataRegister.confirmPassword !== dataRegister.password) return presentToast("error", "Xác nhận mật khẩu không chính xác");
		if (!dataRegister.mobile) return presentToast("error", "Số điện thoại không được để trống");
		if (dataRegister.mobile && size(dataRegister.mobile) !== 10) return presentToast("error", "Số điện thoại phải là 10 ký tự");
		if (!regexPhoneNumber(dataRegister.mobile)) return presentToast("error", "Số điện thoại không đúng định dạng");
		if (dataRegister.username && size(dataRegister.username) > 15) return presentToast("error", "Tên đăng nhập không được lớn hơn 15 ký tự");
		const inviteCode = getCookie("reg-id");

		const params = defaultsDeep({ InviteCode: inviteCode || "", username: toLower(dataRegister.username) }, dataRegister);
		register(params).then((res: any) => {
			const { data } = res;

			if (data.error) {
				presentToast("error", data.error.message);
			}

			if (!data.error) {
				presentAlert({
					header: "Thông Báo",
					message: "Đăng ký tài khoản thành công",
					buttons: [
						{
							text: "OK",
							role: "confirm",
							handler: () => history.push("/home"),
						},
					],
					cssClass: "alert-custom",
					backdropDismiss: false,
				});
			}
		});
	};

	const GetDataPosts = async () => {
		const { data } = await getPosts();

		if (data) {
			setDataPosts(data);
		}
	};

	useEffect(() => {
		GetDataPosts();
	}, []);

	useEffect(() => {
		const urlParams = new URLSearchParams(history.location.search);
		if (urlParams.get("reg-id")) {
			setCookie("reg-id", urlParams.get("reg-id"));
			history.push("/register");
		}
	}, [history]);

	return (
		<IonPage className="open-noti">
			<div className="app-home homepage">
				<HeaderHome />
			</div>
			<IonContent className="app-register">
				<div className="flex flex-col gap-6 box-register">
					<span className="title">ĐĂNG KÝ TÀI KHOẢN</span>
					<span className="sub-title">
						Nếu bạn đã có tài khoản, hãy{" "}
						<span className="text-0075ff" onClick={() => history.push("/home?login=true")}>
							Đăng Nhập
						</span>
					</span>
				</div>
				<section className="register bg-[#D9D9D9] mb-300">
					<main className="pt-20">
						<IonItem className="register__username  item-label item-label-stacked item-interactive item-input item-has-placeholder item md ion-focusable hydrated ion-star-inserted ion-untouched ion-pristine ion-valid">
							<div className="flex flex-col align-items-start w100">
								<div className="flex flex-1 justify-content-start">
									<span className="title">Tên Đăng Nhập</span>
								</div>
								<div className="flex flex-1 min-w-100">
									<IonInput
										placeholder="Từ 6-15 ký tự, có thể bao gồm cả chữ cái, số và gạch dưới ..."
										className="min-w-100 sc-IonInput-md-h sc-IonInput-md-s md hydrated ion-star-inserted ion-untouched ion-pristine ion-valid"
										onIonChange={(e: any) => {
											setDataRegister(defaultsDeep({ username: e.target.value }, dataRegister));
										}}
									/>
								</div>
							</div>
						</IonItem>
						<IonItem className="register__pwd  item-label item-label-stacked item-interactive item-input item-has-placeholder item md ion-focusable hydrated ion-star-inserted ion-untouched ion-pristine ion-valid">
							<div className="flex flex-col align-items-start w100">
								<div className="flex flex-1 justify-content-start">
									<span className="title">Mật Khẩu Đăng Nhập</span>
								</div>
								<div className="flex flex-1 min-w-100">
									<IonInput
										placeholder="Hơn 6 ký tự, phải bao gồm cả chữ cái và số ..."
										type={isShowPass ? "text" : "password"}
										className="flex-1 sc-IonInput-md-h sc-IonInput-md-s md hydrated ion-star-inserted ion-untouched ion-pristine ion-valid"
										onIonChange={(e: any) => {
											setDataRegister(defaultsDeep({ password: e.target.value }, dataRegister));
										}}
									/>
									<IonIcon className="register__icon--eye  md hydrated" icon={eye} role="img" aria-label="eye" onClick={() => setIsShowPass(!isShowPass)} />
								</div>
							</div>
						</IonItem>
						<IonItem className="register__withdrawpwd  item-label item-label-stacked item-interactive item-input item-has-placeholder item md ion-focusable hydrated ion-star-inserted ion-untouched ion-pristine ion-valid">
							<div className="flex flex-col align-items-start w100">
								<div className="flex flex-1 justify-content-start">
									<span className="title">Xác Nhận Mật Khẩu</span>
								</div>
								<div className="flex flex-1 min-w-100">
									<IonInput
										placeholder="Nhập lại mật khẩu đã nhập bên trên ..."
										type={isConfirmPass ? "text" : "password"}
										className=" sc-IonInput-md-h sc-IonInput-md-s md hydrated ion-star-inserted ion-untouched ion-pristine ion-valid"
										onIonChange={(e: any) => {
											setDataRegister(
												defaultsDeep(
													{
														confirmPassword: e.target.value,
													},
													dataRegister
												)
											);
										}}
									/>
									<IonIcon
										className="register__icon--eye  md hydrated"
										icon={eye}
										role="img"
										aria-label="eye"
										onClick={() => setIsConfirmPass(!isConfirmPass)}
									/>
								</div>
							</div>
						</IonItem>
						<IonItem className="register__phone  item-label item-label-stacked item-interactive item-input item-has-placeholder item md ion-focusable hydrated ion-star-inserted ion-untouched ion-pristine ion-valid">
							<div className="flex flex-col align-items-start w100">
								<div className="flex flex-1 justify-content-start">
									<span className="title">Số Điện Thoại</span>
								</div>
								<div className="flex flex-1 min-w-100">
									<IonInput
										placeholder="Yêu cầu số điện thoại di động thật để xác minh tài khoản ..."
										className="sc-IonInput-md-h min-w-100 sc-IonInput-md-s md hydrated ion-star-inserted ion-untouched ion-pristine ion-valid"
										onIonChange={(e: any) => {
											e.target.value = e.target.value.replace(/[^0-9]/g, "");
											setDataRegister(defaultsDeep({ mobile: e.target.value }, dataRegister));
										}}
									/>
								</div>
							</div>
						</IonItem>
						<div className="flex align-items-normal gap">
							<IonCheckbox
								checked={isCheckRegistered}
								color="success"
								mode="md"
								name="isCheckRegistered"
								className="ion-color ion-color-success md interactive hydrated ion-untouched ion-pristine ion-valid"
								role="checkbox"
								aria-checked="false"
								aria-labelledby="ion-cb-9-lbl"
								onIonChange={(e) => setIsCheckRegistered(e.detail.checked)}
							/>
							<span className="sub-title">
								Tôi đã đủ 18 tuổi và chấp nhận{" "}
								<span className="text-0075ff" onClick={() => setIsShowModalPost(dataPosts[1])}>
									Điều khoản và điều kiện
								</span>
								<span className="text-red"> *</span>
							</span>
						</div>
						<div className="register__button">
							<IonButton
								className="af-submit-btn md button button-block button-solid ion-activatable ion-focusable hydrated"
								expand="block"
								onClick={() => _register()}
							>
								ĐĂNG KÝ NGAY
							</IonButton>
						</div>
					</main>
				</section>
				{isShowModalPost && (
					<div className="showNoti ng-tns-c6-91 ng-star-inserted top-0" id="showNoti">
						<div className="showNoti__wrapper">
							<span className="showNoti__wrapper__title">{isShowModalPost?.Title}</span>
							<IonImg className="btn-close ios hydrated" src={`${IMG_MB}assets/noti-detail/noti-close.svg`} onClick={() => setIsShowModalPost(null)}></IonImg>
							<div className="showNoti__wrapper__content">
								<div className="content">
									<div className="content__scroll">
										<p
											dangerouslySetInnerHTML={{
												__html: isShowModalPost?.DescriptionMobile,
											}}
										></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</IonContent>
		</IonPage>
	);
};

export default Register;
