import React, { useLayoutEffect } from "react";
import { IonContent, IonPage, IonImg, IonGrid, IonCol, IonRow } from "@ionic/react";
import { useSelector, shallowEqual } from "react-redux";
import SlideHome from "../components/SlideHome";
import HeaderHome from "../components/HeaderHome";
import MenuGames from "../components/MenuGames";

import "./Home.css";
import { IMG_MB } from "../constants";

const Game: React.FC = () => {
	const { authToken } = useSelector(
		(state: any) => ({
			authToken: state.auth.authToken,
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	useLayoutEffect(() => {
		if (!authToken) {
			window.location.href = "/home?login=true";
		}
	}, [authToken]);

	return (
		<IonPage className="app-home">
			<HeaderHome />
			<IonContent fullscreen className="homepage">
				<div className="home-main">
					<SlideHome />
					<MenuGames />
					<div className="lottery-category">
						<IonGrid className="app-lottery pt-0 list-lt bg-white">
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Game Dân Gian </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonCol className="item-lottery-col" size="6">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/taixiu.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="6">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/oantuti.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="6">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/xocdia.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="6">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/baucua.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="6">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/12congiap.png`} />
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Game;
