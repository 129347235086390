import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonToolbar, IonImg, IonIcon, IonButtons, IonTitle, IonModal, IonButton, IonGrid } from "@ionic/react";
import { chevronForward, arrowBack } from "ionicons/icons";
import { getNews } from "../redux/auth/authCrud";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { useHistory } from "react-router-dom";
import { reduce } from "lodash";

import "./UserInfo.css";
import { IMG_MB } from "../constants";

const Notification: React.FC = () => {
	const history = useHistory();

	const [isShowModalNoti, setIsShowModalNoti] = useState<any>(null);
	const [dataNotifications, setDataNotifications] = useState([]);

	const getDataNotifications = async () => {
		const { data = [] } = await getNews();
		const newData = reduce(
			data,
			(res: any, item) => {
				if (!item?.AutoShowPopup && item?.isActive) {
					res.push(item);
				}

				return res;
			},
			[]
		);
		setDataNotifications(newData);
	};

	useEffect(() => {
		getDataNotifications();
	}, []);

	return (
		<IonPage className="app-user-edit app-home app-user-banks ng-star-inserted ion-page can-go-back">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/information")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Thông Báo
					</IonButton>
				</div>
			</div>
			<IonContent className="ion-content">
				<div className="p-10">
					{dataNotifications.length > 0 && (
						<table className="sic-table">
							<tbody className="bg-F1F1F1">
								{dataNotifications.map((item: any, index) => {
									return (
										<tr key={index}>
											<td className={`${index === 0 ? `bt-0 rounded-tl-10` : ``} w-30`}>{index + 1}</td>
											<td className={`${index === 0 ? `bt-0` : ``} text-left`}>
												<span className="text-title">{item?.Title}</span>
											</td>
											{/* <td className={`${index === 0 ? `bt-0` : ``} w-80`}>{moment(item?.CreatedTime).format("MM-DD-YYYY")}</td> */}
											<td className={`${index === 0 ? `bt-0 rounded-tr-10` : ``} w-30`} onClick={() => setIsShowModalNoti(item)}>
												<IonIcon slot="end" role="img" icon={chevronForward}></IonIcon>
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					)}
				</div>
			</IonContent>
			<IonModal id="vibet88-modal" className="height-80" isOpen={isShowModalNoti ? true : false} onIonModalDidDismiss={() => setIsShowModalNoti(null)}>
				<IonToolbar>
					<IonTitle>CHI TIẾT TIN NHẮN</IonTitle>
					<IonButtons slot="end">
						<IonButton color="light">
							<IonImg className="icon-close" src={`${IMG_MB}assets/icon/close-modal.png`} onClick={() => setIsShowModalNoti(null)} />
						</IonButton>
					</IonButtons>
				</IonToolbar>
				<IonContent className="ion-content-message">
					<IonGrid className="ios ion-grid ion-grid-message hydrated">
						<p
							className="px-10 pb-10"
							dangerouslySetInnerHTML={{
								__html: isShowModalNoti?.Description,
							}}
						></p>
					</IonGrid>
				</IonContent>
			</IonModal>
		</IonPage>
	);
};

export default Notification;
