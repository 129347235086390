import React from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonIcon, IonGrid, IonRow, IonCol, IonText, IonImg, IonButton, useIonToast, IonInput } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useSelector, shallowEqual } from "react-redux";
import { Clipboard } from "@capacitor/clipboard";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./Agency.css";
import { IMG_MB } from "../constants";

const AgencyLink: React.FC = () => {
	const history = useHistory();
	const [present] = useIonToast();

	const { userInfo } = useSelector(
		(state: any) => ({
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const writeToClipboard = async (url: any) => {
		await Clipboard.write({
			string: url,
		});
		presentToast("success", "Copy link đăng kí thành công!");
	};

	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/agency")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Liên Kết Đăng Ký
					</IonButton>
				</div>
			</div>
			<IonContent className="app-agency">
				<IonGrid className="ios agency-ion-grid agent-grid-bg">
					<IonRow className="ion-row">
						<IonCol size="12">
							<div className="agency-ion-col flex gap-10">
								<IonImg src={`${IMG_MB}assets/icon/icon-next.png`} />
								<IonText>Liên kết này là duy nhất dành cho tài khoản Đại Lý của bạn.</IonText>
							</div>
						</IonCol>
						<IonCol size="12">
							<div className="agency-ion-col flex gap-10">
								<IonImg src={`${IMG_MB}assets/icon/icon-next.png`} />
								<IonText>Người dùng đăng ký bằng liên kết này sẽ là thành viên tuyến dưới của bạn.</IonText>
							</div>
						</IonCol>
						<IonCol size="12">
							<div className="agency-ion-col flex gap-10">
								<IonImg src={`${IMG_MB}assets/icon/icon-next.png`} />
								<IonText>Bạn sẽ nhận được Hoa Hồng Đại Lý không giới hạn với chương trình Đại Lý VIBET88.</IonText>
							</div>
						</IonCol>
						<IonCol size="12">
							<div className="agency-ion-col flex gap-10">
								<IonText>Liên Kết Của Bạn</IonText>
							</div>
						</IonCol>
						<IonCol size="12">
							<div className="flex">
								<IonInput className="ion-input-agent" value={`${window.location.origin}/register?reg-id=${userInfo?.InviteCode}`}></IonInput>
								<IonButton className="btn-copy-link" onClick={() => writeToClipboard(`${window.location.origin}/register?reg-id=${userInfo?.InviteCode}`)}>
									Copy Link
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				</IonGrid>
			</IonContent>
		</IonPage>
	);
};

export default AgencyLink;
