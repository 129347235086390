import { useState, useEffect } from "react";
import { IonModal, IonImg } from "@ionic/react";
import Cookie from "js-cookie";

export default function NewsletterModal({ DATA_KM }) {
	const [modalState, setModalState] = useState(false);
	const noMore = false;
	const [indexKM, setIndexKM] = useState(0);

	useEffect(() => {
		let timer;
		Cookie.get("hideNewsletter") ||
			(timer = setTimeout(() => {
				setModalState(true);
			}, 100));

		return () => {
			timer && clearTimeout(timer);
		};
	}, []);

	function closeModal() {
		if (DATA_KM.length - 1 > indexKM) {
			setIndexKM(indexKM + 1);
			return;
		}

		setTimeout(() => {
			setModalState(false);
			noMore && Cookie.set("hideNewsletter", "true", { expires: 7, path: window.location.pathname });
		}, 100);
	}

	return (
		<IonModal isOpen={modalState} onIonModalDidDismiss={closeModal} id="newsletter-popup">
			<div className="wrapper">
				{/* <IonImg className="mfp-close" src={`${IMG_MB}assets/icon/icon-register-close.png`} onClick={closeModal} /> */}
				<IonImg className="cursor-pointer" src={DATA_KM[indexKM]} onClick={closeModal} />
			</div>
		</IonModal>
	);
}
