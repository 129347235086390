import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonTitle, IonToolbar, IonButtons, IonImg, IonList, IonItem, IonModal, IonButton, IonGrid, IonRow, IonCol } from "@ionic/react";
import { getTickets, getChannels, getBetTypes } from "../redux/auth/authCrud";
import { reduce, orderBy, get, defaultsDeep, includes, toNumber } from "lodash";
import { formatMoney } from "../util";
import { BET_STATUS, IMG_MB } from "../constants";
import moment from "moment";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./BetHistory.css";

const KeepBetHistory: React.FC = () => {

	const [dataTickets, setDataTickets] = useState<any>([]);
	const [dataChannels, setDataChannels] = useState({});
	const [dataBetTypes, setDataBetTypes] = useState({});
	const [modalTicket, setModalTicket] = useState<any>(null);

	const GetTickets = async () => {
		const res = await getTickets();
		if (res && res.data) {
			const newData = reduce(
				res.data,
				(res: any, item) => {
					if (item.stopWon) {
						res.push(item);
					}

					return res;
				},
				[]
			);

			setDataTickets(orderBy(newData, [(obj) => new Date(moment(obj.BetDate, "DD-MM-YYYY").format("YYYY-MM-DD"))], ["desc"]));
		}
	};

	const GetDataChannels = async () => {
		const { data } = await getChannels();

		if (data) {
			const newData = reduce(
				data,
				(res: any, item) => {
					res[item.Id] = item;
					return res;
				},
				[]
			);
			setDataChannels(newData);
		}
	};

	const getDataBetTypes = async () => {
		const { data } = await getBetTypes();

		if (data) {
			const newData = reduce(
				data,
				(res: any, item) => {
					res[item.Id] = item;
					return res;
				},
				[]
			);
			setDataBetTypes(newData);
		}
	};

	useEffect(() => {
		GetTickets();
		GetDataChannels();
		getDataBetTypes();
	}, []);

	return (
		<IonPage className="app-bet-history">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="sic-title-page">
					<span> Lịch Sử Nuôi Xổ Số </span>
					<hr />
				</div>
			</div>
			<IonContent className="ion-content ion-content-segment agency-form ios overscroll hydrated">
				<IonList className="sic-bet-history ios list-ios">
					{dataTickets.map((item: any, index: any) => {
						const ChannelName = get(dataChannels, `[${item?.ChannelId}].Name`, "");
						const Channel2Name = get(dataChannels, `[${item?.ChannelId2}].Name`, "");
						const BetTypeName = get(dataBetTypes, `[${item?.BetTypeId}].Name`, "");
						return (
							<IonItem
								key={index}
								className="bet-history__main--item item-lines-none"
								lines="none"
								onClick={() => setModalTicket(defaultsDeep({ ChannelName, BetTypeName, Channel2Name }, item))}
							>
								<div className="item-bet-history">
									<section className="bet-history-section flex-1">
										<div className="bet-history--channel">
											{ChannelName} - {BetTypeName}
										</div>
										<div className="bet-history--time">Lượt xổ ngày {item?.BetDate}</div>
									</section>
									<section className="bet-history-section">
										<div className="bet-history--amount">
											{formatMoney(item?.BetAmount)} Điểm [
											<span className={`${item?.BetStatus === "Win" ? "color-trung" : "color-truot"}`}>{get(BET_STATUS, `${item?.BetStatus}`, "")}</span>]
											<div className="bet-history--status">{moment(item?.CreatedTime).format("DD-MM-YYYY HH:mm:ss")}</div>
										</div>
									</section>
								</div>
							</IonItem>
						);
					})}
				</IonList>
			</IonContent>
			<IonModal id="vibet88-modal" isOpen={modalTicket ? true : false} onIonModalDidDismiss={() => setModalTicket(null)}>
				<IonContent>z
					<IonToolbar>
						<IonTitle>CHI TIẾT VÉ CƯỢC XỔ SỐ</IonTitle>
						<IonButtons slot="end">
							<IonButton color="light" onClick={() => setModalTicket(null)}>
								<IonImg className="icon-close" src={`${IMG_MB}assets/icon/close-modal.png`} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<IonGrid className="ios ion-grid hydrated">
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Loại xổ số
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								{modalTicket?.ChannelName} {modalTicket?.BetTypeId === 44 ? ` - ${modalTicket?.Channel2Name}` : ``}
							</IonCol>
						</IonRow>
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Kiểu cược
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								{modalTicket?.BetTypeName}
							</IonCol>
						</IonRow>
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Mã vé cược
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								{modalTicket?.Id}
							</IonCol>
						</IonRow>
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Thời gian cược
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								{moment(modalTicket?.CreatedTime).format("DD-MM-YYYY HH:mm:ss")}
							</IonCol>
						</IonRow>
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Số đặt cược
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								{modalTicket?.BetNumbers}
							</IonCol>
						</IonRow>
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Số tiền cược
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								{formatMoney(modalTicket?.BetAmount)}
							</IonCol>
						</IonRow>
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Lượt xổ
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								{modalTicket?.BetDate}
							</IonCol>
						</IonRow>
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Tỉ lệ chiết khấu
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								{includes([37, 38, 39, 40, 41, 42], get(modalTicket, "ChannelId"))
									? `0`
									: includes([1, 44], get(modalTicket, "ChannelId"))
									? toNumber(get(modalTicket, "PercentMB"))
									: toNumber(get(modalTicket, "Percent"))}
								%
							</IonCol>
						</IonRow>
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Nuôi số
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								{modalTicket?.stopWon ? "Có" : "Không"}
							</IonCol>
						</IonRow>
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Mã nuôi
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								{modalTicket?.stopWon}
							</IonCol>
						</IonRow>
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Kết quả
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								[
								<span className={`${modalTicket?.BetStatus === "Win" ? "color-trung" : "color-truot"}`}>
									{get(BET_STATUS, `${modalTicket?.BetStatus}`, "")}{" "}
								</span>
								]
							</IonCol>
						</IonRow>
						<IonRow className="ios ion-row hydrated">
							<IonCol className="title ios hydrated" size="5">
								Lợi nhuận
							</IonCol>
							<IonCol size="7" className="ios hydrated">
								{formatMoney(modalTicket?.WinLose)}
							</IonCol>
						</IonRow>
					</IonGrid>
				</IonContent>
			</IonModal>
		</IonPage>
	);
};

export default KeepBetHistory;
