import React, { useEffect, useState } from "react";
import * as auth from "../redux/auth/authRedux";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

const LogoutTimer: React.FC = () => {
	const [logoutTimeTemp, setLogoutTimeTemp] = useState(0);
	const { logoutTime } = useSelector(
		(state) => ({
			logoutTime: state.auth.logoutTime,
		}),
		shallowEqual
	);

	const dispatch = useDispatch();
	useEffect(() => {
		if (Date.now() >= logoutTime) {
			dispatch(auth.actions.logout());
			window.location.href = "/home?login=true";
		}

		const timer = setInterval(() => {
			if (Date.now() >= logoutTime) {
				clearInterval(timer);
				dispatch(auth.actions.logout());
				window.location.href = "/home?login=true";
			}

			if (Date.now() < logoutTime && logoutTimeTemp > logoutTime) {
				dispatch(auth.actions.updateLogoutTime(logoutTimeTemp));
			}
		}, 60 * 1000); // Check every second for simplicity, adjust as needed

		return () => clearInterval(timer);
		// eslint-disable-next-line
	}, [logoutTime, dispatch, logoutTimeTemp]);

	// Reset logout time on user activity
	const handleActivity = () => {
		setLogoutTimeTemp(Date.now() + 240 * 60 * 1000);
	};

	useEffect(() => {
		const handleActivityEvents = ["mousemove", "keypress", "touchmove"];

		const handleActivityEvent = () => {
			handleActivity();
		};

		handleActivityEvents.forEach((event) => {
			document.addEventListener(event, handleActivityEvent);
		});

		return () => {
			handleActivityEvents.forEach((event) => {
				document.removeEventListener(event, handleActivityEvent);
			});
		};
		// eslint-disable-next-line
	}, []);

	return null;
};

export default LogoutTimer;
