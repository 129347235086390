import React, { useState, useEffect, useLayoutEffect } from "react";
import { IonContent, IonPage, IonButton, IonIcon, IonImg } from "@ionic/react";
import { getUserVipInfo } from "../redux/auth/authCrud";
import { useHistory } from "react-router-dom";
import { formatMoney } from "../util";
import { toNumber } from "lodash";
import { useSelector, shallowEqual } from "react-redux";
import { arrowBack } from "ionicons/icons";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./VipAccount.css";
import { IMG_MB } from "../constants";

const VipAccount: React.FC = () => {
	const history = useHistory();
	const { authToken } = useSelector(
		(state: any) => ({
			authToken: state.auth.authToken,
		}),
		shallowEqual
	);

	const [dataVipInfo, setDataVipInfo] = useState<any>([]);

	const GetUserVipInfo = async () => {
		const res = await getUserVipInfo();
		if (res && res.data) {
			setDataVipInfo(res.data);
		}
	};

	useEffect(() => {
		GetUserVipInfo();
	}, []);

	useLayoutEffect(() => {
		if (!authToken) {
			window.location.href = "/home?login=true";
		}
	}, [authToken, history]);

	if (!authToken) return <IonImg className="page-loading" src={`${IMG_MB}assets/v2/loading.webp`} />;

	return (
		<IonPage className="app-agency-balance">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group mb-7">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/account")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Thông Tin VIP
					</IonButton>
				</div>
				<div className="sic-title-vip-info">
					<span className="title"> Cấp độ VIP hiện tại của bạn </span>
					<div className="text-vip"> {dataVipInfo?.VipLevel} </div>
				</div>
			</div>
			<IonContent className="ion-content ion-content-segment agency-form ios overscroll hydrated">
				<div className="sic-title-page">
					<span> Quyền lợi VIP {dataVipInfo?.VipLevel} </span>
					<hr />
				</div>
				<div className="box-info-vip">
					<div className="row-vip mb-7">
						<span className="title">Hỗ trợ nhanh siêu VIP</span>
						<div className="text">{dataVipInfo?.isVipSupport ? "Có" : "Không"}</div>
					</div>
					<div className="row-vip mb-7">
						<span className="title">Nạp / Rút tiền siêu nhanh</span>
						<div className="text">{dataVipInfo?.isFastDeposit ? "Có" : "Không"}</div>
					</div>
					<div className="row-vip mb-7">
						<span className="title">Mỗi lần rút tối đa</span>
						<div className="text">{`${formatMoney(dataVipInfo?.MaxWithdraw)} K`}</div>
					</div>
					<div className="row-vip mb-7">
						<span className="title">Số lần rút tiền trong ngày</span>
						<div className="text">{dataVipInfo?.WithdrawDaily || 0} </div>
					</div>
					<div className="row-vip mb-7">
						<span className="title">Thưởng cược xổ số</span>
						<div className="text">{`${formatMoney(dataVipInfo?.LotteryBonus)} K`}</div>
					</div>
					<div className="row-vip mb-7">
						<span className="title">Thưởng nâng cấp VIP</span>
						<div className="text">{`${formatMoney(dataVipInfo?.VipBonus)} K`}</div>
					</div>
					<div className="row-vip mb-7">
						<span className="title">Thưởng sinh nhật</span>
						<div className="text">{`${formatMoney(dataVipInfo?.BirthdayBonus)} K`}</div>
					</div>
				</div>
				{dataVipInfo?.VipLevel < 10 && (
					<>
						<div className="sic-title-page">
							<span> Điều kiện nâng cấp VIP {toNumber(dataVipInfo?.VipLevel) + 1} </span>
							<hr />
						</div>
						<div className="box-info-vip">
							<div className="row-vip mb-7">
								<span className="title">Tổng nạp (còn lại)</span>
								<div className="text">{`${formatMoney(dataVipInfo?.TotalDepositLeft)} K`}</div>
							</div>
							<div className="row-vip mb-7">
								<span className="title">Tổng cược (còn lại)</span>
								<div className="text">{`${formatMoney(dataVipInfo?.TotalBetLeft)} K`}</div>
							</div>
						</div>
					</>
				)}
			</IonContent>
		</IonPage>
	);
};

export default VipAccount;
