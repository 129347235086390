import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonTitle, IonToolbar, IonButtons, IonImg, IonModal, IonButton, IonGrid, useIonToast } from "@ionic/react";
import { getPromotions, applyPromotion } from "../redux/auth/authCrud";
import moment from "moment";
import HeaderHome from "../components/HeaderHome";
import { IMG_MB } from "../constants";
import { includes } from "lodash";

import "./AgencyBalance.css";

import { IMG_URL } from "../constants";

const Promotion: React.FC = () => {
	const [present] = useIonToast();

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const [dataPromotions, setDataPromotions] = useState<any>([]);
	const [tabIndex, setTabIndex] = useState("0");
	const [dataModalPromotion, setDataModalPromotion] = useState<any>(null);

	const GetPromotions = async () => {
		const res = await getPromotions();
		if (res && res.data) {
			setDataPromotions(res.data);
		}
	};

	const _applyPromotion = async (promotionId: any) => {
		const res = await applyPromotion(promotionId);

		if (res?.data && res?.data?.error && res?.data?.error?.message) return presentToast("error", res?.data?.error?.message);

		setDataModalPromotion(null);
		presentToast("success", "Nhận khuyến mãi thành công!");
	};

	useEffect(() => {
		GetPromotions();
	}, []);

	return (
		<IonPage className="app-agency-balance">
			<div className="app-home homepage">
				<HeaderHome />
				<div className="page-promotions">
					<span className="title">KHUYẾN MÃI - VIBET88</span>
					<span className="sub-title">VIBET88 liên tục đưa ra các chương trình khuyến mãi hấp dẫn dành cho người chơi.</span>
				</div>
				<div className="btn-group btn-group-promotions">
					<IonButton className={`btn-sic tab-promotion ${tabIndex === "0" ? "active" : ""}`} size="small" onClick={(e) => setTabIndex("0")}>
						TẤT CẢ
					</IonButton>
					<IonButton className={`btn-sic tab-promotion ${tabIndex === "1" ? "active" : ""}`} size="small" onClick={(e) => setTabIndex("1")}>
						VIP
					</IonButton>
					<IonButton className={`btn-sic tab-promotion ${tabIndex === "2" ? "active" : ""}`} size="small" onClick={(e) => setTabIndex("2")}>
						THÀNH VIÊN MỚI
					</IonButton>
					<IonButton className={`btn-sic tab-promotion ${tabIndex === "3" ? "active" : ""}`} size="small" onClick={(e) => setTabIndex("3")}>
						CASINO LIVE
					</IonButton>
					<IonButton className={`btn-sic tab-promotion ${tabIndex === "4" ? "active" : ""}`} size="small" onClick={(e) => setTabIndex("4")}>
						THỂ THAO
					</IonButton>
					<IonButton className={`btn-sic tab-promotion ${tabIndex === "5" ? "active" : ""}`} size="small" onClick={(e) => setTabIndex("5")}>
						XỔ SỐ
					</IonButton>
					<IonButton className={`btn-sic tab-promotion ${tabIndex === "6" ? "active" : ""}`} size="small" onClick={(e) => setTabIndex("6")}>
						GAME BÀI
					</IonButton>
					<IonButton className={`btn-sic tab-promotion ${tabIndex === "7" ? "active" : ""}`} size="small" onClick={(e) => setTabIndex("7")}>
						DÂN GIAN
					</IonButton>
					<IonButton className={`btn-sic tab-promotion ${tabIndex === "8" ? "active" : ""}`} size="small" onClick={(e) => setTabIndex("8")}>
						NỔ HŨ
					</IonButton>
				</div>
			</div>
			<IonContent className="ion-content ion-content-segment agency-form ios overscroll hydrated">
				<div className="flex flex-col gap-10 promotion-ion-grid">
					{includes(["0"], tabIndex) &&
						dataPromotions.map((item: any, index: any) => {
							if (!item.isActive) return <React.Fragment key={index}></React.Fragment>;
							return <IonImg className="promotion-ion-row" key={index} src={`${IMG_URL}${item?.BannerMobile}`} onClick={() => setDataModalPromotion(item)} />;
						})}
				</div>
				<div className="page-promotions">
					<span className="sub-title">
						VIBET88 luôn đồng hành cùng người chơi, hỗ trợ người chơi tốt nhất cùng các chương trình khuyến mãi được cập nhập liên tục. Đừng bỏ qua các chương
						trình mới nhất tại VIBET88, chúng tôi luôn cam kết cùng bạn tìm kiếm chiến thắng hàng ngày.
					</span>
				</div>
			</IonContent>
			<IonModal id="vibet88-modal" className="h-70" isOpen={dataModalPromotion ? true : false} onIonModalDidDismiss={() => setDataModalPromotion(null)}>
				<IonContent className="modal-promotion-content">
					<div className="header">
						<IonToolbar>
							<IonTitle>{dataModalPromotion?.Name}</IonTitle>
							<IonButtons slot="end">
								<IonButton color="light" onClick={() => setDataModalPromotion(null)}>
									<IonImg className="icon-close" src={`${IMG_MB}assets/icon/close-modal.png`} />
								</IonButton>
							</IonButtons>
						</IonToolbar>
						<div className="flex flex-col gab-10 promotion-header">
							<span>
								Áp dụng từ: <span className="date">{moment(dataModalPromotion?.DateStart).format("DD-MM-YYYY")}</span> đến{" "}
								<span className="date">{moment(dataModalPromotion?.DateEnd).format("DD-MM-YYYY")}</span>
							</span>
							{dataModalPromotion?.ShowRegisterBtn && (
								<IonButton
									expand="block"
									className="ios button button-block button-solid ion-activatable ion-focusable hydrated btn-naptien"
									onClick={() => _applyPromotion(dataModalPromotion?.Id)}
								>
									ĐĂNG KÍ NGAY
								</IonButton>
							)}
						</div>
					</div>
					<IonGrid className="ios ion-grid hydrated">
						<div className="flex flex-col gab-10 py-10">
							<p
								className="ck-content"
								dangerouslySetInnerHTML={{
									__html: dataModalPromotion?.NoteMobile,
								}}
							></p>
						</div>
					</IonGrid>
				</IonContent>
			</IonModal>
		</IonPage>
	);
};

export default Promotion;
