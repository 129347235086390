import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonIcon, IonButton, IonList, useIonViewDidEnter } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useSelector, shallowEqual } from "react-redux";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import moment from "moment";
import { getRewardPointLog } from "../redux/auth/authCrud";
import { includes } from "lodash";
import "./RewardPoints.css";
import { formatMoney } from "../util";

const INIT_REWARD_POINT_LOG = {
	columnSearch: "",
	keySearch: "",
	filters: "",
	columnSort: "",
	sortType: "",
	filterColumns: ["TypeLog"],
	filterValues: ["Hoàn trả cược%"],
	startTime: "2024-06-01 00:00:00",
	endTime: moment().format("YYYY-MM-DD 23:59:59"),
	currentPage: 1,
	recordPerPage: 1000,
};

const HistoryAddPoints: React.FC = () => {
	const history = useHistory();
	const [dataRewardPointLog, setDataRewardPointLog] = useState<any>(null);

	const GetRewardPointLog = async () => {
		const res = await getRewardPointLog(INIT_REWARD_POINT_LOG);
		if (res && res.data) {
			setDataRewardPointLog(res.data);
		}
	};

	useIonViewDidEnter(() => {
		GetRewardPointLog();
	});

	const { userInfo } = useSelector(
		(state: any) => ({
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/reward-points")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Lịch Sử Cộng Điểm
					</IonButton>
				</div>
			</div>
			<IonContent className="app-agency app-rewards-point">
				<div className="sic-title-vip-info">
					<div className="text-vip">Bạn đang có</div>
					<span className="title">{formatMoney(userInfo?.RewardPoint)} Điểm</span>
				</div>
				<IonList className="vibet88-bet-history p-4px">
					{dataRewardPointLog?.data &&
						dataRewardPointLog?.data.map((item: any, index: number) => {
							return (
								<div className="history-ion-item" key={index}>
									<div className="history-item">
										<div className="history-item-row">
											<div className="title">
												<span className="date font-semibold text-14px">{moment(item?.CreatedTime).format("DD/MM/YYYY")}</span>
												<span className="num text-14px">{`${item?.TypeLog}`.replace("Hoàn trả cược ", "")}</span>
											</div>
											<div className="title">
												<span className="date text-2A5B84 text-14px">Còn lại</span>
												<span className="num text-FF0000 font-semibold text-14px">{formatMoney(item?.AfterPoint)}</span>
											</div>
										</div>
										<div className="history-item-row-bottom">
											<div className="left">
												<span className="title font-semibold text-14px">Tiền cược:</span>
												<span className="num text-14px">{formatMoney(item?.BetAmount)}</span>
											</div>
											<div className="right flex-1 flex gap-2 items-end justify-content-end">
												<span className="title font-semibold text-14px">Cộng điểm:</span>
												<span className="num text-14px">{formatMoney(item?.Point)}</span>
											</div>
										</div>
										{!includes(item?.Note, "Cập nhật hoàn trả vé cược") && (
											<div className="history-item-row-bottom">
												<div className="left">
													<span className="title font-semibold text-14px">Ghi chú:</span>
													<span className="num text-14px">{item?.Note}</span>
												</div>
											</div>
										)}
									</div>
								</div>
							);
						})}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default HistoryAddPoints;
