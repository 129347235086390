import React, { useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonImg, IonButton, IonIcon, IonGrid, IonCol, IonRow, IonItem, IonList, IonPopover, useIonViewDidEnter } from "@ionic/react";
import { IMG_MB, LOTTERY_CENTRAL, LOTTERY_SOUTH } from "../constants";
import { getDayOfWeek } from "../util";
import { caretDown } from "ionicons/icons";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";

import SlideHome from "../components/SlideHome";
import HeaderHome from "../components/HeaderHome";
import MenuGames from "../components/MenuGames";

import "./Lottery.css";

const dayLotteryCentral: any = moment().format("YYYY-MM-DD");
const dayLotterySouth: any = moment().format("YYYY-MM-DD");

const Lottery: React.FC = () => {
	const history = useHistory();
	const [dayCentral, setDayCentral] = useState(getDayOfWeek(dayLotteryCentral));
	const [daySouth, setDaySouth] = useState(getDayOfWeek(dayLotterySouth));

	const _getDayLottery = (date: any, time: any) => {
		const countDownDate = moment(`${date} ${time}`).valueOf();
		const now = new Date().getTime();
		const distance = countDownDate - now;
		return distance > 0 ? getDayOfWeek(date) : getDayOfWeek(moment().add(1, "days").format("YYYY-MM-DD"));
	};

	const { authToken } = useSelector(
		(state: any) => ({
			authToken: state.auth.authToken,
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	useLayoutEffect(() => {
		if (!authToken) {
			window.location.href = "/home?login=true";
		}
	}, [authToken, history]);

	useIonViewDidEnter(() => {
		if (authToken) {
			const newDayCentral = _getDayLottery(dayLotteryCentral, "17:10:00");
			setDayCentral(newDayCentral);

			const newDaySouth = _getDayLottery(dayLotterySouth, "16:10:00");
			setDaySouth(newDaySouth);
		}
	});

	if (!authToken) return <IonImg className="page-loading" src={`${IMG_MB}assets/v2/loading.webp`} />;

	return (
		<IonPage className="app-home">
			<HeaderHome />
			<IonContent fullscreen className="homepage">
				<div className="home-main">
					<SlideHome />
					<MenuGames />
					<div className="lottery-category">
						<IonGrid className="app-lottery pt-0 list-lt bg-white">
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div className="flex">
											<span> Xổ Số Nhanh </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonCol size="4" onClick={() => history.push("/lottery-play/mn1p")}>
									<IonImg className="ho-tro w-82" src={`assets/icon-lottery/v3/1p.png`} />
								</IonCol>
								<IonCol size="4" onClick={() => history.push("/lottery-play/mn2p")}>
									<IonImg className="ho-tro w-82" src={`assets/icon-lottery/v3/2p.png`} />
								</IonCol>
								<IonCol size="4" onClick={() => history.push("/lottery-play/mn5p")}>
									<IonImg className="ho-tro w-82" src={`assets/icon-lottery/v3/5p.png`} />
								</IonCol>
								<IonCol size="4" onClick={() => history.push("/lottery-play/mb1p")}>
									<IonImg className="ho-tro w-82" src={`assets/icon-lottery/v2/mienbac1p.png`} />
								</IonCol>
								<IonCol size="4" onClick={() => history.push("/lottery-play/mb2p")}>
									<IonImg className="ho-tro w-82" src={`assets/icon-lottery/v2/mienbac2p.png`} />
								</IonCol>
								<IonCol size="4" onClick={() => history.push("/lottery-play/mb5p")}>
									<IonImg className="ho-tro w-82" src={`assets/icon-lottery/v2/mienbac5p.png`} />
								</IonCol>
							</IonRow>
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Miền Bắc </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonCol size="4" onClick={() => history.push("/lottery-play/mienbac")}>
									<IonImg className="ho-tro w-82" src={`${IMG_MB}assets/icon-lottery/v1/mienbac.png`} />
								</IonCol>
								<IonCol size="4" onClick={() => history.push("/lottery-play/mienbac18h25")}>
									<IonImg className="ho-tro w-82" src={`${IMG_MB}assets/icon-lottery/v1/18h25.png`} />
								</IonCol>
							</IonRow>
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Miền Trung </span>
											<hr />
										</div>
										<div className="select-date ng-star-inserted">
											<IonButton id="popover-button-mientrung" mode="md" className="md button button-solid ion-activatable ion-focusable hydrated activated">
												<span>{dayCentral}</span>
												<IonIcon icon={caretDown} slot="end" className="md hydrated" />
											</IonButton>
											<IonPopover className="popover-lottery-channel" trigger="popover-button-mientrung" dismissOnSelect={true} side="top" alignment="start">
												<IonContent>
													<IonList>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDayCentral("Tất Cả");
															}}
														>
															Tất Cả
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDayCentral("Thứ Hai");
															}}
														>
															Thứ Hai
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDayCentral("Thứ Ba");
															}}
														>
															Thứ Ba
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDayCentral("Thứ Tư");
															}}
														>
															Thứ Tư
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDayCentral("Thứ Năm");
															}}
														>
															Thứ Năm
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDayCentral("Thứ Sáu");
															}}
														>
															Thứ Sáu
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDayCentral("Thứ Bảy");
															}}
														>
															Thứ Bảy
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDayCentral("Chủ Nhật");
															}}
														>
															Chủ Nhật
														</IonItem>
													</IonList>
												</IonContent>
											</IonPopover>
										</div>
									</div>
								</IonCol>
								{LOTTERY_CENTRAL &&
									dayCentral &&
									LOTTERY_CENTRAL[dayCentral] &&
									LOTTERY_CENTRAL[dayCentral].map((item: any, index: any) => {
										return (
											<IonCol key={index} size="4" onClick={() => history.push(`/lottery-play/${item}`)}>
												<IonImg className="ho-tro w-82" src={`${IMG_MB}assets/icon-lottery/v1/${item}.png`} />
											</IonCol>
										);
									})}
							</IonRow>
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Miền Nam </span>
											<hr />
										</div>
										<div className="select-date ng-star-inserted">
											<IonButton id="popover-button-miennam" mode="md" className="md button button-solid ion-activatable ion-focusable hydrated activated">
												<span>{daySouth}</span>
												<IonIcon icon={caretDown} slot="end" className="md hydrated" />
											</IonButton>
											<IonPopover className="popover-lottery-channel" trigger="popover-button-miennam" dismissOnSelect={true} side="top" alignment="start">
												<IonContent>
													<IonList>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDaySouth("Tất Cả");
															}}
														>
															Tất Cả
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDaySouth("Thứ Hai");
															}}
														>
															Thứ Hai
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDaySouth("Thứ Ba");
															}}
														>
															Thứ Ba
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDaySouth("Thứ Tư");
															}}
														>
															Thứ Tư
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDaySouth("Thứ Năm");
															}}
														>
															Thứ Năm
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDaySouth("Thứ Sáu");
															}}
														>
															Thứ Sáu
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDaySouth("Thứ Bảy");
															}}
														>
															Thứ Bảy
														</IonItem>
														<IonItem
															button={true}
															detail={false}
															onClick={(e) => {
																e.preventDefault();
																setDaySouth("Chủ Nhật");
															}}
														>
															Chủ Nhật
														</IonItem>
													</IonList>
												</IonContent>
											</IonPopover>
										</div>
									</div>
								</IonCol>
								{LOTTERY_SOUTH &&
									daySouth &&
									LOTTERY_SOUTH[daySouth] &&
									LOTTERY_SOUTH[daySouth].map((item: any, index: any) => {
										return (
											<IonCol key={index} size="4" onClick={() => history.push(`/lottery-play/${item}`)}>
												<IonImg className="ho-tro w-82" src={`${IMG_MB}assets/icon-lottery/v1/${item}.png`} />
											</IonCol>
										);
									})}
							</IonRow>
						</IonGrid>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default Lottery;
