import { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { IonContent, IonPage, useIonAlert, IonImg } from "@ionic/react";
import * as auth from "./authRedux";
import * as lottery from "../lottery/lotteryRedux";
import { getUserInfo, getSettings } from "./authCrud";
import { get, find } from "lodash";
import moment from "moment";
import { HubConnectionBuilder, HttpTransportType } from "@microsoft/signalr";
import { API_URL, IMG_MB } from "../../constants";
import LogoutTimer from "../../components/LogoutTimer";

var isSignalr = false;

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const hoursRef = useRef(null);
  const minutesRef = useRef(null);
  const secondsRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isMaintenance, setIsMaintenance] = useState(false);
  const [presentAlert] = useIonAlert();
  const hubConnection = new HubConnectionBuilder()
    .withUrl(`${API_URL}chathub`, {
      skipNegotiation: false,
      transport: HttpTransportType.ServerSentEvents,
    })
    .withAutomaticReconnect([0, 2000, 10000, 30000])
    .build();

  const hubConnection2 = new HubConnectionBuilder()
    .withUrl(`https://callback.vibet88.fun/chathub`, {
      skipNegotiation: false,
      transport: HttpTransportType.ServerSentEvents,
    })
    .withAutomaticReconnect([0, 2000, 10000, 30000])
    .build();

  const { authToken, userInfo, deviceId } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
      userInfo: auth.user,
      deviceId: auth.deviceId,
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {
    let timeInterval;
    if (userInfo && !isMaintenance) {
      const countdown = get(userInfo, "Countdown");
      const countDownDate = moment(countdown, "YYYY-MM-DD HH:mm:ss").valueOf();
      let now = new Date().getTime();
      if (countdown && countDownDate - now > 0) {
        setIsMaintenance(true);
        timeInterval = setInterval(async () => {
          now = new Date().getTime();
          const distance = countDownDate - now;
          if (distance > 0) {
            const hours = Math.floor(distance / (1000 * 60 * 60))
              .toString()
              .padStart(2, "0");
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
              .toString()
              .padStart(2, "0");
            const seconds = Math.floor((distance % (1000 * 60)) / 1000)
              .toString()
              .padStart(2, "0");

            if (hoursRef.current && minutesRef.current && secondsRef) {
              hoursRef.current.innerHTML = hours;
              minutesRef.current.innerHTML = minutes;
              secondsRef.current.innerHTML = seconds;
            }
          } else {
            clearInterval(timeInterval);
            if (hoursRef.current && minutesRef.current && secondsRef) {
              hoursRef.current.innerHTML = `00`;
              minutesRef.current.innerHTML = `00`;
              secondsRef.current.innerHTML = `00`;
            }
            setIsMaintenance(false);
          }
        }, 1000);
      }
    }

    return () => {
      if (timeInterval) {
        clearInterval(timeInterval);
      }
    };

    // eslint-disable-next-line
  }, [userInfo]);

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          getUserInfo().then((resUser) => {
            dispatch(props.fulfillUser(get(resUser, "data", null)));
          });
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout());
        }
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      requestUser();
    } else {
      dispatch(props.fulfillUser(undefined));
      if (isSignalr) {
        isSignalr = false;
        hubConnection.stop();
        hubConnection2.stop();
      }
    }

    let timeInterval;

    const requestSettings = async () => {
      const resSettings = await getSettings();
      setIsLoading(false);
      const settings = get(resSettings, "data", []);

      const countdownNote = find(settings || [], ["Name", "countdownNote"]);
      const countdown = find(settings || [], ["Name", "countdown"]);
      const countDownDate = moment(countdown?.Value, "YYYY-MM-DD HH:mm:ss").valueOf();
      let now = new Date().getTime();

      if (countdown && countdown.Value && countDownDate - now > 0) {
        setIsMaintenance(true);
        timeInterval = setInterval(async () => {
          now = new Date().getTime();
          const distance = countDownDate - now;
          if (distance > 0) {
            const hours = Math.floor(distance / (1000 * 60 * 60))
              .toString()
              .padStart(2, "0");
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
              .toString()
              .padStart(2, "0");
            const seconds = Math.floor((distance % (1000 * 60)) / 1000)
              .toString()
              .padStart(2, "0");

            if (hoursRef.current && minutesRef.current && secondsRef) {
              hoursRef.current.innerHTML = hours;
              minutesRef.current.innerHTML = minutes;
              secondsRef.current.innerHTML = seconds;
            }
          } else {
            clearInterval(timeInterval);
            if (hoursRef.current && minutesRef.current && secondsRef) {
              hoursRef.current.innerHTML = `00`;
              minutesRef.current.innerHTML = `00`;
              secondsRef.current.innerHTML = `00`;
            }
            setIsMaintenance(false);
          }
        }, 1000);
      }

      if (countdownNote && countdownNote.Value && countDownDate - now < 0) {
        presentAlert({
          header: "Thông Báo",
          message: countdownNote.Value,
          buttons: ["OK"],
          cssClass: "alert-custom",
        });
      }
    };

    requestSettings();

    return () => {
      if (timeInterval) {
        clearInterval(timeInterval);
      }
    };
    // eslint-disable-next-line
  }, []);

  const _connectionSignalr = (userId, currentId) => {
    if (hubConnection) {
      hubConnection.start().then(() => {
        hubConnection.invoke("AddToGroup", userId);
      });
      hubConnection.on("Send", (sendData) => {
        let { DeviceId } = JSON.parse(sendData);
        if (!didRequest.current && DeviceId !== currentId) {
          dispatch(props.logout());
          return presentAlert({
            header: "Thông Báo",
            message: "Tài khoản của bạn được đăng nhập ở một nơi khác!",
            buttons: [
              {
                text: "OK",
                role: "confirm",
                handler: () => {
                  window.location.href = "/home";
                },
              },
            ],
            cssClass: "alert-custom",
            backdropDismiss: false,
          });
        }
      });
      hubConnection.on("UpdateUserInfo", (sendData) => {
        let newInfo = JSON.parse(sendData);
        dispatch(props.fulfillUser(newInfo));
      });
      hubConnection.onreconnected(() => {
        hubConnection.invoke("AddToGroup", userId);
      });
      hubConnection.on("UpdateTicketResult", (sendData) => {
        const resultTickets = JSON.parse(sendData);
        dispatch(props.updateTicketResult(resultTickets));
      });
    }
    if (hubConnection2) {
      hubConnection2.start().then(() => {
        hubConnection2.invoke("AddToGroup", userId);
      });
      hubConnection2.on("UpdateUserInfo", (sendData) => {
        let newInfo = JSON.parse(sendData);
        dispatch(props.fulfillUser(newInfo));
      });
      hubConnection2.onreconnected(() => {
        hubConnection2.invoke("AddToGroup", userId);
      });
      hubConnection2.on("UpdateTicketResult", (sendData) => {
        const resultTickets = JSON.parse(sendData);
        dispatch(props.updateTicketResult(resultTickets));
      });
    }
  };

  useEffect(() => {
    if (deviceId && !isSignalr) {
      isSignalr = true;
      _connectionSignalr(userInfo.Id, deviceId);
    }
    // eslint-disable-next-line
  }, [deviceId]);

  if (isLoading) return <IonImg className="page-loading" src={`${IMG_MB}assets/v2/loading.webp`} />
  if (isMaintenance)
    return (
      <main className="main-page">
        <IonPage className="app-home open-noti">
          <IonContent fullscreen className="homepage">
            <div className="maintenance">
              <div className="maintenance_contain">
                <img src={`${IMG_MB}assets/maintenance/main-vector.png`} alt="maintenance" />
                <div className="pp-infobox-title-wrapper">
                  <h3 className="pp-infobox-title">Trang web đang được bảo trì!</h3>
                </div>
                <div className="pp-infobox-description">
                  <div className="fl-countdown">
                    <div className="fl-countdown-number fl-countdown-hours">
                      <div className="fl-countdown-unit">
                        <span className="fl-countdown-unit-number" ref={hoursRef}>
                          00
                        </span>
                        <div className="fl-countdown-unit-label">Giờ</div>
                      </div>
                    </div>
                    <div className="fl-countdown-number fl-countdown-minutes">
                      <div className="fl-countdown-unit">
                        <span className="fl-countdown-unit-number" ref={minutesRef}>
                          00
                        </span>
                        <div className="fl-countdown-unit-label">Phút</div>
                      </div>
                    </div>
                    <div className="fl-countdown-number fl-countdown-seconds">
                      <div className="fl-countdown-unit">
                        <span className="fl-countdown-unit-number" ref={secondsRef}>
                          00
                        </span>
                        <div className="fl-countdown-unit-label">Giây</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </IonContent>
        </IonPage>
      </main>
    );

  return <>
    {props.children}
    {userInfo && <LogoutTimer />}
  </>;
}

export default connect(null, { ...auth.actions, ...lottery.actions })(AuthInit);
