import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonImg, IonGrid, IonCol, IonRow, IonSlides, IonSlide } from "@ionic/react";
import { getPosts, getNews } from "../redux/auth/authCrud";
import HeaderHome from "../components/HeaderHome";
import SlideHome from "../components/SlideHome";
import MenuGames from "../components/MenuGames";
import NewsletterModal from "../components/Modal/NewsletterModal";
import { reduce } from "lodash";

import "./Home.css";
import { IMG_MB } from "../constants";

const Home: React.FC = () => {
	const history = useHistory();
	const [isShowModalPost, setIsShowModalPost] = useState<any>(null);
	const [dataPosts, setDataPosts] = useState([]);
	const [isIndexPage, setIsIndexPage] = useState(false);
	const [dataNotifications, setDataNotifications] = useState<any>(null);

	const GetNotifications = async () => {
		const res = await getNews();
		if (res && res.data) {
			const newData = reduce(
				res.data,
				(res: any, item: any) => {
					if (item?.AutoShowPopup && item?.isActive && item?.MobileImageUrl) {
						res.push(item?.MobileImageUrl);
					}

					return res;
				},
				[]
			);

			setDataNotifications(newData);
		}
	};

	const GetDataPosts = async () => {
		const { data } = await getPosts();

		if (data) {
			setDataPosts(data);
		}
	};

	useEffect(() => {
		GetNotifications();
		GetDataPosts();
		setIsIndexPage(window.location.pathname === "/home");
	}, []);

	return (
		<IonPage className="app-home open-noti">
			<HeaderHome />
			<IonContent fullscreen className="homepage">
				<div className="home-main">
					<SlideHome />
					<MenuGames />
					<div className="lottery-category">
						<IonGrid className="app-lottery pt-0 list-lt bg-white">
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div className="flex">
											<span> Xổ Số - Lô Đề </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonCol className="item-lottery-col" size="3" onClick={() => history.push("/lottery")}>
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/v3/icon-lottery-st.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3" onClick={() => history.push("/lottery")}>
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/v3/icon-lottery-mb.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3" onClick={() => history.push("/lottery")}>
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/v3/icon-lottery-mn.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3" onClick={() => history.push("/lottery")}>
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/v3/icon-lottery-mt.png`} />
								</IonCol>
							</IonRow>
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Thể Thao </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonCol className="item-lottery-col" size="4">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v3/saba.png`} onClick={() => history.push("/sport/saba")} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v3/afb.png`} onClick={() => history.push("/sport-2/afbsport")} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v3/cmd.png`} onClick={() => history.push("/sport-2/afbsport")} />
								</IonCol>
								{/* <IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/sbobet-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/cmd368-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
                  <IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/cmd368-off.png`} />
                </IonCol>
                <IonCol className="item-lottery-col" size="3">
                  <IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/maxbet-off.png`} />
                </IonCol> */}
							</IonRow>
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Casino Live </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonSlides
									className="w-full"
									options={{
										slidesPerView: 3,
										autoplay: {
											delay: 5000,
										},
									}}
								>
									<IonSlide className="item-lottery-col">
										<IonCol size="12">
											<IonImg
												className="item-lottery-col-icon w-full"
												src={`${IMG_MB}assets/home/games/evo-2.png`}
												onClick={() => history.push("/casino-live/evo")}
											/>
										</IonCol>
									</IonSlide>
									<IonSlide className="item-lottery-col">
										<IonCol size="12">
											<IonImg
												className="item-lottery-col-icon w-full"
												src={`${IMG_MB}assets/home/games/sexy-2.png`}
												onClick={() => history.push("/casino-live-1/sexy")}
											/>
										</IonCol>
									</IonSlide>
									<IonSlide className="item-lottery-col">
										<IonCol size="12">
											<IonImg
												className="item-lottery-col-icon w-full"
												src={`${IMG_MB}assets/home/games/wm-2.png`}
												onClick={() => history.push("/casino-live-2/wm")}
											/>
										</IonCol>
									</IonSlide>
									<IonSlide className="item-lottery-col">
										<IonCol size="12">
											<IonImg
												className="item-lottery-col-icon w-full"
												src={`${IMG_MB}assets/home/games/dg.png`}
												onClick={() => history.push("/casino-live-3/dg")}
											/>
										</IonCol>
									</IonSlide>
									<IonSlide className="item-lottery-col">
										<IonCol size="12">
											<IonImg className="item-lottery-col-icon w-full" src={`assets/home/games/sa2.png`} onClick={() => history.push("/casino-live-4/sa")} />
										</IonCol>
									</IonSlide>
								</IonSlides>
								{/* <IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/afb-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
                  <IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/ag-off.png`} />
                </IonCol>
                <IonCol className="item-lottery-col" size="3">
                  <IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/bbi-off.png`} />
                </IonCol>
                <IonCol className="item-lottery-col" size="3">
                  <IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/ae-off.png`} />
                </IonCol>
                <IonCol className="item-lottery-col" size="3">
                  <IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/ebet-off.png`} />
                </IonCol> */}
							</IonRow>
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Nổ Hũ - Bắn Cá </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonCol className="item-lottery-col" size="4">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/28.png`} onClick={() => history.push("/nohu-games")} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4">
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/33.png`} onClick={() => history.push("/nohu-games")} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/2.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/32.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/34.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="4" onClick={() => history.push("/nohu-games")}>
									<IonImg className="item-lottery-col-icon w-full" src={`${IMG_MB}assets/home/games/v2/36.png`} />
								</IonCol>
							</IonRow>
							{/* <IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Đánh Bài Online </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/tienlen-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/tala-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/maubinh-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/cotuong-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/samloc-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/cotyphu-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/cocangua-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/baucua-off.png`} />
								</IonCol>
							</IonRow> */}
							{/* <IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Game Dân Gian </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/taixiu-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/oantuti-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/xocdia-off.png`} />
								</IonCol>
								<IonCol className="item-lottery-col" size="3">
									<IonImg className="item-lottery-col-icon" src={`${IMG_MB}assets/home/games/baucuanohu-off.png`} />
								</IonCol>
							</IonRow> */}
							<IonRow className="item-lottery">
								<IonCol size="12" className="lt-title">
									<div className="title-big">
										<div>
											<span> Đá Gà </span>
											<hr />
										</div>
									</div>
								</IonCol>
								<IonCol className="item-lottery-col" size="6">
									<IonImg
										className="item-lottery-col-icon w-full"
										src={`${IMG_MB}assets/home/games/v2/odin.png`}
										onClick={() => history.push("/cock-fight/odin")}
									/>
								</IonCol>
							</IonRow>
						</IonGrid>
					</div>
				</div>
				<IonGrid className="sic-footer">
					<IonRow className="ion-row">
						<div className="ion-col" onClick={() => setIsShowModalPost(dataPosts[0])}>
							<span>Giới Thiệu</span>
						</div>
						<div className="ion-col w-16">|</div>
						<div className="ion-col" onClick={() => setIsShowModalPost(dataPosts[1])}>
							<span>Điều Khoản</span>
						</div>
						<div className="ion-col w-16">|</div>
						<div className="ion-col" onClick={() => setIsShowModalPost(dataPosts[3])}>
							<span>Bảo Mật</span>
						</div>
						<div className="ion-col w-16">|</div>
						<div className="ion-col" onClick={() => setIsShowModalPost(dataPosts[5])}>
							<span>Điểm Thưởng</span>
						</div>
						<div className="ion-col w-16">|</div>
						<div className="ion-col" onClick={() => setIsShowModalPost(dataPosts[4])}>
							<span>Đại Lý</span>
						</div>
						<div className="ion-col w-16">|</div>
						<div className="ion-col" onClick={() => setIsShowModalPost(dataPosts[2])}>
							<span>Liên Hệ</span>
						</div>
					</IonRow>
				</IonGrid>
				{isIndexPage && dataNotifications && dataNotifications?.length > 0 && <NewsletterModal DATA_KM={dataNotifications} />}
				{isShowModalPost && (
					<div className="showNoti ng-tns-c6-91 ng-star-inserted" id="showNoti">
						<div className="showNoti__wrapper">
							<span className="showNoti__wrapper__title">{isShowModalPost?.Title}</span>
							<IonImg className="btn-close ios hydrated" src={`${IMG_MB}assets/noti-detail/noti-close.svg`} onClick={() => setIsShowModalPost(null)}></IonImg>
							<div className="showNoti__wrapper__content">
								<div className="content">
									<div className="content__scroll">
										<p
											dangerouslySetInnerHTML={{
												__html: isShowModalPost?.DescriptionMobile,
											}}
										></p>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</IonContent>
		</IonPage>
	);
};

export default Home;
