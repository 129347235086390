import React, { useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
	IonContent,
	IonPage,
	IonTitle,
	IonToolbar,
	IonButtons,
	IonIcon,
	IonGrid,
	IonRow,
	IonCol,
	IonImg,
	IonButton,
	IonModal,
	useIonAlert,
	useIonToast,
	IonLabel,
	IonInput,
	IonCheckbox,
} from "@ionic/react";
import { get } from "lodash";
import { arrowBack } from "ionicons/icons";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { getRewards, applyReward, getUserInfo } from "../redux/auth/authCrud";
import * as auth from "../redux/auth/authRedux";

import "./RewardPoints.css";
import { IMG_MB } from "../constants";
import { formatMoney } from "../util";

const RewardsExchange: React.FC = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const [presentAlert] = useIonAlert();
	const [present] = useIonToast();

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};
	const [dataRewards, setDataRewards] = useState<any>(null);
	const [modelApplyReward, setModelApplyReward] = useState<any>(null);

	const { userInfo } = useSelector(
		(state: any) => ({
			userInfo: state.auth.user,
		}),
		shallowEqual
	);

	const GetRewards = async () => {
		const res = await getRewards();
		if (res && res.data) {
			setDataRewards(res.data);
		}
	};

	useLayoutEffect(() => {
		GetRewards();
	}, []);

	const ApplyReward = async () => {
		const params = {
			WithdrawPassword: modelApplyReward.WithdrawPassword,
			RewardType: modelApplyReward.RewardType,
			Note: modelApplyReward.Note,
			RewardId: modelApplyReward.RewardId,
		};

		if (!params.WithdrawPassword) return presentToast("error", "Nhập mã rút tiền.");
		if (params.RewardType === 2 && !params.Note) return presentToast("error", "Nhập ghi chú.");
		const res = await applyReward(params);
		setModelApplyReward(null);
		if (res?.data && res?.data?.error && res?.data?.error?.message) return presentToast("error", res?.data?.error?.message);
		presentAlert({
			header: "Thông Báo",
			message: res?.data?.Message,
			buttons: ["OK"],
			cssClass: "alert-custom",
		});
		getUserInfo().then((resUser) => {
			dispatch(auth.actions.fulfillUser(get(resUser, "data", null)));
		});
	};

	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/reward-points")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Đổi Thưởng
					</IonButton>
				</div>
			</div>
			<IonContent className="app-agency app-rewards-point">
				<div className="sic-title-vip-info">
					<div className="text-vip">Bạn đang có</div>
					<span className="title">{formatMoney(userInfo?.RewardPoint)} Điểm</span>
				</div>
				<div className="bg-F3F3F3 p-10 grid grid-cols-2 gap-10 m-2 rounded-5px">
					{dataRewards &&
						dataRewards?.map((item: any, index: number) => {
							return (
								<div className="shadow bg-FFFFFF border border-F5BC27 border-solid rounded-5px flex flex-col h-fit relative" key={index}>
									<img className="m-h-[250px] rounded-t-5px " src={`${IMG_MB}${item?.ImageUrl}`} alt="" />
									<div className="bg-D9D9D9 w-full rounded-b-5px border-t border-l-0 border-r-0 border-b-0 border-solid border-F5BC27 flex flex-col gap-5px items-center py-5">
										<span className="text-14px text-2A5B84 font-semibold text-center">{item?.RewardName}</span>
										<span className="text-14px text-black font-semibold">{formatMoney(item?.ConversionMoney)} K</span>
										<span className="text-14px text-FF0000 font-semibold">{formatMoney(item?.ConversionPoints)}</span>
										{item?.AvailableQuantity > 0 ? (
											<button
												className="bg-F5BC27 text-white px-3 py-1 rounded-md text-12px"
												onClick={() => setModelApplyReward({ ...item, RewardId: item?.Id, Note: "", RewardType: 1 })}
											>
												Đổi Thưởng
											</button>
										) : (
											<button className="bg-6D6D6D text-white px-3 py-1 rounded-md text-12px">Hết Hàng</button>
										)}
									</div>
									<button className="bg-F5BC27 text-FF0000 rounded-tl-md rounded-br-md text-10px absolute font-semibold left-0 py-1 px-2">
										{item?.RewardCode}
									</button>
									<button className="bg-F5BC27 text-2A5B84 min-w-40px rounded-tr-md rounded-bl-md text-10px absolute font-semibold right-0 py-1 px-2">
										{item?.AvailableQuantity}
									</button>
								</div>
							);
						})}
				</div>
			</IonContent>
			{modelApplyReward && (
				<IonModal id="vibet88-modal" className="h-70" isOpen={true} onIonModalDidDismiss={() => setModelApplyReward(null)}>
					<IonToolbar>
						<IonTitle>ĐỔI THƯỞNG</IonTitle>
						<IonButtons slot="end">
							<IonButton color="light" onClick={() => setModelApplyReward(null)}>
								<IonImg className="icon-close" src={`${IMG_MB}assets/icon/close-modal.png`} />
							</IonButton>
						</IonButtons>
					</IonToolbar>
					<IonContent className="app-rewards-point">
						<IonGrid className="ios agency-ion-grid">
							<IonRow className="ion-row border-1px-F5BC27 rounded-md">
								<IonCol size="12">
									<IonImg className="m-h-[250px]" src={`${IMG_MB}${modelApplyReward?.ImageUrl}`} />
								</IonCol>
								<IonCol size="12" className="p-0 border-t-F5BC27">
									<div className="agency-ion-col border-b-dashed-F5BC27 flex gap-10">
										<IonLabel className="flex-1 font-semibold border-r-F5BC27 sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated p-4px">
											Tên phần thưởng
										</IonLabel>
										<IonLabel className="title font-semibold text-FF0000 flex-1">{modelApplyReward?.RewardName}</IonLabel>
									</div>
								</IonCol>
								<IonCol size="12" className="p-0">
									<div className="agency-ion-col border-b-dashed-F5BC27 flex gap-10">
										<IonLabel className="flex-1 font-semibold border-r-F5BC27 sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated p-4px">
											Mã phần thưởng
										</IonLabel>
										<IonLabel className="title font-semibold text-FF0000 flex-1">{modelApplyReward?.RewardCode}</IonLabel>
									</div>
								</IonCol>
								<IonCol size="12" className="p-0">
									<div className="agency-ion-col border-b-dashed-F5BC27 flex gap-10">
										<IonLabel className="flex-1 font-semibold border-r-F5BC27 sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated p-4px">Điểm quy đổi</IonLabel>
										<IonLabel className="title font-semibold text-FF0000 flex-1">{formatMoney(modelApplyReward?.ConversionPoints)}</IonLabel>
									</div>
								</IonCol>
								<IonCol size="12" className="p-0">
									<div className="agency-ion-col border-b-dashed-F5BC27 flex gap-10">
										<IonLabel className="flex-1 font-semibold border-r-F5BC27 sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated p-4px">
											Quy đổi thành tiền
										</IonLabel>
										<IonLabel className="title font-semibold text-FF0000 flex-1">{formatMoney(modelApplyReward?.ConversionMoney)}</IonLabel>
									</div>
								</IonCol>
								<IonCol size="12" className="p-0">
									<div className="agency-ion-col border-b-dashed-F5BC27 flex gap-10">
										<IonLabel className="flex-1 font-semibold border-r-F5BC27 sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated p-4px">
											Số lượng còn lại
										</IonLabel>
										<IonLabel className="title font-semibold text-FF0000 flex-1">{formatMoney(modelApplyReward?.AvailableQuantity)}</IonLabel>
									</div>
								</IonCol>
								<IonCol size="12" className="p-0">
									<div className="agency-ion-col border-b-dashed-F5BC27 flex gap-10">
										<IonLabel className="flex-1 m-h-50px flex font-semibold border-r-F5BC27 sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated p-4px">
											Lựa chọn
										</IonLabel>
										<div className="flex-1 flex flex-col align-items-start gap-5px">
											<div
												className="login__remember flex flex-align gap-5px"
												onClick={(e) => setModelApplyReward({ ...modelApplyReward, RewardType: 2, Note: "" })}
											>
												<IonCheckbox
													checked={modelApplyReward?.RewardType === 2}
													color="primary"
													mode="md"
													name="laysanpham"
													className="ion-color ion-color-success md interactive hydrated ion-untouched ion-pristine ion-valid"
													role="checkbox"
													aria-labelledby="ion-laysanpham"
												/>
												<span className="login__text--opts login__text--red login__text--bold">Lấy sản phẩm</span>
											</div>
											<div
												className="login__remember flex flex-align gap-5px"
												onClick={(e) => setModelApplyReward({ ...modelApplyReward, RewardType: 1, Note: "" })}
											>
												<IonCheckbox
													checked={modelApplyReward?.RewardType === 1}
													color="primary"
													mode="md"
													name="doithanhtien"
													className="ion-color ion-color-success md interactive hydrated ion-untouched ion-pristine ion-valid"
													role="checkbox"
													aria-labelledby="ion-doithanhtien"
												/>
												<span className="login__text--opts login__text--red login__text--bold">Đổi thành tiền</span>
											</div>
										</div>
									</div>
								</IonCol>
								{modelApplyReward?.RewardType === 2 && (
									<IonCol size="12" className="p-0">
										<div className="agency-ion-col flex gap-10 border-b-dashed-F5BC27">
											<IonLabel className="m-h-40px flex flex-1 border-r-F5BC27 font-semibold sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated p-4px">
												Địa Chỉ
											</IonLabel>
											<div className="flex-1 ">
												<IonInput
													type="text"
													value={modelApplyReward?.Note}
													onIonChange={(e) => setModelApplyReward({ ...modelApplyReward, Note: e.detail.value })}
													placeholder="Điền chính xác địa chỉ của bạn ..."
													className="w-95 sic-ion-input color-trung  agency-ion-input ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
												></IonInput>
											</div>
										</div>
									</IonCol>
								)}
								<IonCol size="12" className="p-0">
									<div className="agency-ion-col gap-10 flex border-b-dashed-F5BC27">
										<IonLabel className="m-h-40px flex flex-1 border-r-F5BC27 font-semibold sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated p-4px">
											Mã rút tiền
										</IonLabel>
										<div className="flex-1">
											<IonInput
												placeholder="Nhập mã rút tiền của bạn ..."
												type="password"
												value={modelApplyReward?.WithdrawPassword}
												onIonChange={(e) => setModelApplyReward({ ...modelApplyReward, WithdrawPassword: e.detail.value })}
												className="w-95 sic-ion-input color-trung agency-ion-input ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
											></IonInput>
										</div>
									</div>
								</IonCol>
								<IonCol size="12">
									<div className="agency-ion-col flex gap-10 ion-justify-content-center">
										<IonButton
											expand="block"
											className="ios text-white button button-block button-solid ion-activatable ion-focusable hydrated btn-naptien"
											onClick={() => ApplyReward()}
										>
											Xác Nhận Đổi Thưởng
										</IonButton>
									</div>
								</IonCol>
							</IonRow>
						</IonGrid>
					</IonContent>
				</IonModal>
			)}
		</IonPage>
	);
};

export default RewardsExchange;
