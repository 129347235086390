import React, { useEffect, useState } from "react";
import { IonContent, IonPage, IonList, IonItem, IonGrid, IonRow, IonCol, IonButton, useIonAlert, IonIcon } from "@ionic/react";
import "./LotteryList.css";
import { RouteComponentProps } from "react-router-dom";
import { getResult } from "../redux/auth/authCrud";
import { forEach, get, join, set, split } from "lodash";
import { arrowBack } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import { LOTTERY_DISPLAY_NAME } from "../constants";

interface LotteryListPageProps
	extends RouteComponentProps<{
		codeName: string;
	}> {}

const LotteryList: React.FC<LotteryListPageProps> = ({ match }) => {
	const history = useHistory();
	const { codeName } = match.params;
	const [dataLottery, setDataLottery] = useState([]);
	const [presentAlert] = useIonAlert();

	const getListLottery = async (codeName: string) => {
		const { data } = await getResult(codeName);

		if (data) {
			setDataLottery(data);
		}
	};

	useEffect(() => {
		getListLottery(codeName);
	}, [codeName]);

	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/lottery-record")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						{get(LOTTERY_DISPLAY_NAME, `${codeName}`)}
					</IonButton>
				</div>
			</div>
			<IonContent className="app-lottery-list bet-history-detail">
				<IonList className="ios ion-list list-ios hydrated">
					{dataLottery.length > 0 &&
						dataLottery.map((item, index) => {
							if (!item["ResultDate"]) return <React.Fragment key={index}></React.Fragment>;
							return (
								<IonItem lines="none" className="item bg-lottery-list ion-item ios item-lines-none in-list ion-focusable hydrated" key={index}>
									<IonGrid className="ios ion-grid hydrated">
										<IonRow className="ios hydrated">
											<IonCol className="issue text-result-date ion-col ios hydrated" size="5">
												{item["ResultDate"]}
											</IonCol>
											<IonCol size="7" className="ion-col ios hydrated">
												<IonButton
													className="ios ion-button button button-small button-solid ion-activatable ion-focusable hydrated"
													onClick={() => {
														const arrLotteryNumber = split(
															`${item["Special"]}-${item["T1"]}-${item["T2"]}-${item["T3"]}-${item["T4"]}-${item["T5"]}-${item["T6"]}-${item["T7"]}-${item["T8"]}`,
															"-"
														);
														const dauDuoi = {};
														forEach(arrLotteryNumber, (item) => {
															if (item) {
																const num = item.slice(-2);
																const num1 = num[0];
																const num2 = num[1];
																const newDataDau = get(dauDuoi, `${num1}`, []);
																set(dauDuoi, `${num1}`, [...newDataDau, num2]);
															}
														});
														presentAlert({
															header: `Đầu Đuôi: ${item["ResultDate"]}`,
															message: `<table class="tblDauDuoiOnly">
                                <tbody>
                                  <tr className="title">
                                    <td><span class="font-500">Đầu</span></td>
                                    <td><span class="font-500">Đuôi</span></td>
                                  </tr>
                                  <tr>
                                    <td>0</td>
                                    <td>${join(get(dauDuoi, "0", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>1</td>
                                    <td>${join(get(dauDuoi, "1", []), ", ")}</td></tr>
                                  <tr>
                                    <td>2</td>
                                    <td>${join(get(dauDuoi, "2", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>${join(get(dauDuoi, "3", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>${join(get(dauDuoi, "4", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>${join(get(dauDuoi, "5", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>6</td>
                                    <td>${join(get(dauDuoi, "6", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>7</td>
                                    <td>${join(get(dauDuoi, "7", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>8</td>
                                    <td>${join(get(dauDuoi, "8", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>9</td>
                                    <td>${join(get(dauDuoi, "9", []), ", ")}</td>
                                  </tr>
                                </tbody>
                              </table>`,
															buttons: [
																{
																	text: "OK",
																	role: "confirm",
																},
															],
															cssClass: "alert-custom alert-tblDauDuoiOnly",
														});
													}}
												>
													Đầu-Đuôi
												</IonButton>
												<IonButton
													className="ios ion-button button button-small button-solid ion-activatable ion-focusable hydrated"
													onClick={() => {
														const arrLotteryNumber = split(
															`${item["Special"]}-${item["T1"]}-${item["T2"]}-${item["T3"]}-${item["T4"]}-${item["T5"]}-${item["T6"]}-${item["T7"]}-${item["T8"]}`,
															"-"
														);
														const duoiDau = {};
														forEach(arrLotteryNumber, (item) => {
															if (item) {
																const num = item.slice(-2);
																const num1 = num[0];
																const num2 = num[1];
																const newDataDuoi = get(duoiDau, `${num2}`, []);
																set(duoiDau, `${num2}`, [...newDataDuoi, num1]);
															}
														});
														presentAlert({
															header: `Đuôi Đầu: ${item["ResultDate"]}`,
															message: `<table class="tblDauDuoiOnly">
                                <tbody>
                                  <tr className="title">
                                    <td><span class="font-500">Đuôi</span></td>
                                    <td><span class="font-500">Đầu</span></td>
                                  </tr>
                                  <tr>
                                    <td>0</td>
                                    <td>${join(get(duoiDau, "0", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>1</td>
                                    <td>${join(get(duoiDau, "1", []), ", ")}</td></tr>
                                  <tr>
                                    <td>2</td>
                                    <td>${join(get(duoiDau, "2", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>3</td>
                                    <td>${join(get(duoiDau, "3", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>4</td>
                                    <td>${join(get(duoiDau, "4", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>5</td>
                                    <td>${join(get(duoiDau, "5", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>6</td>
                                    <td>${join(get(duoiDau, "6", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>7</td>
                                    <td>${join(get(duoiDau, "7", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>8</td>
                                    <td>${join(get(duoiDau, "8", []), ", ")}</td>
                                  </tr>
                                  <tr>
                                    <td>9</td>
                                    <td>${join(get(duoiDau, "9", []), ", ")}</td>
                                  </tr>
                                </tbody>
                              </table>`,
															buttons: [
																{
																	text: "OK",
																	role: "confirm",
																},
															],
															cssClass: "alert-custom alert-tblDauDuoiOnly",
														});
													}}
												>
													Đuôi-Đầu
												</IonButton>
											</IonCol>
										</IonRow>
										<IonRow className="ios hydrated">
											<IonCol className="lottery-history-bet ion-col ios hydrated">
												<table>
													<tbody>
														{get(item, "Special", "") && (
															<tr>
																<td>ĐB</td>
																<td>
																	<div className="num-prizedb">{get(item, "Special", "")}</div>
																</td>
															</tr>
														)}
														{get(item, "T1", "") && (
															<tr>
																<td>Nhất</td>
																<td>
																	<div>{get(item, "T1", "")}</div>
																</td>
															</tr>
														)}
														{get(item, "T2", "") && (
															<tr>
																<td>Nhì</td>
																<td>
																	<div className={get(item, "T8", "").length > 0 ? "" : "result_t2"}>
																		{get(item, "T2", "")
																			.split("-")
																			.map((item: any, index: any) => (
																				<span key={index}>{item}</span>
																			))}
																	</div>
																</td>
															</tr>
														)}
														{get(item, "T3", "") && (
															<tr>
																<td>Ba</td>
																<td>
																	<div className={get(item, "T8", "").length > 0 ? "result_t2" : "result_t3"}>
																		{get(item, "T3", "")
																			.split("-")
																			.map((item: any, index: any) => (
																				<span key={index}>{item}</span>
																			))}
																	</div>
																</td>
															</tr>
														)}
														{get(item, "T4", "") && (
															<tr>
																<td>Tư</td>
																<td>
																	<div className={get(item, "T8", "").length > 0 ? "result_mn_t4" : "result_t4"}>
																		{get(item, "T4", "")
																			.split("-")
																			.map((item: any, index: any) => (
																				<span key={index}>{item}</span>
																			))}
																	</div>
																</td>
															</tr>
														)}
														{get(item, "T5", "") && (
															<tr>
																<td>Năm</td>
																<td>
																	<div className={get(item, "T8", "").length > 0 ? "" : "result_t3"}>
																		{get(item, "T5", "")
																			.split("-")
																			.map((item: any, index: any) => (
																				<span key={index}>{item}</span>
																			))}
																	</div>
																</td>
															</tr>
														)}
														{get(item, "T6", "") && (
															<tr>
																<td>Sáu</td>
																<td>
																	<div className="result_t3">
																		{get(item, "T6", "")
																			.split("-")
																			.map((item: any, index: any) => (
																				<span key={index}>{item}</span>
																			))}
																	</div>
																</td>
															</tr>
														)}
														{get(item, "T7", "") && (
															<tr>
																<td>Bảy</td>
																<td>
																	<div className={`${get(item, "T8", "").length > 0 ? "" : "num-prizedb"} result_t7`}>
																		{get(item, "T7", "")
																			.split("-")
																			.map((item: any, index: any) => (
																				<span key={index}>{item}</span>
																			))}
																	</div>
																</td>
															</tr>
														)}
														{get(item, "T8", "") && (
															<tr>
																<td>Tám</td>
																<td>
																	<div className="result_t7 num-prizedb">
																		{get(item, "T8", "")
																			.split("-")
																			.map((item: any, index: any) => (
																				<span key={index}>{item}</span>
																			))}
																	</div>
																</td>
															</tr>
														)}
													</tbody>
												</table>
												{/* <table>
													<tbody>
														{item["Special"] && (
															<tr>
																<td>Giải ĐB</td>
																<td>
																	<div>{item["Special"]}</div>
																</td>
															</tr>
														)}
														{item["T1"] && (
															<tr>
																<td>Giải nhất</td>
																<td>
																	<div>{item["T1"]}</div>
																</td>
															</tr>
														)}
														{item["T2"] && (
															<tr>
																<td>Giải nhì</td>
																<td>
																	<div>{item["T2"]}</div>
																</td>
															</tr>
														)}
														{item["T3"] && (
															<tr>
																<td>Giải ba</td>
																<td>
																	<div>{item["T3"]}</div>
																</td>
															</tr>
														)}
														{item["T4"] && (
															<tr>
																<td>Giải tư</td>
																<td>
																	<div>{item["T4"]}</div>
																</td>
															</tr>
														)}
														{item["T5"] && (
															<tr>
																<td>Giải năm</td>
																<td>
																	<div>{item["T5"]}</div>
																</td>
															</tr>
														)}
														{item["T6"] && (
															<tr>
																<td>Giải sáu</td>
																<td>
																	<div>{item["T6"]}</div>
																</td>
															</tr>
														)}
														{item["T7"] && (
															<tr>
																<td>Giải bảy</td>
																<td>
																	<div>{item["T7"]}</div>
																</td>
															</tr>
														)}
														{item["T8"] && (
															<tr>
																<td>Giải tám</td>
																<td>
																	<div>{item["T8"]}</div>
																</td>
															</tr>
														)}
													</tbody>
												</table> */}
											</IonCol>
										</IonRow>
									</IonGrid>
								</IonItem>
							);
						})}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default LotteryList;
