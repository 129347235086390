import { useState, useEffect } from "react";
import { IonContent, IonHeader, IonToolbar, IonIcon, IonTitle, IonButton, IonText, IonItem, IonModal, IonList } from "@ionic/react";
import "../../pages/LotteryPlay.css";
import { chevronForward } from "ionicons/icons";
import { forEach, get, join, set, split, includes, size } from "lodash";
import { LOTTERY_DISPLAY_NAME, LOTTERY_QUICK } from "../../constants";
import { getResult } from "../../redux/auth/authCrud";
import { useSelector, shallowEqual } from "react-redux";

const LotteryResults = ({ codeName, isShowModalResults, setIsShowModalResults }) => {
	const [dataLottery, setDataLottery] = useState([]);
	const [dataModalResult, setDataModalResult] = useState(null);
	const [dataModalLoading, setDataModalLoading] = useState(null);

	const { ticketResult } = useSelector(
		(state) => ({
			ticketResult: state.lottery.ticketResult,
		}),
		shallowEqual
	);

	const getListLottery = async (codeName) => {
		const { data } = await getResult(codeName);

		if (data) {
			setDataLottery(data);
		}
	};

	useEffect(() => {
		getListLottery(codeName);
	}, [codeName]);

	const formatTicketResult = (ticketResult) => {
		if (!ticketResult) return;

		const arrLotteryNumber = split(
			`${ticketResult["Special"]}-${ticketResult["T1"]}-${ticketResult["T2"]}-${ticketResult["T3"]}-${ticketResult["T4"]}-${ticketResult["T5"]}-${ticketResult["T6"]}-${ticketResult["T7"]}-${ticketResult["T8"]}`,
			"-"
		);

		const dauDuoi = {};
		forEach(arrLotteryNumber, (item) => {
			if (item) {
				const num = item.slice(-2);
				const num1 = num[0];
				const num2 = num[1];
				const newDataDau = get(dauDuoi, `${num1}`, []);
				set(dauDuoi, `${num1}`, [...newDataDau, num2]);
			}
		});

		setDataModalLoading({
			item: ticketResult,
			dauDuoi,
		});
	};

	useEffect(() => {
		if (!includes(LOTTERY_QUICK, codeName)) return;
		if (includes(["mn1p"], codeName) && ticketResult?.ChannelId !== 49) return;
		if (includes(["mn2p"], codeName) && ticketResult?.ChannelId !== 50) return;
		if (includes(["mn5p"], codeName) && ticketResult?.ChannelId !== 51) return;
		if (includes(["mb1p"], codeName) && ticketResult?.ChannelId !== 52) return;
		if (includes(["mb2p"], codeName) && ticketResult?.ChannelId !== 53) return;
		if (includes(["mb5p"], codeName) && ticketResult?.ChannelId !== 54) return;

		// cập nhập vào dataResults
		if (dataLottery.length > 0) {
			const index = dataLottery.findIndex((item) => item.ResultDate === ticketResult.ResultDate);
			if (index === -1) {
				setDataLottery([ticketResult, ...dataLottery]);
			} else {
				setDataLottery(dataLottery.map((item, idx) => (idx === index ? ticketResult : item)));
			}
			formatTicketResult(ticketResult);
		} else {
			setDataLottery([ticketResult]);
		}

		// eslint-disable-next-line
	}, [ticketResult]);

	if (dataModalLoading)
		return (
			<IonModal
				isOpen={true}
				onIonModalDidDismiss={() => setIsShowModalResults(false)}
				className="app-show-lottery-result sc-ion-modal-md-h sc-ion-modal-md-s md modalHisResult hydrated"
			>
				<IonContent className="ion-content account-form ios overscroll hydrated ion-padding-7px">
					<div className="title__result">
						Kết quả lượt xổ
						<span className="issue__name">
							{includes(LOTTERY_QUICK, codeName) ? get(dataModalLoading, "item.SessionId") : get(dataModalLoading, "item.ResultDate")}
						</span>
					</div>
					<div className="wrap-tbl-result-lottery asean-link">
						<div className="wrap-tbl-result w-full">
							<table className="tblResultLottery">
								<tbody>
									{get(dataModalLoading, "item.Special", "") && (
										<tr>
											<td>ĐB</td>
											<td>
												{includes(get(dataModalLoading, "item.Special", ""), "*") ? (
													<div className="flex items-center justify-content-center">
														<img width="15px" src="assets/icon-lottery/gifs/1.gif" alt="ĐB" />
														<img width="15px" src="assets/icon-lottery/gifs/2.gif" alt="ĐB" />
														<img width="15px" src="assets/icon-lottery/gifs/3.gif" alt="ĐB" />
														<img width="15px" src="assets/icon-lottery/gifs/4.gif" alt="ĐB" />
														<img width="15px" src="assets/icon-lottery/gifs/5.gif" alt="ĐB" />
														<img width="15px" src="assets/icon-lottery/gifs/6.gif" alt="ĐB" />
													</div>
												) : includes(get(dataModalLoading, "item.Special", ""), "loading") ? (
													<div className="flex items-center justify-content-center">
														<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
													</div>
												) : (
													<div className="num-prizedb flex items-center justify-content-center">
														<span>{get(dataModalLoading, "item.Special", "")}</span>
													</div>
												)}
											</td>
										</tr>
									)}
									{get(dataModalLoading, "item.T1", "") && (
										<tr>
											<td>Nhất</td>
											<td className="bg-white">
												{includes(get(dataModalLoading, "item.T1", ""), "*") ? (
													<div className="flex items-center justify-content-center">
														<img width="15px" src="assets/icon-lottery/gifs/1.gif" alt="ĐB" />
														<img width="15px" src="assets/icon-lottery/gifs/2.gif" alt="ĐB" />
														<img width="15px" src="assets/icon-lottery/gifs/3.gif" alt="ĐB" />
														<img width="15px" src="assets/icon-lottery/gifs/4.gif" alt="ĐB" />
														<img width="15px" src="assets/icon-lottery/gifs/5.gif" alt="ĐB" />
													</div>
												) : includes(get(dataModalLoading, "item.T1", ""), "loading") ? (
													<div className="flex items-center justify-content-center">
														<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
													</div>
												) : (
													<div className="flex items-center justify-content-center">
														<span>{get(dataModalLoading, "item.T1", "")}</span>
													</div>
												)}
											</td>
										</tr>
									)}
									{get(dataModalLoading, "item.T2", "") && (
										<tr>
											<td>Nhì</td>
											<td>
												<div className={size(get(dataModalLoading, "item.T8", "")) > 0 ? "" : "result_t2"}>
													{get(dataModalLoading, "item.T2", "")
														.split("-")
														.map((item, index) => {
															if (includes(item, "loading"))
																return (
																	<div className="flex items-center justify-content-center" key={index}>
																		<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
																	</div>
																);

															if (includes(item, "*"))
																return (
																	<div className="flex items-center justify-center" key={index}>
																		{item.split("*").map((item, index) => {
																			if (index < 1) return null;
																			return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																		})}
																	</div>
																);

															return (
																<div className="flex items-center justify-content-center" key={index}>
																	<span>{item}</span>
																</div>
															);
														})}
												</div>
											</td>
										</tr>
									)}
									{get(dataModalLoading, "item.T3", "") && (
										<tr>
											<td>Ba</td>
											<td className="bg-white">
												<div className={size(get(dataModalLoading, "item.T8", "")) > 0 ? "result_t2" : "result_t3"}>
													{get(dataModalLoading, "item.T3", "")
														.split("-")
														.map((item, index) => {
															if (includes(item, "loading"))
																return (
																	<div className="flex items-center justify-content-center" key={index}>
																		<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
																	</div>
																);

															if (includes(item, "*"))
																return (
																	<div className="flex items-center justify-center" key={index}>
																		{item.split("*").map((item, index) => {
																			if (index < 1) return null;
																			return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																		})}
																	</div>
																);

															return (
																<div className="flex items-center justify-content-center" key={index}>
																	<span>{item}</span>
																</div>
															);
														})}
												</div>
											</td>
										</tr>
									)}
									{get(dataModalLoading, "item.T4", "") && (
										<tr>
											<td>Tư</td>
											<td>
												<div
													className={
														size(get(dataModalLoading, "item.T8", "")) > 0 ? "flex flex-wrap justify-content-center" : "flex flex-wrap justify-content-center"
													}
												>
													{get(dataModalLoading, "item.T4", "")
														.split("-")
														.map((item, index) => {
															if (includes(item, "loading"))
																return (
																	<div className="flex items-center justify-content-center flex-basis-25" key={index}>
																		<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
																	</div>
																);

															if (includes(item, "*"))
																return (
																	<div className="flex items-center justify-center flex-basis-25" key={index}>
																		{item.split("*").map((item, index) => {
																			if (index < 1) return null;
																			return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																		})}
																	</div>
																);

															return (
																<div className="flex items-center justify-content-center flex-basis-25" key={index}>
																	<span>{item}</span>
																</div>
															);
														})}
												</div>
											</td>
										</tr>
									)}
									{get(dataModalLoading, "item.T5", "") && (
										<tr>
											<td>Năm</td>
											<td className="bg-white">
												<div className={size(get(dataModalLoading, "item.T8", "")) > 0 ? "" : "result_t3"}>
													{get(dataModalLoading, "item.T5", "")
														.split("-")
														.map((item, index) => {
															if (includes(item, "loading"))
																return (
																	<div className="flex items-center justify-content-center" key={index}>
																		<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
																	</div>
																);

															if (includes(item, "*"))
																return (
																	<div className="flex items-center justify-center" key={index}>
																		{item.split("*").map((item, index) => {
																			if (index < 1) return null;
																			return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																		})}
																	</div>
																);

															return (
																<div className="flex items-center justify-content-center" key={index}>
																	<span>{item}</span>
																</div>
															);
														})}
												</div>
											</td>
										</tr>
									)}
									{get(dataModalLoading, "item.T6", "") && (
										<tr>
											<td>Sáu</td>
											<td>
												<div className="result_t3">
													{get(dataModalLoading, "item.T6", "")
														.split("-")
														.map((item, index) => {
															if (includes(item, "loading"))
																return (
																	<div className="flex items-center justify-content-center" key={index}>
																		<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
																	</div>
																);

															if (includes(item, "*"))
																return (
																	<div className="flex items-center justify-center" key={index}>
																		{item.split("*").map((item, index) => {
																			if (index < 1) return null;
																			return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																		})}
																	</div>
																);

															return (
																<div className="flex items-center justify-content-center" key={index}>
																	<span>{item}</span>
																</div>
															);
														})}
												</div>
											</td>
										</tr>
									)}
									{get(dataModalLoading, "item.T7", "") && (
										<tr>
											<td>Bảy</td>
											<td className="bg-white">
												<div className={`${size(get(dataModalLoading, "item.T8", "")) > 0 ? "" : "num-prizedb"} items-center justify-content-center result_t7`}>
													{get(dataModalLoading, "item.T7", "")
														.split("-")
														.map((item, index) => {
															if (includes(item, "loading"))
																return (
																	<div className="flex items-center justify-content-center flex-basis-25" key={index}>
																		<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
																	</div>
																);

															if (includes(item, "*"))
																return (
																	<div className="flex items-center justify-center flex-basis-25" key={index}>
																		{item.split("*").map((item, index) => {
																			if (index < 1) return null;
																			return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																		})}
																	</div>
																);

															return (
																<div className="flex items-center justify-content-center flex-basis-25" key={index}>
																	<span>{item}</span>
																</div>
															);
														})}
												</div>
											</td>
										</tr>
									)}
									{get(dataModalLoading, "item.T8", "") && (
										<tr>
											<td>Tám</td>
											<td>
												<div className="num-prizedb result_t7 items-center justify-content-center">
													{get(dataModalLoading, "item.T8", "")
														.split("-")
														.map((item, index) => {
															if (includes(item, "loading"))
																return (
																	<div className="flex items-center justify-content-center" key={index}>
																		<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
																	</div>
																);

															if (includes(item, "*"))
																return (
																	<div className="flex items-center justify-center" key={index}>
																		{item.split("*").map((item, index) => {
																			if (index < 1) return null;
																			return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																		})}
																	</div>
																);

															return (
																<div className="flex items-center justify-content-center" key={index}>
																	<span>{item}</span>
																</div>
															);
														})}
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						{/* <div className="wrap-tbl-dauduoi">
							<table className="tblDauDuoi">
								<tbody>
									<tr className="title">
										<td>Đầu</td>
										<td>Đuôi</td>
									</tr>
									<tr>
										<td>0</td>
										<td>{join(get(dataModalLoading, "dauDuoi.0", []), ", ")}</td>
									</tr>
									<tr>
										<td>1</td>
										<td>{join(get(dataModalLoading, "dauDuoi.1", []), ", ")}</td>
									</tr>
									<tr>
										<td>2</td>
										<td>{join(get(dataModalLoading, "dauDuoi.2", []), ", ")}</td>
									</tr>
									<tr>
										<td>3</td>
										<td>{join(get(dataModalLoading, "dauDuoi.3", []), ", ")}</td>
									</tr>
									<tr>
										<td>4</td>
										<td>{join(get(dataModalLoading, "dauDuoi.4", []), ", ")}</td>
									</tr>
									<tr>
										<td>5</td>
										<td>{join(get(dataModalLoading, "dauDuoi.5", []), ", ")}</td>
									</tr>
									<tr>
										<td>6</td>
										<td>{join(get(dataModalLoading, "dauDuoi.6", []), ", ")}</td>
									</tr>
									<tr>
										<td>7</td>
										<td>{join(get(dataModalLoading, "dauDuoi.7", []), ", ")}</td>
									</tr>
									<tr>
										<td>8</td>
										<td>{join(get(dataModalLoading, "dauDuoi.8", []), ", ")}</td>
									</tr>
									<tr>
										<td>9</td>
										<td>{join(get(dataModalLoading, "dauDuoi.9", []), ", ")}</td>
									</tr>
								</tbody>
							</table>
						</div> */}
					</div>
					<IonButton
						className="close-result ios button button-block button-solid ion-activatable ion-focusable hydrated"
						expand="block"
						onClick={() => setDataModalLoading(null)}
					>
						OK
					</IonButton>
				</IonContent>
			</IonModal>
		);

	if (dataModalResult)
		return (
			<IonModal
				isOpen={true}
				onIonModalDidDismiss={() => setIsShowModalResults(false)}
				className="app-show-lottery-result sc-ion-modal-md-h sc-ion-modal-md-s md modalHisResult hydrated"
			>
				<IonContent className="ion-content ion-padding-7px account-form ios overscroll hydrated">
					<div className="title__result">
						Kết quả lượt xổ
						<span className="issue__name">
							{includes(LOTTERY_QUICK, codeName) ? get(dataModalResult, "item.SessionId") : get(dataModalResult, "item.ResultDate")}
						</span>
					</div>
					<div className="wrap-tbl-result-lottery asean-link">
						<div className="wrap-tbl-result">
							<table className="tblResultLottery">
								<tbody>
									{get(dataModalResult, "item.Special", "") && (
										<tr>
											<td>ĐB</td>
											<td>
												<div className="num-prizedb">{get(dataModalResult, "item.Special", "")}</div>
											</td>
										</tr>
									)}
									{get(dataModalResult, "item.T1", "") && (
										<tr>
											<td>Nhất</td>
											<td className="bg-white">
												<div>{get(dataModalResult, "item.T1", "")}</div>
											</td>
										</tr>
									)}
									{get(dataModalResult, "item.T2", "") && (
										<tr>
											<td>Nhì</td>
											<td>
												<div className={size(get(dataModalResult, "item.T8", "")) > 0 ? "" : "result_t2"}>
													{get(dataModalResult, "item.T2", "")
														.split("-")
														.map((item, index) => (
															<span key={index}>{item}</span>
														))}
												</div>
											</td>
										</tr>
									)}
									{get(dataModalResult, "item.T3", "") && (
										<tr>
											<td>Ba</td>
											<td className="bg-white">
												<div className={size(get(dataModalResult, "item.T8", "")) > 0 ? "result_t2" : "result_t3"}>
													{get(dataModalResult, "item.T3", "")
														.split("-")
														.map((item, index) => (
															<span key={index}>{item}</span>
														))}
												</div>
											</td>
										</tr>
									)}
									{get(dataModalResult, "item.T4", "") && (
										<tr>
											<td>Tư</td>
											<td>
												<div
													className={
														size(get(dataModalResult, "item.T8", "")) > 0 ? "flex flex-wrap justify-content-center" : "flex flex-wrap justify-content-center"
													}
												>
													{get(dataModalResult, "item.T4", "")
														.split("-")
														.map((item, index) => (
															<div className="flex items-center justify-content-center flex-basis-25" key={index}>
																<span>{item}</span>
															</div>
														))}
												</div>
											</td>
										</tr>
									)}

									{get(dataModalResult, "item.T5", "") && (
										<tr>
											<td>Năm</td>
											<td className="bg-white">
												<div className={size(get(dataModalResult, "item.T8", "")) > 0 ? "" : "result_t3"}>
													{get(dataModalResult, "item.T5", "")
														.split("-")
														.map((item, index) => (
															<span key={index}>{item}</span>
														))}
												</div>
											</td>
										</tr>
									)}
									{get(dataModalResult, "item.T6", "") && (
										<tr>
											<td>Sáu</td>
											<td>
												<div className="result_t3">
													{get(dataModalResult, "item.T6", "")
														.split("-")
														.map((item, index) => (
															<span key={index}>{item}</span>
														))}
												</div>
											</td>
										</tr>
									)}
									{get(dataModalResult, "item.T7", "") && (
										<tr>
											<td>Bảy</td>
											<td className="bg-white">
												<div className={`${size(get(dataModalResult, "item.T8", "")) > 0 ? "" : "num-prizedb"} result_t7`}>
													{get(dataModalResult, "item.T7", "")
														.split("-")
														.map((item, index) => (
															<span key={index}>{item}</span>
														))}
												</div>
											</td>
										</tr>
									)}
									{get(dataModalResult, "item.T8", "") && (
										<tr>
											<td>Tám</td>
											<td>
												<div className="result_t7 num-prizedb">
													{get(dataModalResult, "item.T8", "")
														.split("-")
														.map((item, index) => (
															<span key={index}>{item}</span>
														))}
												</div>
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
						<div className="wrap-tbl-dauduoi">
							<table className="tblDauDuoi">
								<tbody>
									<tr className="title">
										<td>Đầu</td>
										<td>Đuôi</td>
									</tr>
									<tr>
										<td>0</td>
										<td>{join(get(dataModalResult, "dauDuoi.0", []), ", ")}</td>
									</tr>
									<tr>
										<td>1</td>
										<td>{join(get(dataModalResult, "dauDuoi.1", []), ", ")}</td>
									</tr>
									<tr>
										<td>2</td>
										<td>{join(get(dataModalResult, "dauDuoi.2", []), ", ")}</td>
									</tr>
									<tr>
										<td>3</td>
										<td>{join(get(dataModalResult, "dauDuoi.3", []), ", ")}</td>
									</tr>
									<tr>
										<td>4</td>
										<td>{join(get(dataModalResult, "dauDuoi.4", []), ", ")}</td>
									</tr>
									<tr>
										<td>5</td>
										<td>{join(get(dataModalResult, "dauDuoi.5", []), ", ")}</td>
									</tr>
									<tr>
										<td>6</td>
										<td>{join(get(dataModalResult, "dauDuoi.6", []), ", ")}</td>
									</tr>
									<tr>
										<td>7</td>
										<td>{join(get(dataModalResult, "dauDuoi.7", []), ", ")}</td>
									</tr>
									<tr>
										<td>8</td>
										<td>{join(get(dataModalResult, "dauDuoi.8", []), ", ")}</td>
									</tr>
									<tr>
										<td>9</td>
										<td>{join(get(dataModalResult, "dauDuoi.9", []), ", ")}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
					<IonButton
						className="close-result ios button button-block button-solid ion-activatable ion-focusable hydrated"
						expand="block"
						onClick={() => setDataModalResult(null)}
					>
						OK
					</IonButton>
				</IonContent>
			</IonModal>
		);

	return (
		<>
			<IonModal
				isOpen={isShowModalResults}
				onIonModalDidDismiss={() => setIsShowModalResults(false)}
				className="app-results-list sc-ion-modal-md-h sc-ion-modal-md-s md modalResults hydrated"
			>
				<IonHeader mode="ios" role="banner" className="ion-header ios header-ios header-collapse-none hydrated">
					<IonToolbar mode="md" className="ion-toolbar in-toolbar md toolbar-title-default hydrated">
						<IonTitle className="md title-md title-default hydrated">{`Kết quả ${get(LOTTERY_DISPLAY_NAME, `${codeName}`)}`}</IonTitle>
					</IonToolbar>
				</IonHeader>
				<IonContent className="ion-content account-form ios overscroll hydrated">
					<IonList className="ion-list ios list-ios hydrated">
						{dataLottery.length > 0 &&
							dataLottery.map((item, index) => {
								return (
									<IonItem
										key={index}
										className="ion-item item ios in-list ion-focusable hydrated"
										onClick={() => {
											const arrLotteryNumber = split(
												`${item["Special"]}-${item["T1"]}-${item["T2"]}-${item["T3"]}-${item["T4"]}-${item["T5"]}-${item["T6"]}-${item["T7"]}-${item["T8"]}`,
												"-"
											);
											const dauDuoi = {};
											forEach(arrLotteryNumber, (item) => {
												if (item) {
													const num = item.slice(-2);
													const num1 = num[0];
													const num2 = num[1];
													const newDataDau = get(dauDuoi, `${num1}`, []);
													set(dauDuoi, `${num1}`, [...newDataDau, num2]);
												}
											});
											setDataModalResult({
												item,
												dauDuoi,
											});
										}}
									>
										<IonText className="ios hydrated">{includes(LOTTERY_QUICK, codeName) ? get(item, "SessionId") : get(item, "ResultDate")}</IonText>
										<IonIcon className="end-arrow ios hydrated" mode="ios" icon={chevronForward} slot="end" role="img" aria-label="arrow forward"></IonIcon>
									</IonItem>
								);
							})}
					</IonList>
				</IonContent>
			</IonModal>
		</>
	);
};

export default LotteryResults;
