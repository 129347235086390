import React, { useState } from "react";
import { IonContent, IonPage, IonIcon, IonButton, IonLabel, IonInput, IonImg, IonItem, IonCheckbox, useIonAlert, useIonToast } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { addUserDownline } from "../redux/auth/authCrud";
import { defaultsDeep, size } from "lodash";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./Agency.css";
import { IMG_MB } from "../constants";

const INIT_USER_DOWNLINE = {
	UserName: "",
	Password: "",
	WithdrawPassword: "",
	ConfirmPassword: "",
	NickName: "",
	Mobile: "",
	isCheckRegistered: true,
};

const AgencyRegister: React.FC = () => {
	const history = useHistory();

	const [presentAlert] = useIonAlert();
	const [present] = useIonToast();

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	const [dataUserDownline, setDataUserDownline] = useState(INIT_USER_DOWNLINE);

	const regexPhoneNumber = (phone: any) => {
		const regexPhoneNumber = /(0[3|5|7|8|9])+([0-9]{8})\b/g;
		return phone.match(regexPhoneNumber) ? true : false;
	};

	const _addUserDownline = async () => {
		if (!dataUserDownline.UserName) return presentToast("error", "Nhập tên đăng nhập.");
		if (dataUserDownline.UserName && size(dataUserDownline.UserName) < 6) return presentToast("error", "Tên đăng nhập không được ít hơn 5 ký tự");
		if (dataUserDownline.UserName && size(dataUserDownline.UserName) > 15) return presentToast("error", "Tên đăng nhập không được nhiều hơn 15 ký tự");
		if (!dataUserDownline.NickName) return presentToast("error", "Nhập họ và tên.");
		if (!dataUserDownline.Password) return presentToast("error", "Nhập mật khẩu.");
		if (!dataUserDownline.Mobile) return presentToast("error", "Nhập số điện thoại.");
		if (dataUserDownline.Mobile && size(dataUserDownline.Mobile) !== 10) return presentToast("error", "Số điện thoại phải là 10 ký tự");
		if (!regexPhoneNumber(dataUserDownline.Mobile)) return presentToast("error", "Số điện thoại không đúng định dạng");
		if (!dataUserDownline.ConfirmPassword) return presentToast("error", "Nhập xác nhận mật khẩu.");
		if (dataUserDownline.ConfirmPassword !== dataUserDownline.Password) return presentToast("error", "Xác nhận mật khẩu không chính xác.");
		if (!dataUserDownline.isCheckRegistered) return presentToast("error", "Vui lòng xác nhận trên 18 tuổi và đã đọc điều khoản và điều kiện");

		const res = await addUserDownline(dataUserDownline);
		if (res && res.data && res.data.Status === false) return presentToast("error", "Thêm tài khoản cấp dưới không thành công.");

		presentAlert({
			header: "Thông Báo",
			message: `Thêm tài khoản cấp dưới thành công.`,
			buttons: ["OK"],
			cssClass: "alert-custom",
		});
		setDataUserDownline(INIT_USER_DOWNLINE);
	};

	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/agency")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Đăng Ký Tài Khoản Cấp Dưới
					</IonButton>
				</div>
			</div>
			<IonContent className="">
				<div className="af-wrapper m-10 p-10 agency-register">
					<div className="af-item">
						<div className="flex agency-register-item">
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/icon-register-user.png`} />
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Tên Đăng Nhập</IonLabel>
						</div>
						<IonInput
							placeholder="Từ 6-15 ký tự, có thể bao gồm cả chữ cái, số và gạch dưới ..."
							clearInput={true}
							type="text"
							className="agency-register-input ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
							value={dataUserDownline?.UserName}
							onIonChange={(e) => {
								setDataUserDownline(
									defaultsDeep(
										{
											UserName: e.detail.value,
										},
										dataUserDownline
									)
								);
							}}
						></IonInput>
					</div>
					<div className="af-item">
						<div className="flex agency-register-item">
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/icon-register-password.png`} />
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Mật Khẩu Đăng Nhập</IonLabel>
						</div>
						<IonInput
							placeholder="Hơn 6 ký tự, phải bao gồm cả chữ cái và số ..."
							clearInput={true}
							type="text"
							className="agency-register-input ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
							value={dataUserDownline?.Password}
							onIonChange={(e) => {
								setDataUserDownline(
									defaultsDeep(
										{
											Password: e.detail.value,
										},
										dataUserDownline
									)
								);
							}}
						></IonInput>
					</div>
					<div className="af-item">
						<div className="flex agency-register-item">
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/icon-register-confirm-password.png`} />
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Xác Nhận Mật Khẩu</IonLabel>
						</div>
						<IonInput
							placeholder="Nhập lại mật khẩu đã nhập bên trên ..."
							clearInput={true}
							type="text"
							className="agency-register-input ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
							value={dataUserDownline?.ConfirmPassword}
							onIonChange={(e) => {
								setDataUserDownline(
									defaultsDeep(
										{
											ConfirmPassword: e.detail.value,
										},
										dataUserDownline
									)
								);
							}}
						></IonInput>
					</div>
					<div className="af-item">
						<div className="flex agency-register-item">
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/icon-register-username.png`} />
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Họ Và Tên</IonLabel>
						</div>
						<IonInput
							placeholder="Yêu cầu Họ và Tên thật trùng với thông tin ngân hàng ..."
							clearInput={true}
							type="text"
							className="agency-register-input ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
							value={dataUserDownline?.NickName}
							onIonChange={(e) => {
								setDataUserDownline(
									defaultsDeep(
										{
											NickName: e.detail.value,
										},
										dataUserDownline
									)
								);
							}}
						></IonInput>
					</div>
					<div className="af-item">
						<div className="flex agency-register-item">
							<IonImg className="ion-icon" slot="start" src={`${IMG_MB}assets/icon/icon-register-phone.png`} />
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Số Điện Thoại</IonLabel>
						</div>
						<IonInput
							placeholder="Yêu cầu số điện thoại di động thật để xác minh tài khoản ..."
							clearInput={true}
							type="text"
							className="agency-register-input ng-untouched ng-pristine ng-valid sc-ion-input-ios-h sc-ion-input-ios-s ios hydrated ion-untouched ion-pristine ion-valid"
							value={dataUserDownline?.Mobile}
							onIonChange={(e) => {
								setDataUserDownline(
									defaultsDeep(
										{
											Mobile: e.detail.value,
										},
										dataUserDownline
									)
								);
							}}
						></IonInput>
					</div>
					<div className="af-item">
						<IonItem className="agency-register-checkbox">
							<IonCheckbox
								slot="start"
								checked={dataUserDownline.isCheckRegistered}
								value={dataUserDownline.isCheckRegistered}
								className="custom-checkbox"
								id="register-checkbox"
								onIonChange={() => setDataUserDownline(defaultsDeep({ isCheckRegistered: !dataUserDownline.isCheckRegistered }, dataUserDownline))}
							></IonCheckbox>
							<IonLabel>Tôi đồng ý và chấp nhận Điều khoản và điều kiện*</IonLabel>
						</IonItem>
					</div>
					<IonButton
						className="af-submit-btn ios button button-block button-solid ion-activatable ion-focusable hydrated"
						expand="block"
						onClick={() => _addUserDownline()}
					>
						ĐĂNG KÝ NGAY
					</IonButton>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default AgencyRegister;
