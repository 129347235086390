import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonIcon, IonButton, IonLabel, useIonAlert, IonList, IonItem } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { collectAllCredits, getWalletTransfer } from "../redux/auth/authCrud";
import { orderBy, toNumber } from "lodash";
import { formatMoney, formatUUID } from "../util";
import moment from "moment";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

import "./Agency.css";

const CollectAllCredits: React.FC = () => {
	const history = useHistory();
	const [presentAlert] = useIonAlert();
	const [isLoading, setIsLoading] = useState(false);
	const [dataWalletTransfer, setDataWalletTransfer] = useState<any>([]);

	const GetWalletTransfer = async () => {
		const res = await getWalletTransfer();
		if (res && res.data) {
			setDataWalletTransfer(orderBy(res.data, ["CreatedTime"], ["desc"]));
		}
	};

	useEffect(() => {
		GetWalletTransfer();
	}, []);

	const _collectAllCredits = async () => {
		if (isLoading) return;
		setIsLoading(true);
		await collectAllCredits("");
		setIsLoading(false);
		presentAlert({
			header: "Thông Báo",
			message: "Đồng bộ điểm thành công.",
			buttons: ["OK"],
			cssClass: "alert-custom",
		});
	};

	return (
		<IonPage className="app-agency-member-search">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/account")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Đồng Bộ Điểm
					</IonButton>
				</div>
			</div>
			<IonContent className="agency-member-list">
				<div className="af-wrapper mb-5">
					<div className="af-item">
						<div className="flex agency-member-list-item">
							<IonLabel className="sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">
								Khi bạn tham gia đặt cược tại các sảnh Casino Live, Cá Cược Thể Thao. Có thể Số Điểm của bạn vẫn đang ở các sảnh đó. Bạn có thể đồng bộ Số Điểm
								về tài khoản của mình bằng cách nhấn vào Button bên dưới.
							</IonLabel>
						</div>
					</div>
					<IonButton
						className="af-submit-btn ios button button-block button-solid ion-activatable ion-focusable hydrated"
						expand="block"
						onClick={() => _collectAllCredits()}
					>
						Đồng Bộ Điểm
					</IonButton>
				</div>
				<div className="sic-title-page">
					<span> Lịch Sử Chuyển Quỹ </span>
					<hr />
				</div>
				<IonList className="sic-bet-history ios list-ios">
					{dataWalletTransfer.map((item: any, index: any) => {
						return (
							<IonItem key={index} className="bet-history__main--item item-lines-none" lines="none">
								<div className="item-bet-history">
									<section className="bet-history-section flex-1">
										<div className="bet-history--channel">{item?.Note}</div>
										<div className="bet-history--time">{formatUUID(item?.Id)}</div>
									</section>
									<section className="bet-history-section">
										<div className="bet-history--amount">
											<span className="font-bold">{formatMoney(toNumber(toNumber(item?.Amount).toFixed(2)))}</span>
											<div className="bet-history--status">{moment(item?.CreatedTime).format("DD-MM-YYYY HH:mm:ss")}</div>
										</div>
									</section>
								</div>
							</IonItem>
						);
					})}
				</IonList>
			</IonContent>
		</IonPage>
	);
};

export default CollectAllCredits;
