import { useState, useEffect } from "react";
import "../../pages/LotteryPlay.css";
import { forEach, get, set, split, includes, size, join } from "lodash";
import { getResult } from "../../redux/auth/authCrud";
import { useSelector, shallowEqual } from "react-redux";
import { LOTTERY_QUICK, LOTTERY_QUICK_MB } from "../../constants";

const LotteryResultsXSN = ({ codeName, onUpdateTicketLottery }) => {
	const [dataLottery, setDataLottery] = useState([]);
	const [dataModalResult, setDataModalResult] = useState(null);

	const { ticketResult } = useSelector(
		(state) => ({
			ticketResult: state.lottery.ticketResult,
		}),
		shallowEqual
	);

	const getListLottery = async (codeName) => {
		const { data } = await getResult(codeName);

		if (data) {
			formatTicketResult(data[0], 0);
			setDataLottery(data);
		}
	};

	useEffect(() => {
		getListLottery(codeName);

		// eslint-disable-next-line
	}, [codeName]);

	const formatTicketResult = (data, index) => {
		if (!data) return;

		const arrLotteryNumber = split(
			`${data["Special"]}-${data["T1"]}-${data["T2"]}-${data["T3"]}-${data["T4"]}-${data["T5"]}-${data["T6"]}-${data["T7"]}-${data["T8"]}`,
			"-"
		);

		const dauDuoi = {};
		forEach(arrLotteryNumber, (item) => {
			if (item) {
				const num = item.slice(-2);
				const num1 = num[0];
				const num2 = num[1];
				const newDataDau = get(dauDuoi, `${num1}`, []);
				set(dauDuoi, `${num1}`, [...newDataDau, num2]);
			}
		});

		setDataModalResult({
			item: data,
			dauDuoi,
			index,
		});
	};

	useEffect(() => {
		if (!includes(LOTTERY_QUICK, codeName)) return;
		if (includes(["mn1p"], codeName) && ticketResult?.ChannelId !== 49) return;
		if (includes(["mn2p"], codeName) && ticketResult?.ChannelId !== 50) return;
		if (includes(["mn5p"], codeName) && ticketResult?.ChannelId !== 51) return;
		if (includes(["mb1p"], codeName) && ticketResult?.ChannelId !== 52) return;
		if (includes(["mb2p"], codeName) && ticketResult?.ChannelId !== 53) return;
		if (includes(["mb5p"], codeName) && ticketResult?.ChannelId !== 54) return;

		if (ticketResult["Special"] && !includes(ticketResult["Special"], "*") && !includes(ticketResult["Special"], "loading")) {
			onUpdateTicketLottery();
		}

		// cập nhập vào dataResults
		if (dataLottery.length > 0) {
			const index = dataLottery.findIndex((item) => item.ResultDate === ticketResult.ResultDate);
			if (index === -1) {
				setDataLottery([ticketResult, ...dataLottery]);
			} else {
				setDataLottery(dataLottery.map((item, idx) => (idx === index ? ticketResult : item)));
			}
			formatTicketResult(ticketResult, 0);
		} else {
			setDataLottery([ticketResult]);
		}

		// eslint-disable-next-line
	}, [ticketResult]);

	//get(dataModalResult, "item.Special", "")
	// lấy ra 2 số cuối của string
	// const num = item.slice(-2);
	// lấy ra số đầu tiên
	// const num1 = num[0];
	// cắt chuỗi từ vị trí 0 đến vị trí 1
	// const num2 = num[1];
	// const newDataDau = get(dauDuoi, `${num1}`, []);
	// set(dauDuoi, `${num1}`, [...newDataDau, num2]);
	// lấy ra số đầu trừ 2 số cuối của chuỗi
	// const num = item.slice(0, -2);
	// lấy ra số cuối của chuỗi
	// const num1 = num.slice(-1);
	// cắt chuỗi từ vị trí 0 đến vị trí 1
	// const num2 = num.slice(0, -1);

	return (
		<div className="ion-content account-form ios overscroll hydrated">
			<div className="title__result p-2px">
				<div
					className="prevNum"
					onClick={() => {
						if (dataModalResult?.index === dataLottery.length - 1) return;
						formatTicketResult(dataLottery[dataModalResult.index + 1], dataModalResult.index + 1);
					}}
				>
					<span></span>
				</div>
				<span className="issue__name">{get(dataModalResult, "item.SessionId")}</span>
				<div
					className="nextNum"
					onClick={() => {
						if (dataModalResult?.index === 0) return;
						formatTicketResult(dataLottery[dataModalResult.index - 1], dataModalResult.index - 1);
					}}
				>
					<span style={dataModalResult?.index === 0 ? { borderColor: "transparent transparent transparent #888" } : {}}></span>
				</div>
			</div>
			<div className="wrap-tbl-result-lottery asean-link">
				<div className="wrap-tbl-result">
					<table className="tblResultLottery">
						<tbody>
							<tr>
								<td className="p0">ĐB</td>
								<td className="p0">
									{get(dataModalResult, "item.Special", "") && (
										<>
											{includes(get(dataModalResult, "item.Special", ""), "*") ? (
												<div className="flex items-center justify-content-center">
													<img width="15px" src="assets/icon-lottery/gifs/1.gif" alt="ĐB" />
													<img width="15px" src="assets/icon-lottery/gifs/2.gif" alt="ĐB" />
													<img width="15px" src="assets/icon-lottery/gifs/3.gif" alt="ĐB" />
													<img width="15px" src="assets/icon-lottery/gifs/4.gif" alt="ĐB" />
													<img width="15px" src="assets/icon-lottery/gifs/5.gif" alt="ĐB" />
												</div>
											) : includes(get(dataModalResult, "item.Special", ""), "loading") ? (
												<div className="flex items-center justify-content-center">
													<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
												</div>
											) : (
												<div className="flex items-center justify-content-center">
													{get(dataModalResult, "item.Special", "")
														.split("")
														.map((item, index) => {
															return (
																<span className={index > (includes(LOTTERY_QUICK_MB, codeName) ? 2 : 3) ? "num-prizedb" : ""} key={index}>
																	{item}
																</span>
															);
														})}
												</div>
											)}
										</>
									)}
								</td>
							</tr>
							<tr>
								<td className="p0">Nhất</td>
								<td className="bg-white p0">
									{get(dataModalResult, "item.T1", "") && (
										<>
											{includes(get(dataModalResult, "item.T1", ""), "*") ? (
												<div className="flex items-center justify-content-center">
													<img width="15px" src="assets/icon-lottery/gifs/1.gif" alt="ĐB" />
													<img width="15px" src="assets/icon-lottery/gifs/2.gif" alt="ĐB" />
													<img width="15px" src="assets/icon-lottery/gifs/3.gif" alt="ĐB" />
													<img width="15px" src="assets/icon-lottery/gifs/4.gif" alt="ĐB" />
													<img width="15px" src="assets/icon-lottery/gifs/5.gif" alt="ĐB" />
												</div>
											) : includes(get(dataModalResult, "item.T1", ""), "loading") ? (
												<div className="flex items-center justify-content-center">
													<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
												</div>
											) : (
												<div className="flex items-center justify-content-center">
													<span>{get(dataModalResult, "item.T1", "")}</span>
												</div>
											)}
										</>
									)}
								</td>
							</tr>
							<tr>
								<td className="p0">Nhì</td>
								<td className="p0">
									{get(dataModalResult, "item.T2", "") && (
										<div className={size(get(dataModalResult, "item.T8", "")) > 0 ? "" : "result_t2"}>
											{get(dataModalResult, "item.T2", "")
												.split("-")
												.map((item, index) => {
													if (includes(item, "loading"))
														return (
															<div className="flex items-center justify-content-center" key={index}>
																<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
															</div>
														);

													if (includes(item, "*"))
														return (
															<div className="flex items-center justify-center" key={index}>
																{item.split("*").map((item, index) => {
																	if (index < 1) return null;
																	return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																})}
															</div>
														);

													return (
														<div className="flex items-center justify-content-center" key={index}>
															<span>{item}</span>
														</div>
													);
												})}
										</div>
									)}
								</td>
							</tr>
							<tr>
								<td className="p0">Ba</td>
								<td className="bg-white p0 min-h-39px">
									{get(dataModalResult, "item.T3", "") && (
										<div className={size(get(dataModalResult, "item.T8", "")) > 0 ? "result_t2" : "result_t3"}>
											{get(dataModalResult, "item.T3", "")
												.split("-")
												.map((item, index) => {
													if (includes(item, "loading"))
														return (
															<div className="flex items-center justify-content-center" key={index}>
																<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
															</div>
														);

													if (includes(item, "*"))
														return (
															<div className="flex items-center justify-center" key={index}>
																{item.split("*").map((item, index) => {
																	if (index < 1) return null;
																	return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																})}
															</div>
														);

													return (
														<div className="flex items-center justify-content-center" key={index}>
															<span>{item}</span>
														</div>
													);
												})}
										</div>
									)}
								</td>
							</tr>
							<tr>
								<td className="p0">Tư</td>
								<td className="p0 min-h-18px">
									{get(dataModalResult, "item.T4", "") && (
										<div
											className={
												size(get(dataModalResult, "item.T8", "")) > 0 ? "flex flex-wrap justify-content-center" : "flex flex-wrap justify-content-center"
											}
										>
											{get(dataModalResult, "item.T4", "")
												.split("-")
												.map((item, index) => {
													if (includes(item, "loading"))
														return (
															<div className="flex items-center justify-content-center flex-basis-25" key={index}>
																<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
															</div>
														);

													if (includes(item, "*"))
														return (
															<div className="flex items-center justify-center" key={index}>
																{item.split("*").map((item, index) => {
																	if (index < 1) return null;
																	return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																})}
															</div>
														);

													return (
														<div className="flex items-center justify-content-center flex-basis-25" key={index}>
															<span>{item}</span>
														</div>
													);
												})}
										</div>
									)}
								</td>
							</tr>
							<tr>
								<td className="p0">Năm</td>
								<td className="bg-white p0 min-h-39px">
									{get(dataModalResult, "item.T5", "") && (
										<div className={size(get(dataModalResult, "item.T8", "")) > 0 ? "" : "result_t3"}>
											{get(dataModalResult, "item.T5", "")
												.split("-")
												.map((item, index) => {
													if (includes(item, "loading"))
														return (
															<div className="flex items-center justify-content-center" key={index}>
																<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
															</div>
														);

													if (includes(item, "*"))
														return (
															<div className="flex items-center justify-center" key={index}>
																{item.split("*").map((item, index) => {
																	if (index < 1) return null;
																	return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																})}
															</div>
														);

													return (
														<div className="flex items-center justify-content-center" key={index}>
															<span>{item}</span>
														</div>
													);
												})}
										</div>
									)}
								</td>
							</tr>
							<tr>
								<td className="p0">Sáu</td>
								<td className="p0 min-h-18px">
									{get(dataModalResult, "item.T6", "") && (
										<div className="result_t3">
											{get(dataModalResult, "item.T6", "")
												.split("-")
												.map((item, index) => {
													if (includes(item, "loading"))
														return (
															<div className="flex items-center justify-content-center" key={index}>
																<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
															</div>
														);

													if (includes(item, "*"))
														return (
															<div className="flex items-center justify-center" key={index}>
																{item.split("*").map((item, index) => {
																	if (index < 1) return null;
																	return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																})}
															</div>
														);

													return (
														<div className="flex items-center justify-content-center" key={index}>
															<span>{item}</span>
														</div>
													);
												})}
										</div>
									)}
								</td>
							</tr>
							<tr>
								<td className="p0">Bảy</td>
								<td className="bg-white p0 min-h-18px">
									{get(dataModalResult, "item.T7", "") && (
										<div className={`${size(get(dataModalResult, "item.T8", "")) > 0 ? "" : "num-prizedb"} flex flex-wrap justify-content-center`}>
											{get(dataModalResult, "item.T7", "")
												.split("-")
												.map((item, index) => {
													if (includes(item, "loading"))
														return (
															<div className="flex items-center justify-content-center flex-basis-25" key={index}>
																<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
															</div>
														);

													if (includes(item, "*"))
														return (
															<div className="flex items-center justify-center" key={index}>
																{item.split("*").map((item, index) => {
																	if (index < 1) return null;
																	return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																})}
															</div>
														);

													return (
														<div className="flex items-center justify-content-center flex-basis-25" key={index}>
															<span>{item}</span>
														</div>
													);
												})}
										</div>
									)}
								</td>
							</tr>
							{get(dataModalResult, "item.T8", "") && (
								<tr>
									<td className="p0">Tám</td>
									<td className="p0 min-h-18px">
										<div className="num-prizedb result_t7 items-center justify-content-center">
											{get(dataModalResult, "item.T8", "")
												.split("-")
												.map((item, index) => {
													if (includes(item, "loading"))
														return (
															<div className="flex items-center justify-content-center" key={index}>
																<img width="15px" src="assets/icon-lottery/gifs/loading.gif" alt="ĐB" />
															</div>
														);

													if (includes(item, "*"))
														return (
															<div className="flex items-center justify-center" key={index}>
																{item.split("*").map((item, index) => {
																	if (index < 1) return null;
																	return <img width="15px" src={`assets/icon-lottery/gifs/${index}.gif`} alt="ĐB" height="15px" key={index} />;
																})}
															</div>
														);

													return (
														<div className="flex items-center justify-content-center" key={index}>
															<span>{item}</span>
														</div>
													);
												})}
										</div>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				<div className="wrap-tbl-dauduoi">
					<table style={{ height: "fit-content" }} className="tblDauDuoi">
						<tbody>
							<tr className="title">
								<td className="p0">Đầu</td>
								<td className="p0">Đuôi</td>
							</tr>
							<tr>
								<td className="p0">0</td>
								<td className="p0">{join(get(dataModalResult, "dauDuoi.0", []), ", ")}</td>
							</tr>
							<tr>
								<td className="p0">1</td>
								<td className="p0">{join(get(dataModalResult, "dauDuoi.1", []), ", ")}</td>
							</tr>
							<tr>
								<td className="p0">2</td>
								<td className="p0">{join(get(dataModalResult, "dauDuoi.2", []), ", ")}</td>
							</tr>
							<tr>
								<td className="p0">3</td>
								<td className="p0">{join(get(dataModalResult, "dauDuoi.3", []), ", ")}</td>
							</tr>
							<tr>
								<td className="p0">4</td>
								<td className="p0">{join(get(dataModalResult, "dauDuoi.4", []), ", ")}</td>
							</tr>
							<tr>
								<td className="p0">5</td>
								<td className="p0">{join(get(dataModalResult, "dauDuoi.5", []), ", ")}</td>
							</tr>
							<tr>
								<td className="p0">6</td>
								<td className="p0">{join(get(dataModalResult, "dauDuoi.6", []), ", ")}</td>
							</tr>
							<tr>
								<td className="p0">7</td>
								<td className="p0">{join(get(dataModalResult, "dauDuoi.7", []), ", ")}</td>
							</tr>
							<tr>
								<td className="p0">8</td>
								<td className="p0">{join(get(dataModalResult, "dauDuoi.8", []), ", ")}</td>
							</tr>
							<tr>
								<td className="p0">9</td>
								<td className="p0">{join(get(dataModalResult, "dauDuoi.9", []), ", ")}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
};

export default LotteryResultsXSN;
