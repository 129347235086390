import React, { useState } from "react";
import { IonContent, IonPage, IonButton, IonIcon, IonItem, IonList } from "@ionic/react";
import "./LotteryRecord.css";
import { chevronDown, chevronUp, arrowBack } from "ionicons/icons";
import { LOTTERY_CENTRAL, LOTTERY_SOUTH, LOTTERY_DISPLAY_NAME } from "../constants";
import { get } from "lodash";
import { useHistory } from "react-router-dom";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";

const LotteryRecord: React.FC = () => {
	const history = useHistory();
	const [isShowMB, setIsShowMB] = useState(false);
	const [isShowMT, setIsShowMT] = useState(false);
	const [isShowMN, setIsShowMN] = useState(false);

	return (
		<IonPage>
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.goBack()}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Kết Quả Xổ Số
					</IonButton>
				</div>
			</div>
			<IonContent className="lrecord">
				<section className="lrecord__section">
					<IonList>
						<div className="lrecord__xstitle lrecord__flex-spacebw" onClick={() => setIsShowMB(!isShowMB)}>
							<div className="lrecord__text--title1">Miền Bắc</div>
							<IonIcon className="end-arrow ios hydrated" mode="ios" icon={isShowMB ? chevronDown : chevronUp} slot="end" aria-label="arrow down"></IonIcon>
						</div>
						{isShowMB && (
							<div className="lrecord__xsdetail active" style={{ height: "105px" }}>
								<IonItem
									className="lrecord__xschild ng-star-inserted item ios item-lines-none in-list ion-focusable hydrated"
									lines="none"
									routerLink="/lottery-list/mienbac"
								>
									<div className="lrecord__flex-spacebw">
										<div className="lrecord__text--title2">Miền Bắc</div>
										<div className="lrecord__flex-end">
											<div className="lrecord__text--issue">{/* 2023-01-03 */}</div>
											{/* <IonIcon className="end-arrow ios hydrated" mode="ios" icon={chevronForward} slot="end" role="img" aria-label="arrow forward"></IonIcon> */}
										</div>
									</div>
								</IonItem>
								{/* <IonItem className="lrecord__xschild ng-star-inserted item ios item-lines-none in-list ion-focusable hydrated" lines="none" routerLink="/lottery-list/18h25">
                  <div className="lrecord__flex-spacebw">
                    <div className="lrecord__text--title2">Đặc Biệt 18h25</div>
                    <div className="lrecord__flex-end">
                      <div className="lrecord__text--issue">2023-01-03</div>
                      <IonIcon className="end-arrow ios hydrated" mode="ios" icon={chevronForward} slot="end" role="img" aria-label="arrow forward"></IonIcon>
                    </div>
                  </div>
                </IonItem> */}
							</div>
						)}
					</IonList>
					<IonList>
						<div className="lrecord__xstitle lrecord__flex-spacebw" onClick={() => setIsShowMN(!isShowMN)}>
							<div className="lrecord__text--title1">Miền Nam</div>
							<IonIcon
								className="end-arrow  ios hydrated"
								mode="ios"
								icon={isShowMN ? chevronDown : chevronUp}
								slot="end"
								role="img"
								aria-label="arrow down"
							></IonIcon>
						</div>
						{isShowMN && (
							<div className="lrecord__xsdetail active" style={{ height: "615px" }}>
								{LOTTERY_SOUTH["Tất Cả"].map((item: any, index: any) => {
									return (
										<IonItem
											key={index}
											className="lrecord__xschild ng-star-inserted item ios item-lines-none in-list ion-focusable hydrated"
											lines="none"
											routerLink={`/lottery-list/${item}`}
										>
											<div className="lrecord__flex-spacebw">
												<div className="lrecord__text--title2">{get(LOTTERY_DISPLAY_NAME, `${item}`, item)}</div>
												<div className="lrecord__flex-end">
													<div className="lrecord__text--issue">{/* 2023-01-02 */}</div>
													{/* <IonIcon className="end-arrow ios hydrated" mode="ios" icon={chevronForward} slot="end" role="img" aria-label="arrow forward"></IonIcon> */}
												</div>
											</div>
										</IonItem>
									);
								})}
							</div>
						)}
					</IonList>
					<IonList>
						<div className="lrecord__xstitle lrecord__flex-spacebw" onClick={() => setIsShowMT(!isShowMT)}>
							<div className="lrecord__text--title1">Miền Trung</div>
							<IonIcon
								className="end-arrow  ios hydrated"
								mode="ios"
								icon={isShowMT ? chevronDown : chevronUp}
								slot="end"
								role="img"
								aria-label="arrow down"
							></IonIcon>
						</div>
						{isShowMT && (
							<div className="lrecord__xsdetail active" style={{ height: "465px" }}>
								{LOTTERY_CENTRAL["Tất Cả"].map((item: any, index: any) => {
									return (
										<IonItem
											key={index}
											className="lrecord__xschild ng-star-inserted item ios item-lines-none in-list ion-focusable hydrated"
											lines="none"
											routerLink={`/lottery-list/${item}`}
										>
											<div className="lrecord__flex-spacebw">
												<div className="lrecord__text--title2">{get(LOTTERY_DISPLAY_NAME, `${item}`, item)}</div>
												<div className="lrecord__flex-end">
													<div className="lrecord__text--issue">{/* 2023-01-02 */}</div>
													{/* <IonIcon className="end-arrow ios hydrated" mode="ios" icon={chevronForward} slot="end" role="img" aria-label="arrow forward"></IonIcon> */}
												</div>
											</div>
										</IonItem>
									);
								})}
							</div>
						)}
					</IonList>
				</section>
			</IonContent>
		</IonPage>
	);
};

export default LotteryRecord;
