import React from "react";
import { IonSlides, IonSlide, IonImg } from "@ionic/react";
import { useHistory } from "react-router-dom";

import "../pages/Home.css";
import { IMG_MB } from "../constants";

const MenuGames: React.FC = () => {
	const history = useHistory();
	return (
		<>
			<div className="menu-games">
				<IonSlides
					className="slides-games"
					options={{
						slidesPerView: 4,
						autoplay: {
							delay: 5000,
						},
					}}
				>
					<IonSlide className="item-game" onClick={() => history.push("/lottery")}>
						<IonImg className="ion-img" src={`${IMG_MB}assets/home/games/g-xoso-new.png`} />
						<span> XỔ SỐ </span>
					</IonSlide>
					<IonSlide className="item-game" onClick={() => history.push("/slot-game")}>
						<IonImg className="ion-img" src={`${IMG_MB}assets/home/games/g-slots-new.png`} />
						<span> NỔ HŨ </span>
					</IonSlide>
					<IonSlide className="item-game" onClick={() => history.push("/casino-live")}>
						<IonImg className="ion-img" src={`${IMG_MB}assets/home/games/g-casino-new.png`} />
						<span> CASINO </span>
					</IonSlide>
					<IonSlide className="item-game" onClick={() => history.push("/sport")}>
						<IonImg className="ion-img" src={`${IMG_MB}assets/home/games/g-thethao-new.png`} />
						<span> THỂ THAO </span>
					</IonSlide>
					{/* <IonSlide className="item-game" onClick={() => history.push("/game")}>
						<IonImg className="ion-img" src={`${IMG_MB}assets/home/games/g-dangian-new.png`} />
						<span> DÂN GIAN </span>
					</IonSlide> */}
					<IonSlide className="item-game" onClick={() => history.push("/cock-fight")}>
						<IonImg className="ion-img" src={`${IMG_MB}assets/home/games/g-daga-new.png`} />
						<span> ĐÁ GÀ </span>
					</IonSlide>
					{/* <IonSlide className="item-game" onClick={() => history.push("/card-game")}>
						<IonImg className="ion-img" src={`${IMG_MB}assets/home/games/g-danhbai-new.png`} />
						<span> ĐÁNH BÀI </span>
					</IonSlide> */}
				</IonSlides>
			</div>
		</>
	);
};

export default MenuGames;
