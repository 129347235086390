import React, { useLayoutEffect, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IonContent, IonPage, IonRow, IonCol, IonList, IonLabel, IonButton, useIonToast, IonIcon, IonImg } from "@ionic/react";
import { useSelector, shallowEqual } from "react-redux";
import "./UserInfo.css";
import { getUserBanks } from "../redux/auth/authCrud";
import HeaderHome from "../components/HeaderHome";
import QuickOptions from "../components/QuickOptions";
import { arrowBack } from "ionicons/icons";
import { IMG_MB } from "../constants";

const BankAccount: React.FC = () => {
	const [userBanks, setUserBanks] = useState([]);

	const [present] = useIonToast();
	const history = useHistory();
	const { authToken } = useSelector(
		(state: any) => ({
			authToken: state.auth.authToken,
		}),
		shallowEqual
	);

	const GetUserBanks = async () => {
		const { data } = await getUserBanks();

		if (data) {
			setUserBanks(data);
		}
	};

	useEffect(() => {
		GetUserBanks();
	}, []);

	useLayoutEffect(() => {
		if (!authToken) {
			history.push("/home");
		}
	}, [authToken, history]);

	if (!authToken) return <IonImg className="page-loading" src={`${IMG_MB}assets/v2/loading.webp`} />;

	const presentToast = (cssClass: "success" | "error", message: string) => {
		present({
			cssClass: cssClass,
			message,
			duration: 1500,
			position: "top",
		});
	};

	return (
		<IonPage className="app-user-edit app-home app-user-banks ng-star-inserted ion-page can-go-back">
			<div className="app-home homepage">
				<HeaderHome />
				<QuickOptions />
				<div className="btn-group">
					<IonButton className="btn-sic" size="small" onClick={() => history.push("/information")}>
						<IonIcon color="#000000" icon={arrowBack}></IonIcon>
					</IonButton>
					<IonButton className="btn-sic" size="small">
						Tài Khoản Ngân Hàng
					</IonButton>
				</div>
			</div>
			<IonContent className="ion-content">
				<div className="account-form mb-5">
					<ul className="ac-note">
						<li>
							Bạn có thể thêm tối đa <span>[5]</span> tài khoản ngân hàng, Hiện tại bạn có <span>[{userBanks.length}]</span> tài khoản
						</li>
						<li>
							Từ tài khoản ngân hàng thứ <span>[2]</span> trở đi phải trùng tên chủ tài khoản với tài khoản đầu tiên
						</li>
						<li> Nếu bạn khóa danh sách tài khoản, bạn sẽ không thể thêm mới hoặc xóa tài khoản ngân hàng </li>
						<li> VIBET88 không hỗ trợ rút tiền về số thẻ ATM và Viettel Pay </li>
					</ul>
					{userBanks.length > 0 && (
						<>
							<IonLabel className="ion-label ng-star-inserted sc-ion-label-ios-h sc-ion-label-ios-s ios hydrated">Thẻ ngân hàng của tôi</IonLabel>
							<IonList className="ion-list ac-list ng-star-inserted ios list-ios hydrated">
								{userBanks.map((bank: any, index) => {
									return (
										<div className="ac-list-item ng-star-inserted" key={index}>
											<IonRow className="ios hydrated">
												<IonCol className="ion-col info ios hydrated">
													<IonRow className="ios hydrated">
														<IonCol className="bankname ios hydrated">{bank.BankName}</IonCol>
														<IonCol className="stk ios hydrated">STK: {bank.AccountNumber}</IonCol>
													</IonRow>
													<IonRow className="add-date ios hydrated">Chủ tài khoản: {bank.AccountName}</IonRow>
												</IonCol>
											</IonRow>
										</div>
									);
								})}
							</IonList>
						</>
					)}

					<div className="btn-wrapper">
						<IonButton
							expand="block"
							className="ng-star-inserted ios button button-block button-solid ion-activatable ion-focusable hydrated"
							onClick={() => {
								if (userBanks.length === 5) return presentToast("error", "Bạn đã thêm tối đa 5 số tài khoản.");
								history.push("/account-add-bank");
							}}
						>
							Đăng ký tài khoản ngân hàng
						</IonButton>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default BankAccount;
